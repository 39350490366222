import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  BataiyoLogoIcon,
  BlackBulbIcon,
  BookIcon,
  ConnectionIcon,
  DetailsAtYourFingertips,
  DigitalDivideBlogCoverImg,
  DiscoveringBlogCoverImg,
  FacebookImg,
  FriendsIcon,
  GoodWillBlackIcon,
  GoodWillIcon,
  HandsIcon,
  InfiniteBlackIcon,
  InfiniteGreenIcon,
  InfiniteIcon,
  InstagramImg,
  LightBulbIcon,
  LikeIcon,
  LinkedInImg,
  MedalIcon,
  ModernDayBlogCoverImg,
  MovingToCityBlogCoverImg,
  NetworkingBlackIcon,
  NetworkingIcon,
  NoCommissionImg,
  OnlyTrustedImg,
  OrangeBulbIcon,
  OurPlatformImg,
  PromotesLegacyImg,
  ReducesSearchTimeImg,
  ScanIcon,
  ShareIcon,
  SupportingBusinessBlogCoverImg,
  XImg,
  YouTubeImg,
  trustIcon,
} from "./ImagesS3";

import { ROUTES } from "./routes";
const colors = ["#0072DD", "#FF820F", "#06A445"];

const getColorForIndex = (index) => {
  return colors[index % colors.length];
};
export const navItems = [
  {
    id: 1,
    path: "#why-bataiyo",
    name: "Why Bataiyo?",
  },
  {
    id: 2,
    path: "#roadmap",
    name: "Roadmap",
  },
  {
    id: 3,
    path: "#faq",
    name: "FAQ",
  },
];
export const heroSectionData = {
  title: (
    <>
      What is{" "}
      <span style={{ color: getColorForIndex(0) }}>
        <b> BATAIYO?</b>
      </span>
    </>
  ),
  description: [
    <>
      Bataiyo is an <b>all-in-one </b>
      platform that helps you connect with{" "}
      <span style={{ color: getColorForIndex(2) }}>
        <b>service providers</b>
      </span>{" "}
      who are <b>experienced</b> and <b>recommended</b> by your{" "}
      <span style={{ color: getColorForIndex(1) }}>
        <b>trusted connections.</b>
      </span>
    </>,
    <>
      Whether you’re planning travel, moving to a new city, going out for
      shopping, looking for food or exciting activities, seeking professional
      help, or finding a job or life partner, Bataiyo is here to help.
    </>,
    <>
      {" "}
      <b> - Bataiyo |</b>{" "}
      <span style={{ color: getColorForIndex(0) }}>
        “<b>Bharosa Apno Ka</b>”
      </span>
    </>,
  ],
};
export const valuesList = [
  {
    id: 1,
    name: (
      <>
        Our Values :
        <span
          style={{ paddingLeft: "32px", fontSize: "32px", fontWeight: "100" }}
        >
          |
        </span>
      </>
    ),
  },
  {
    id: 2,
    img: LightBulbIcon,
    name: "Enlightenment",
  },
  {
    id: 3,
    img: NetworkingIcon,
    name: "Connections",
  },
  {
    id: 4,
    img: GoodWillIcon,
    name: "Kindness",
  },
  {
    id: 5,
    img: trustIcon,
    name: "Trust",
  },
  {
    id: 6,
    img: InfiniteIcon,
    name: "Legacy",
  },
];
export const bataiyoAdvantages = [
  {
    id: 1,
    title: "Zero Commission",
    img: NoCommissionImg,
    alt: "no-commission",
  },
  {
    id: 2,
    title: "Only Trusted Recommendations",
    img: OnlyTrustedImg,
    alt: "only-trusted-recommendations",
  },
  {
    id: 3,
    title: "One Platform For Everyone",
    img: OurPlatformImg,
    alt: "one-platform-for-everyone",
  },
  {
    id: 4,
    title: "Reduces Search Time",
    img: ReducesSearchTimeImg,
    alt: "reduces-search-time",
  },
  {
    id: 5,
    title: "Promotes Legacy",
    img: PromotesLegacyImg,
    alt: "promotes-legacy",
  },
];
export const sixDegreeSeperationSectionData = {
  title: (
    <>
      6 Degrees of Separation{" "}
      <a
        className="six-degree-link"
        href={ROUTES.SIX_DEGREE_SEPERATION_WIKI_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <OpenInNewIcon />
      </a>
    </>
  ),
  description: [
    <>
      It is the idea that all people are six or fewer social connections away
      from each other. As a result, a <b>chain</b> of{" "}
      <span style={{ color: getColorForIndex(0) }}>
        "<b>friend of a friend</b>"
      </span>{" "}
      statements can be made to connect any two people in a maximum of six
      steps.
    </>,
    <>
      <span style={{ color: getColorForIndex(0) }}>
        <b>Bataiyo</b>
      </span>{" "}
      works on the idea of <b>“6 Degrees of Separation”</b> and <b>ensures</b>{" "}
      that the <b>services & experiences</b> that you avail are more{" "}
      <b>reliable and trusted </b>
      <span style={{ color: getColorForIndex(0) }}>
        <b>connections.</b>
      </span>
    </>,
  ],
};
export const IBOTextData = {
  desc1: "IBO Benefits",
  desc2: (
    <>
      Participate in <span style={{ color: getColorForIndex(0) }}>IBO</span> for{" "}
      <span style={{ color: getColorForIndex(0) }}>FREE</span> and get a chance
      to be a part of the{" "}
      <span style={{ color: getColorForIndex(0) }}>change</span> and{" "}
      <span style={{ color: getColorForIndex(0) }}>life-time benefits!</span>
    </>
  ),
  desc3: (
    <>
      <b>Results:</b> For Draws, the results will be calculated based on{" "}
      <b>Proof-Of-Network</b>. The person with most <b>trusted</b> network will
      have more chance to win!
    </>
  ),
};
export const IBOValuesList = [
  {
    id: 1,
    title: (
      <>
        <span style={{ color: getColorForIndex(0) }}>Free</span> Premium
        Membership
      </>
    ),
    icon: BlackBulbIcon,
    alt: "premium-membership",
  },
  {
    id: 2,
    title: (
      <>
        <span style={{ color: getColorForIndex(0) }}>Gift</span> premium
        memberships for{" "}
        <span style={{ color: getColorForIndex(0) }}>Free!</span>
      </>
    ),
    icon: NetworkingBlackIcon,
    alt: "premium memberships",
  },
  {
    id: 3,
    title: (
      <>
        Get a <span style={{ color: getColorForIndex(0) }}>Job</span> at{" "}
        <span style={{ color: getColorForIndex(0) }}>Bataiyo</span>!
      </>
    ),
    description: (
      <>
        Dont worry this is not free, you will get{" "}
        <span style={{ color: getColorForIndex(0) }}>paid!</span>
      </>
    ),
    icon: GoodWillBlackIcon,
    alt: "get-a-job",
  },
  {
    id: 4,
    title: (
      <>
        Get <span style={{ color: getColorForIndex(0) }}>Equity</span> In{" "}
        <span style={{ color: getColorForIndex(0) }}>Bataiyo</span> for{" "}
        <span style={{ color: getColorForIndex(0) }}>Free!</span>
      </>
    ),
    description: <>Terms & Conditions* </>,
    icon: trustIcon,
    alt: "get-equity",
  },
  {
    id: 5,
    title: (
      <>
        Chance to <span style={{ color: getColorForIndex(0) }}>grow</span>
        <br />
        <span style={{ color: getColorForIndex(0) }}>
          together and create legacy!
        </span>
      </>
    ),
    description: <>Terms & Conditions*</>,
    icon: InfiniteBlackIcon,
    alt: "chance-to-grow",
  },
];
export const usersExperiences = [
  {
    id: 1,
    title: <>Your Friends & Family Know the Best</>,
    description: (
      <>
        Find trusted professionals who come highly recommended by your inner
        circle. It’s like having a personal network of experts at your
        fingertips!
      </>
    ),
    img: FriendsIcon,
    alt: "friends",
  },
  {
    id: 2,
    title: (
      <>
        Access with a Tap:
        <br />
        Loads of information just a tap away.
      </>
    ),
    description: (
      <>
        Gone are the days of endless searching and second-guessing. With
        Bataiyo, all the information you need is just a tap away.{" "}
      </>
    ),

    img: DetailsAtYourFingertips,
    alt: "fingertips",
  },
  {
    id: 3,
    title: (
      <>
        Scan! Connect! Explore!:
        <br />
        Your Trusted Network Awaits
      </>
    ),
    description: (
      <>
        With Bataiyo, your trusted network is always within reach. Discover the
        ease of finding top-notch services backed by the people you trust the
        most.
      </>
    ),
    img: ScanIcon,
    alt: "scan-karo-connect-karo",
  },
  {
    id: 4,
    title: (
      <>
        Spread the Word:
        <br />
        Empower Your Network{" "}
      </>
    ),
    description: (
      <>
        Help your local service providers reach a wider audience. Share your
        trusted recommendations and strengthen your community.
      </>
    ),
    img: ShareIcon,
    alt: "share",
  },
];
export const servicesExperiences = [
  {
    id: 1,
    title: <>Hassle-Free Onboarding</>,
    description: (
      <>
        Hop on Bataiyo without the headache. Easy setup, and you’re good to go!
      </>
    ),

    img: LikeIcon,
    alt: "like",
  },
  {
    id: 2,
    title: <>Grow Your Network </>,
    description: (
      <>
        Meet more people, get more gigs. Join our community and watch your
        connections multiply.
      </>
    ),
    img: ShareIcon,
    alt: "share",
  },
  {
    id: 3,
    title: <>Seamless Directory Access</>,
    description: (
      <>
        Say goodbye to the stress of keeping track of your contacts manually
        —Bataiyo is here.
      </>
    ),
    img: BookIcon,
    alt: "book",
  },
  {
    id: 4,
    title: <>Trustworthy Clients Through Recommendations</>,
    description: (
      <>
        Get clients you can count on. Trusted referrals mean you’re working with
        folks who value your expertise.
      </>
    ),
    img: MedalIcon,
    alt: "medal",
  },
];
export const socialMediaIcons = [
  {
    id: 1,
    path: "https://www.instagram.com/bataiyo.official/",
    icon: InstagramIcon,
    alt: "instagram",
  },
  {
    id: 2,
    path: "https://www.linkedin.com/company/bataiyo/",
    icon: LinkedInIcon,
    alt: "linkedin",
  },
  {
    id: 3,
    path: "https://x.com/bataiyoofficial?t=s9FWulgAI-X1FujT5xYscQ&s=09",
    icon: XIcon,
    alt: "x-logo",
  },
  {
    id: 4,
    path: "https://www.facebook.com/profile.php?id=61560529440864&mibextid=ZbWKwL",
    icon: FacebookIcon,
    alt: "facebook",
  },
  {
    id: 5,
    path: "https://www.youtube.com/channel/UCh2YhhO5bdamxyC1WSBfcXQ",
    icon: YouTubeIcon,
    alt: "YouTube",
  },
];
export const roadmapCardsDetails = [
  {
    id: 1,
    title: "Phase 1",
    q: "3",
    year: "2024",
    logo: OrangeBulbIcon,
    alt: "blulb",
    deatils: "Awareness of Idea",
    backgroundColor: "#FF9D4214",
  },

  {
    id: 2,
    title: "Phase 2",
    q: "4",
    year: "2024",
    logo: ConnectionIcon,
    alt: "connections",
    deatils: "App Launch",
    backgroundColor: "transprent",
  },
  {
    id: 3,
    title: "Phase 3",
    q: "3",
    year: "2026",
    logo: HandsIcon,
    alt: "hands",
    deatils: "Unlock Features",
    backgroundColor: "#0072DD14",
  },
  {
    id: 4,
    title: "Phase 4",
    q: "3",
    year: "2028",
    logo: BataiyoLogoIcon,
    alt: "bataiyo-logo",
    deatils: "Atma-Nirbhar Bharat",
    backgroundColor: "transprent",
  },
  {
    id: 5,
    title: "Phase 5",
    q: "3",
    year: "2030",
    logo: InfiniteGreenIcon,
    alt: "inifinite",
    deatils: "Grow Together",
    backgroundColor: "#06A44514",
  },
];
export const categoryOptions = [
  { value: "Individual", label: "Individual" },
  { value: "Business", label: "Business" },
];
export const privacyPolicyData = [
  {
    header: "Privacy Policy",
    paragraphs: [
      <>
        Bgent Solutions Private Limited (hereinafter “<b>Bataiyo</b>”)
        recognizes the importance of privacy of its users and also of
        maintaining confidentiality of the information provided by its users as
        a responsible data controller and data processer.
      </>,
      <>
        This Privacy Policy provides for the practices for handling and securing
        user's Personal Information including any sensitive information (defined
        hereunder) by Bataiyo and its subsidiaries and affiliates.
      </>,
      <>
        This Privacy Policy is applicable to any person (‘<b>User</b>’) who
        purchase, intend to purchase, or inquire about any product(s) or
        service(s) made available by Bataiyo through any of Bataiyo’s customer
        interface channels including its website, mobile site, mobile app &
        offline channels including call centers and offices (collectively
        referred herein as "<b>Platform</b>"). For the purpose of this Privacy
        Policy, ‘<b>Website</b>’ means the website(s), mobile site(s) and mobile
        app.
      </>,
      <>
        For the purpose of this Privacy Policy, wherever the context so requires
        "you" or "your" shall mean User and the term "we", "us", "our" shall
        mean Bataiyo.
      </>,
      <>
        By using or accessing the Website or other Platform, the User hereby
        agrees with the terms of this Privacy Policy and the contents herein. If
        you disagree with this Privacy Policy please do not use or access our
        Website or other Platforms.
      </>,
      <>
        Our services are not intended for children under 18 years old, and we’ll
        never collect their data unless it’s provided by (and with the consent
        of) a parent or guardian. The limited circumstances we might need to
        collect the personal data of children under 18 years old include as part
        of a reservation, the purchase of other related services, or in other
        exceptional circumstances (such as features addressed to families). This
        will only be used and collected as provided by a parent or guardian and
        with their consent. If we become aware that we’ve processed the
        information of a child under 18 years old without the valid consent of a
        parent or guardian, we will delete it.
      </>,
      <>
        This Privacy Policy does not apply to any website(s), mobile sites and
        mobile apps of third parties, even if their websites/products are linked
        to our Website. User should take note that information and privacy
        practices of Bataiyo’s business partners, advertisers, sponsors or other
        sites to which Bataiyo provides hyperlink(s), may be materially
        different from this Privacy Policy. Accordingly, it is recommended that
        you review the privacy statements and policies of any such third parties
        with whom they interact.
      </>,
      <>
        This Privacy Policy is an integral part of your User Agreement with
        Bataiyo and all capitalized terms used, but not otherwise defined
        herein, shall have the respective meanings as ascribed to them in the
        User Agreement. This Policy is a legally binding document between you
        and Bataiyo. The terms of this Policy will be effective upon your
        acceptance of the same (directly or indirectly in electronic form, by
        clicking on the I accept tab or by use of the Platform or by other
        means) and will govern the relationship between you and Bataiyo for your
        use of the Platform.
      </>,
    ],
    sections: [
      {
        id: 1,
        title: "TYPE OF INFORMATION WE COLLECT AND ITS LEGAL BASIS",
        paragraphs: [
          <>
            The information as detailed below is collected for us to be able to
            provide the services chosen by you and to fulfill our legal
            obligations as well as our obligations towards third parties as per
            our User Agreement. "<b>Personal Information</b>" of User shall
            include the information shared by the User and collected by us for
            the following purposes:
          </>,
        ],
        content: [
          <>
            <b>Registration on the Website:</b> Information which you provide
            while subscribing to or registering on the Website, including but
            not limited to information about your personal identity such as
            name, gender, marital status, religion, age, profile picture etc.,
            your contact details such as your email address, postal addresses,
            unique ID number, telephone (mobile or otherwise) and/or fax
            numbers. The information may also include information such as your
            banking details (including credit / debit card) and any other
            information relating to your income and/or lifestyle; billing
            information payment history etc. (as shared by you).
          </>,
          <>
            <b>Other information:</b> We many also collect some other
            information and documents including but not limited to transactional
            history (other than banking details) about your Platform activities,
            buying behavior, your usernames, passwords, email addresses and
            other security-related information used by you in relation to our
            services. Data either created by you or by a third party and which
            you wish to store on our servers such as image files, documents etc,
            data available in public domain or received from any third party
            including social media channels, including but not limited to
            personal or non-personal information from your linked social media
            channels (like name, email address, friend list, profile pictures or
            any other information that is permitted to be received as per your
            account settings) as a part of your account information, information
            pertaining any other persons for who you make any recommendations to
            or about through your registered Bataiyo account. In such case, you
            must confirm and represent that each of the other persons have
            agreed to have the information shared by you disclosed to us and
            further be shared by us with the concerned service provider(s). In
            case a User does not wish to provide this information, Bataiyo may
            not be able to offer its services.
          </>,
          <>
            User further understands that Bataiyo may share this information
            with the Service Provider or any other third party for provision and
            facilitation of the service requested. Bataiyo will always redact
            all/any sensitive & confidential information contained in the any
            other identity card submitted for the purpose of availing a service,
            promotional offer or booking a product on the Website.
          </>,
          <>
            Your use of any part of the Platform indicates your acceptance of
            this Policy and of the collection, use and disclosure of your
            Personal Information in accordance with this Policy. While you have
            the option not to provide us with certain information or withdraw
            consent to collect certain information, kindly note that in such an
            event you may not be able to take full advantage of the entire scope
            of features and services offered to you and we reserve the right to
            restrict you from using services at any point.
          </>,
          <>
            It should be noted that Bataiyo does not store, keep or access, any
            payment related data or information. All payment transactions are
            routed via secure server software through third party payment
            gateways. Bataiyo only directs you to the payment gateway and does
            not have any control over the payment transactions. This implies
            that Bataiyo shall not be held responsible or liable for any loss or
            damage due to any disclosure of your payment related information.
            Bataiyo hereby disclaims all its liability for any loss or damage
            caused to you on account of disclosure (intentional / unintentional)
            of any Personal Information concerning your account and/or any
            information with respect to the online payment transaction including
            through the credit card/debit card/cash card/net-banking/mobile
            wallet/UPI, etc. and/or the payment verification process.
          </>,
        ],
      },
      {
        id: 2,
        title: "HOW WE USE YOUR PERSONAL INFORMATION",
        content: [
          <>
            To register on our Platform, Users are required to provide mobile
            number, email address, and full name for the registration process.
            The Users may also provide Personal Information and/or sensitive
            information. The information supplied by the Users enables us to
            improve our Platform and provide you the most user-friendly
            experience.
          </>,
          <>
            We may use your Personal Information and/or sensitive information
            for several reasons including but not limited to: <br />
            a) To confirm your reservations with respective service providers,
            keep you informed of the transaction status, send confirmations /
            recommendations either via sms or whatsapp or any other messaging
            service, send any updates or changes, allow our customer service to
            contact you, if necessary, customize the content of our website,
            mobile site and mobile app, request for reviews of products or
            services or any other improvements, send verification message(s) or
            email(s), validate/authenticate your account and to prevent any
            misuse or abuse, contact you on your birthday/anniversary to offer a
            special gift or offer.
            <br />
            b) To conduct surveys. We value opinions and comments from our Users
            and frequently conduct surveys, both online and offline.
            Participation in these surveys is optional. Typically, the
            information received is aggregated, and used to make improvements to
            Website, other Platforms, services and to develop appealing content,
            features and promotions for members based on the results of the
            surveys. Identity of the survey participants is anonymous unless
            otherwise stated in the survey.
            <br />
            c) Marketing promotions, research and programs help us to identify
            your preferences, develop programs and improve user experience.
            Personal Information collected by us for such activities may include
            contact information and survey questions. We use such Personal
            Information to survey information to develop promotions,
            support-related problems and product improvements. As a registered
            User, you will also occasionally receive updates from us about
            recommended activities/products and services in your area, special
            offers, new Bataiyo services, other noteworthy items (like benefits
            on particular service of a provider, hotel reservations, holiday
            packages, car rentals, doctors recommended, etc.) and marketing
            programs. If you fail to complete a transaction after giving us your
            contact information during the course of completing such steps that
            are intended for completion of the transaction, we will contact You
            in order to give You a better way to avail our services. We may also
            contact you to for your opinion on the services of vendors listed
            with us or any other vendors recommended on our Platform.
            <br />
            d) to contact you and deliver information targeted to your
            interests, such as banner advertisements, notices, service
            offerings, communications, newsletters, SMS messages, etc., relevant
            to your interests and use of the Platform. You expressly agree to
            receive such information and communications. In case you do not wish
            to receive such information and communications, you may
            unsubscribe/opt out of the receipt of such information and
            communications by writing to contact@bgent.in
            <br />
            e) Bataiyo may use data management services to access device
            information; metadata of incoming and outgoing calls and messages;
            device storage, device identifiers, call related information and
            records, application history, etc. These permissions are collected
            to better understand how Users access and use the Services, both on
            an aggregated and individualized basis, in order to improve our
            Platform, analyse player traffic and activity, respond to User
            desires and preferences, and for other research and analytical
            purposes. This includes Location Services i.e. wherein we may use
            and store information about your location to provide special
            features or to improve and customize the Services and to ensure that
            you are not accessing the Platform from restricted states in India
            or outside the territorial limits of the Republic of India, Log Data
            Services i.e. our backend servers automatically record information
            created by your use of the Platform. This data may include
            information such as your location, your mobile carrier, device IDs,
            search terms, and cookie information. [Log data is received when you
            access the Platform. This data is used to provide the Services on
            the Platform and to measure, customize, and improve them].
            <br />
            f) From time to time we may add or enhance services available on the
            Website. To the extent these services are provided, and used by you,
            we will use the Personal Information you provide to facilitate the
            service(s) requested. For example, if you email us with a question,
            we will use your email address, name, nature of the question, etc.
            to respond to your question. We may also store such Personal
            Information to assist us in making the Website the better and easier
            to use for our Users.
            <br />
            g) In case Bataiyo launches reward programs by way of which users
            may stand to win related rewards. We may use your Personal
            Information to enroll you in the rewards program and status of the
            same will be visible each time you log in to the Website. Depending
            on the reward program, each time you win a reward, Bataiyo may share
            your Personal Information with a third party that will be
            responsible for fulfilling the reward to you. You may however choose
            to opt out of such reward programs by writing to us. For various
            purposes such as fraud detection, offering bookings on credit etc.,
            we at times may verify information of customers on selective basis,
            including their credit information. Additionally, Bataiyo may share
            your Personal Information, anonymized and/ or aggregated data to a
            third party that Bataiyo may engage to perform certain tasks on its
            behalf, including but not limited to payment processing, data
            hosting, data processing and assessing credit worthiness for
            offering bookings on credit. Where we permit any third parties to
            collect and use your sensitive personal data or Personal
            Information, we, to the best of our knowledge, ensure that such
            third parties maintain the same security standards that are adhered
            to by us and prescribed under applicable data protection laws for
            the protection of your sensitive personal data or Personal
            Information and take reasonable measures to ensure that the third
            parties do not disclose the sensitive personal data or Personal
            Information unless such disclosure has been expressly consented to
            by You.
            <br />
            h) Bataiyo retains the right to utilise data supplied by or
            pertaining to the User for purposes such as processing payment
            instructions, such as those received by independent third-party
            service providers, such as payment gateways, banks and other
            financial institutions, providers of prepaid instruments and
            wallets, for the purpose of processing payment transactions or
            delaying the use of payment facilities.
            <br />
            i) Bataiyo may record and share aggregate information of its users
            with selected clients, service providers and partners of Bataiyo,
            without disclosing individual names or identifying information of
            the users of the Platform/Services.
            <br />
            j) While using services, if the nature of the service so requires,
            that in order to provide Services, We may collect, with your prior
            permission/consent the following: Information regarding your
            location, camera, audio, images & file access and other services,
            phone number & IMEI - required for online payment gateway
            transactions.
            <br />
            k) When you make calls to our customer service team, Bataiyo uses an
            automated telephone number detection system to match your telephone
            number to your existing reservations. This can help save time for
            both you and our customer service staff. However, our customer
            service staff may still ask for authentication, which helps to keep
            your reservation details confidential. Live listening might be
            carried out or calls might be recorded for quality control and
            training purposes. This includes the usage of the recordings for the
            handling of complaints, legal claims and for fraud detection.
            <br />
            l) In certain cases, we may need to use your information to handle
            and resolve legal claims and disputes, for regulatory investigations
            and compliance, to enforce the Bataiyo User Agreement or to comply
            with lawful requests from law enforcement.
            <br />
            m) In order to use the services for which a subscription fee(s)
            is/are due, Bataiyo may require the User to pay by credit card, wire
            transfer, debit card, or check.As part of the billing and payment
            processes, Bataiyo will gather the credit card number of the
            relevant User as well as any other financial institution
            information, such as bank account numbers, and use that information.
            This includes, but is not limited to, using and disclosing the
            credit card number and information to third parties as necessary to
            complete the billing operation.However, the only person who may
            verify credit information is the User through the login
            process.Users' credit card and debit card information is transacted
            on secure websites of authorised payment gateways that are digitally
            encrypted, offering the highest standard of security currently
            achievable. User is advised, however, that internet technology is
            not full proof safe and User should exercise discretion on using the
            same and the User is provided with an option not to save payment
            details.
          </>,
          <>
            If Bataiyo is required to do so by law, rule, regulation, law
            enforcement agency, government official, legal authority, or similar
            requirements, or if Bataiyo determines, in its sole discretion, that
            disclosing the information is necessary to protect its rights or the
            rights of others, to protect against harm to people or property, to
            combat fraud and credit risk, or to enforce or apply the User
            Agreement, it will only do so.
          </>,
          <>
            Bataiyo will not disclose your Personal Information and/or sensitive
            information unless Bataiyo has received your permission or Bataiyo
            is required to disclose such Personal Information and/or Sensitive
            Information to comply with applicable laws and lawful requests by
            any governmental authorities.
          </>,
          <>
            Any information will not be considered as sensitive if it is freely
            available and accessible in the public domain or is furnished under
            the Right to Information Act, 2005 or any other law for the time
            being in force.
          </>,
          <>
            Bataiyo displays information of various vendors /services providers
            recommended by its Users (“<b>Recommendations</b>”) and of services
            providers listed for providing of various services to the Platform
            Users (“<b>Service Providers</b>”) on its Platform. Bataiyo displays
            such information on its Platform on an as-is basis making no
            representation or warranty on the accuracy or completeness of the
            information. It shall be the duty of the Service Providers to check
            the accuracy and completeness of their information from time to
            time, and inform us immediately of any discrepancies, changes or
            updates to such information. Therefore, Bataiyo disclaims any
            liability for any incorrect or incomplete information appearing on
            the Platform for such Service Providers.
          </>,
          <>
            A visitor is deemed to read and understand this Policy before use
            browsing the services and if such visitor does not agree to any of
            the applicable terms of this Policy, such visitor shall stop such
            browsing forthwith. No sensitive personal data is collected from a
            user/visitor browsing the Platform/services. A visitor can delete
            temporary cookies on the User’s devices by as per the User’s
            convenience. Please note that any visitor shall be deemed to be a
            User, and all User terms shall be applicable, if such visitor has
            willingly submitted any personal data or information to Bataiyo
            through any means, including email, post or through the registration
            process on the Platform. Bataiyo does not collect information about
            Platform visitors from other sources, such as public records,
            private companies, or bodies, unless necessary for the Users'
            registration.
          </>,
        ],
      },
      {
        id: 3,
        title: "HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION",
        paragraphs: [
          "Bataiyo will retain your Personal Information on its servers for as long as is reasonably necessary for the purposes listed in this policy. In some circumstances we may retain your Personal Information for longer periods of time, for instance where we are required to do so in accordance with any legal, regulatory, tax or accounting requirements. Where your personal data is no longer required we will ensure it is either securely deleted or stored in a way which means it will no longer be used by the business. In case user wishes to delete their account based on information available on Our Website.",
        ],
      },
      {
        id: 4,
        title: "WITH WHOM THE INFORMATION IS SHARED",
        content: [
          <>
            <b>Service Providers and suppliers:</b>
            <br />
            Your information shall be shared with the end service providers like
            airlines, hotels, bus service providers, cab rental, railways or any
            other suppliers who are responsible for fulfilling your bookings.
            You may note that while making a booking with us you authorize us to
            share your information with the said service providers and
            suppliers. It is pertinent to note that Bataiyo does not authorize
            the end service provider to use your information for any other
            purpose(s) except as may be for fulfilling their part of service.
            However, how the said service providers / suppliers use the
            information shared with them is beyond the purview and control of
            Bataiyo as they process Personal Information as independent data
            controllers, and hence we cannot be made accountable for the same.
            You are therefore advised to review the privacy policies of the
            respective service provider or supplier whose services you choose to
            avail. Bataiyo does not sell or rent individual customer names or
            other Personal Information of Users to third parties except sharing
            of such information with our business / alliance partners or vendors
            who are engaged by us for providing various referral services and
            for sharing promotional and other benefits to our customers from
            time to time basis their booking history with us.
          </>,
          <>
            <b>Companies in the same group:</b>
            <br />
            In the interests of improving personalization and service
            efficiency, we may, under controlled and secure circumstances, share
            your Personal Information with our affiliate or associate entities.
            In addition, as part of business expansion / development /
            restructuring or for any other reason whatsoever, if we decide to
            sell/transfer/assign our business, any part thereof, any of our
            subsidiaries or any business units, then as part of such
            restructuring exercise customer information including the Personal
            Information collected herein shall be transferred accordingly.
          </>,
          <>
            <b>Business Partners and Third party Vendors:</b>
            <br />
            a) We may also share certain filtered Personal Information to our
            corporate affiliates or business partners who may contact the
            customers to offer certain products or services, which may include
            free or paid products / services, which will enable the customer to
            have better experience. Examples of such partners are entities
            offering co-branded credit cards, travel insurance, insurance cover
            against loss of wallet, banking cards or similar sensitive
            information etc. If you choose to avail any such services offered by
            our business partners, the services so availed will be governed by
            the privacy policy of the respective service provider.
            <br />
            b) We use non-identifiable Personal Information of Users in
            aggregate or anonymized form to build higher quality, more useful
            online services by performing statistical analysis of the collective
            characteristics and behavior of our customers and visitors, and by
            measuring demographics and interests regarding specific areas of the
            Website. We may provide anonymous statistical information based on
            this data to suppliers, advertisers, affiliates and other current
            and potential business partners. We may also use such aggregate data
            to inform these third parties as to the number of people who have
            seen and clicked on links to their websites. Any Personal
            Information which we collect and which we may use in an aggregated
            format is our property. We may use it, in our sole discretion and
            without any compensation to you, for any legitimate purpose
            including without limitation the commercial sale thereof to third
            parties.
            <br />
            c) Occasionally, Bataiyo will hire a third party for market
            research, surveys etc. and will provide information to these third
            parties specifically for use in connection with these projects. The
            information (including aggregate cookie and tracking information) we
            provide to such third parties, alliance partners, or vendors are
            protected by confidentiality agreements and such information is to
            be used solely for completing the specific project, and in
            compliance with the applicable regulations.
          </>,
        ],
      },
      {
        id: 5,
        title: "COOKIES AND SESSION DATA",
        content: [
          <>
            Bataiyo uses cookies to personalize your experience on the Website
            and the advertisements that maybe displayed. Bataiyo’s use of
            cookies is similar to that of any other reputable online companies.
          </>,
          <>
            Cookies are small pieces of information that are stored by your
            browser on your device's hard drive. Cookies allow us to serve you
            better and more efficiently. Cookies also allow ease of access, by
            logging you in without having to type your login name each time
            (only your password is needed); we may also use such cookies to
            display any advertisement(s) to you while you are on the Website or
            to send you offers (or similar emails – provided you have not opted
            out of receiving such emails) focusing on destinations which may be
            of your interest.
          </>,
          <>
            A cookie may also be placed by our advertising servers, or third
            party advertising companies. Such cookies are used for purposes of
            tracking the effectiveness of advertising served by us on any
            website, and also to use aggregated statistics about your visits to
            the Website in order to provide advertisements in the Website or any
            other website about services that may be of potential interest to
            you. The third party advertising companies or advertisement
            providers may also employ technology that is used to measure the
            effectiveness of the advertisements. All such information is
            anonymous. This anonymous information is collected through the use
            of a pixel tag, which is an industry standard technology and is used
            by all major websites. They may use this anonymous information about
            your visits to the Website in order to provide advertisements about
            goods and services of potential interest to you. No Personal
            Information is collected during this process. The information so
            collected during this process, is anonymous, and does not link
            online actions to a User.
          </>,
          <>
            Most web browsers automatically accept cookies. Of course, by
            changing the options on your web browser or using certain software
            programs, you can control how and whether cookies will be accepted
            by your browser. Bataiyo supports your right to block any unwanted
            internet activity, especially that of unscrupulous websites.
            However, blocking Bataiyo cookies may disable certain features on
            the Website, and may hinder an otherwise seamless experience to
            purchase or use certain services available on the Website. Please
            note that it is possible to block cookie activity from certain
            websites while permitting cookies from websites you trust.
          </>,
          <>
            Each time you access the Website your session data gets logged.
            Session data may consist of various aspects like the IP address,
            operating system and type of browser software being used and the
            activities conducted by the User while on the Website. We collect
            session data because it helps us analyze User’s choices, browsing
            pattern including the frequency of visits and duration for which a
            User is logged on. It also helps us diagnose problems with our
            servers and lets us better administer our systems. The aforesaid
            information cannot identify any User personally. However, it may be
            possible to determine a User's Internet Service Provider (ISP), and
            the approximate geographic location of User's point of connectivity
            through the above session data.
          </>,
        ],
      },
      {
        id: 6,
        title: "DISCLOSURE OF INFORMATION",
        paragraphs: [
          "In addition to the circumstances described above, Bataiyo may disclose User's Personal Information if required to do so by law, required by any enforcement authority for investigation, by court order or in reference to any legal process, to conduct our business, for regulatory, internal compliance and audit exercise(s), to secure our systems; or to enforce or protect our rights or properties of Bataiyo or any or all of its affiliates, associates, employees, directors or officers or when we have reason to believe that disclosing Personal Information of User(s) is necessary to identify, contact or bring legal action against someone who may be causing interference with our rights or properties, whether intentionally or otherwise, or when anyone else could be harmed by such activities. Such disclosure and storage may take place without your knowledge. In that case, we shall not be liable to you or any third party for any damages howsoever arising from such disclosure and storage.",
        ],
      },
      {
        id: 7,
        title: "USER GENERATED CONTENT",
        paragraphs: [
          "Bataiyo provides an option to its users to post their experiences by way of reviews, ratings and general poll questions. The customers also have an option of posting questions w.r.t a service offered by/via Bataiyo or post answers to questions raised by other users. Bataiyo may also hire a third party to contact you and gather feedbacks. Though the participation in the feedback process is purely optional, you may still receive emails, notifications (app, sms, Whatsapp or any other messaging service) for you to share your review(s), answer questions posted by other users. The reviews may be written or in a video format. The reviews written or posted may also be visible on other related platforms. Bataiyo may collect review and ratings, question and answers, crowd source data collection (poll questions). Each User who posts review or ratings, Q&A, photographs shall have a profile, which other Users will be able to access. Other Users may be able to view the number of trips, reviews written, questions asked and answered and photographs posted.",
        ],
      },
      {
        id: 8,
        title: "OPTING OUT OF RECIEVEING ANY PROMOTIONAL EMAILS",
        paragraphs: [
          `You will occasionally receive e-mail updates from us about fare sales in your area, special offers, new Bataiyo services, and other noteworthy items. We hope you will find these updates interesting and informative. If you wish not to receive them, please click on the "unsubscribe" link or follow the instructions in each e-mail message.`,
        ],
      },
      {
        id: 9,
        title: "PROTECTION OF INFORMATION SECURITY",
        content: [
          <>
            Bataiyo maintains appropriate reasonable security practises and
            procedures, has a thorough information security programme that is
            documented, and information security policies that contain
            managerial, technical, operational, and physical security control
            measures appropriate to the nature of Bataiyo's operations and the
            information being collected and to protect information against
            accidental or unlawful destruction or loss, alteration, unauthorized
            disclosures or access, and any other unlawful forms of processing of
            the data in its possession (“<b>Security Practices</b>”). However,
            Bataiyo will not be held responsible for any loss whatever incurred
            by the User for any data loss or theft caused by unauthorised access
            to the User's electronic devices via which the User accesses the
            Services. Our security measures are reviewed regularly and updated
            in keeping with technological advances.
          </>,
          <>
            All information provided by you is stored and retained on a secure
            server located in India. Such Security Practices include, but are
            not limited to encryption, firewalls, password protection,
            limitation of access to specified personnel on a need-to-know basis,
            de-identification and encryption and other physical security
            measures to guard against unauthorized access to the server and
            systems and information residing on such server and systems. Our
            Security Practices are commensurate with the information assets
            being protected and with the nature of the business. By using the
            Platform, you accept that the Security Practices and procedures
            mentioned above, which are designed to protect the information
            provided by you, are reasonable. However, we do not guarantee that
            Personal Information and/or Sensitive Information cannot be
            accessed, disclosed, altered or destroyed by breach of any of the
            abovementioned safeguards. We take all steps reasonably necessary,
            including providing notice of breach to Users, where required under
            applicable law, to ensure that Personal Information and/or Sensitive
            Information is treated securely and in accordance with this Policy.
          </>,
          <>
            While Bataiyo endeavors to safeguard your Personal Information
            and/or sensitive information at all times, Bataiyo cannot ensure its
            complete security and is not responsible for any security breaches
            by third parties. You should also take precautions to prevent
            unauthorised access to your computer or other electronic device as
            well as to your account's user name and password. If you access your
            account from a shared computer or electronic device, make sure you
            log out before leaving the computer or device.
          </>,
          <>
            Bataiyo would also like to caution its users about phishing attacks,
            wherein unscrupulous third parties seek to extract sensitive and
            confidential information from you by posing as a genuine website or
            by a sending an e-mail misrepresenting it to be from a genuine
            source. You understand that the transmission of information over the
            internet is not completely secure and there are risks associated
            with it. Please be careful while dealing with and/or responding to
            such e-mails. If you receive such a message claiming to be from
            Bataiyo, then please do not reply to it and immediately bring it to
            our attention by contacting the Grievance Officer.
          </>,
          <>
            Bataiyo employs reasonable security practises and procedures, has a
            thorough information security programme that is documented, and
            information security policies that contain managerial, technical,
            operational, and physical security control measures appropriate to
            the nature of Bataiyo's operations and the information being
            collected.
          </>,
        ],
      },
      {
        id: 10,
        title: "YOUR CONSENT AND YOUR RIGHTS AND EXERCISE OF RIGHTS",
        content: [
          <>
            You acknowledge that this Policy is a part of the User Agreement of
            the Platform and the other services, and you unconditionally agree
            that becoming a User of the Platform and its services signifies your
            (i) assent to this Policy, and (ii) consent to Bataiyo using,
            collecting, processing and/or disclosing your Personal Information
            and/or sensitive information in the manner and for the purposes set
            out in this Privacy Policy. Your visit to the Platform and use of
            the services is subject to this Policy and the User Agreement.
          </>,
          <>
            Your Rights (as applicable to Users from India, EU, UK and US):
            <br />
            a) You have the right to information, access, correction, deletion,
            restriction of processing, objection to processing, and data
            portability.
            <br />
            b) Article 21(1) EU General Data Protection Regulation (EU) 2016/679
            (“GDPR”) gives you the right to object at any time for reasons
            arising out of your particular situation against the processing of
            personal data relating to you when your data is processed under
            Article 6(1)(e) or Article 6 (1)(f) GDPR. This also applies to
            profiling. If you object, we will no longer process your personal
            data unless we can establish compelling and legitimate grounds for
            processing that outweigh your interests, rights, and freedoms, or if
            the processing aids the enforcing, exercising, or defending of legal
            claims.
            <br />
            c) If we process your personal data for the purpose of direct
            marketing Article 21(2) GDPR gives you the right to object at any
            time to the processing of your personal data for the purpose of
            direct marketing; this also applies to profiling, as far as it is
            associated with direct marketing.
            <br />
            d) If you object to processing for the purpose of direct marketing,
            we will no longer process your personal data for this purpose.
            <br />
            e) The Data Rights of the Citizens of California: You may have
            certain data rights under state privacy laws, such as the ability to
            request information regarding the manner in which Bataiyo collects
            your personal information, to obtain your personal information in a
            portable format, and to have your personal information amended or
            deleted. Additionally, you may have the right to opt out of the
            processing of your personal data for targeted advertising, by
            managing your browser settings or by contacting us at email ID
            placed on our Website. Consent is required for customers under the
            age of 16 to be exposed to cross-context behavioural advertising. We
            do not serve cross-context behavioural ads in customers' child
            profiles. If we deny a data request, you to appeal the denial of any
            of these rights by completing a form that we will furnish you with.
            Depending on the data options selected, specific services might be
            restricted or inaccessible. We will typically request that you
            authenticate your request using the contact information you have
            already provided in order to safeguard your Bataiyo account.
            Personal information of consumers, as defined by the California
            Consumer Privacy Rights Act, has not been sold by Bataiyo as of now.
            Bataiyo gathers and divulges certain types of data for some business
            purposes which includes "sensitive personal information" but this
            Sensitive personal information is not utilized or disclosed by
            Bataiyo for any purpose that is not explicitly authorized by the
            California Consumer Privacy Rights Act. In order to facilitate your
            ongoing utilization of Bataiyo Services, we shall retain your
            personal information for the duration necessary to fulfil the
            objectives outlined in this Privacy Notice, in accordance with legal
            requirements, as otherwise instructed to you. Any consumer who
            exercises their rights under the California Consumer Privacy Rights
            Act will not be subject to discrimination at Bataiyo.
          </>,
          <>
            We do not keep your data for longer than is necessary unless we are
            required to do so under law. We will use your Personal Information
            and/or sensitive information only for so long as is necessary:
            <br />
            a) to achieve the purposes that have been specifically identified in
            this Policy or such purposes as having been identified in any
            agreement entered into with you;
            <br />
            b) as required by applicable laws and regulations, or as may be
            required for evidentiary purposes;
            <br />
            c) as deemed necessary for tax, fraud control, or mitigation, and to
            conduct a defence in relation to any disputes or claims whether
            potential or actual (taking into account statute of limitations
            under applicable laws).
          </>,
          <>
            We want you to be in control of how your personal data is used by
            us. You can do this in the following ways: You can ask us for a copy
            of the personal data we hold about you, You can inform us of any
            changes to your personal data, or you can ask us to correct any of
            the personal data we hold about you. As explained below, you can
            make some of these changes yourself, online, when you have a user
            account, in certain situations, you can ask us to erase, block, or
            restrict the processing of the personal data we hold about you, or
            object to ways in which we are using your personal data, in certain
            situations, you can also ask us to send the personal data you have
            given us to a third party, where we are using your personal data
            based on your consent, you are entitled to withdraw that consent at
            any time subject to applicable law, and where we process your
            personal data based on legitimate interest or the public interest,
            you have the right to object to that use of your personal data at
            any time, subject to applicable law.
          </>,
          <>
            We rely on you to make sure that your personal information is
            complete, accurate and current. Please do let us know about any
            changes to, or inaccuracies in, your personal information as soon as
            possible.
          </>,
          <>
            If you have a Bataiyo user account, you can access a lot of your
            personal data through our website or apps. You’ll find the option to
            add, update or remove information we have about you in your account
            settings.
          </>,
          <>
            Please note that if you withdraw your consent, we may be constrained
            to withdraw those features, functionalities, services, etc. which
            cannot be provided, without the information you seek to withdraw
            consent for, or delete from our records. Please note that your
            requests with respect to your data shall not be complied with if it
            adversely affects another User or prejudices such User’s rights.
          </>,
          <>
            Your right to withdraw consent relates to any further collection or
            processing, of your sensitive personal data or Personal Information,
            however such withdrawal of consent shall not: (i) be retrospective
            or require the deletion of records required for statutory purposes;
            or (ii) operate where required for the discharge of ongoing
            contractual obligations unless the contract under which the
            sensitive personal data or Personal Information is required to be
            collected is also terminated with the request for withdrawal of
            consent to collect such sensitive data.
          </>,
        ],
      },
      {
        id: 11,
        title: "GRIEVANCE OFFICER",
        paragraphs: [
          <>
            Any complaints, abuse or concerns with regards to content and or
            comment or breach of these terms shall be immediately informed to
            the designated Grievance Officer mentioned below in writing or
            through email signed with the electronic signature:
          </>,
          <>
            Name: Dhanik Koul
            <br />
            E-mail id: contact@bgent.in
            <br />
            Mobile: 9682553935
          </>,
          <>
            Subject to the terms and conditions, Bataiyo shall, upon the written
            request of the User, delete the and/or sensitive information of the
            User, unless applicable law requires storage of the said Personal
            information and/or sensitive information. The mail may be sent to
            [●].
          </>,
        ],
      },
      {
        id: 12,
        title: "LIMITATION",
        paragraphs: [
          "Bataiyo will not be liable for any damages of any kind arising from the use of this platform, including, but not limited to compensatory, direct, indirect, incidental, punitive, special and consequential damages, loss of data, goodwill, business opportunity, income or profit, loss of or damage to property and claims of third parties. In no event Bataiyo will be liable for any damages whatsoever in an amount in excess of an amount of INR 10,000.",
        ],
      },
      {
        id: 13,
        title: "RETENTION OF DATA",
        paragraphs: [
          "We may retain the Personal Information and/or Sensitive Information for as long as is necessary to fulfil the purposes for which it was collected or as needed to provide the Services, even after you have discontinued or deleted any account, or after the end of the provision of the Services, if retention of such and/or Sensitive Information is reasonably necessary to comply with legal obligations, meet regulatory requirements, resolve disputes between Users, prevent fraud, or any other use. Subsequent to such a term, we will delete or anonymize your Personal Information and/or Sensitive Information once the purpose has been fulfilled.",
        ],
      },
      {
        id: 14,
        title: "FORCE MAJEURE",
        paragraphs: [
          "Notwithstanding anything contained in this Policy or elsewhere, Bataiyo shall be under no liability whatsoever in case of occurrence of a force majeure event, including in case of non-availability of any portion of Services by any ‘Act of God’, war, disease, revolution, riot, civil commotion, strike, lockout, flood, fire, failure of any public utility, man-made disaster, infrastructure failure, technology outages, failure of technology integration of partners or any other cause whatsoever, beyond the control of Bataiyo. Further, in case of a force majeure event, Bataiyo shall not be liable for any breach of security or loss of Personal Information and/or Sensitive Information, or any content uploaded by the User for availing the Services/on the Platform.",
        ],
      },
      {
        id: 15,
        title: "POLICY AMENDMENT",
        paragraphs: [
          "Bataiyo reserves the right to modify the Policy at any time in its sole discretion and to reflect changes to the services/Platform, for any changes in the law or for any reason whatsoever, with or without any notification to the User. Bataiyo is under no obligation to notify the implementation of the modifications to this Policy. Every time the User accesses the Platform, it will be the User’s responsibility to review the Policy. The User will be deemed to have accepted the Policy, as amended, if the User continues to access the services/Platform after the Policy is modified. If the User does not agree to the changes, the User must discontinue accessing the services including cancelling any paid services. Discontinuing use of the services will not affect the applicability of the Policy to the Users’ prior access to and use of the services/Platform.",
        ],
      },
      {
        id: 16,
        title: "NOTICE FOR IDIVIDUALS LOCATED IN EUROPE",
        content: [
          <>
            ‘If you are in the European Economic Area, United Kingdom and/or
            Switzerland, the policies in this section are specific to you. They
            describe how we market to you, our legal bases for processing your
            information and your rights.’
          </>,
          <>
            This section only applies to individuals that access or use our
            services while located in the European Economic Area, United Kingdom
            and/or Switzerland (collectively “Europe”). We may ask you to
            identify which country you are in when you use some of the services
            or we may rely on your IP address to identify in which country you
            are located. When we rely on your IP address, we cannot apply the
            terms of this section to any individual that masks or otherwise
            hides their location information from us so as not to appear located
            in Europe.
          </>,
          <>
            We are a controller regarding any personal information collected
            from individuals accessing or using our services. A “controller” is
            an entity that determines the purposes for which and the way any
            personal information is processed.
          </>,
          <>
            Marketing. We will only contact individuals located in Europe by
            electronic means (including email or SMS) based on our legitimate
            interests, as permitted by applicable law or the individual’s
            consent. When we rely on legitimate interest, we will only send you
            information about our services that are like those which were the
            subject of a previous offer or negotiations of an offer to you. If
            you do not want us to use your personal information in this way or
            to disclose your personal information to third parties for marketing
            purposes. You can object to direct marketing at any time and free of
            charge. Direct marketing includes any communications to you that are
            only based on advertising or promoting products and services.
          </>,
          <>
            Legal Bases for Processing. Our legal bases for the processing
            activities identified in this Policy are:
            <br />
            a) We rely on our contract with you as our legal basis for
            processing in relation to the following: to provide and maintain our
            services, to provide customer support or respond to you, to enforce
            compliance with our terms, agreements, or policies, and to share
            your information with service providers.
            <br />
            b) Depending on the specific circumstances, we rely on your consent
            or legitimate interest in relation to the following processing
            activities: to send you marketing and promotional emails, to advise
            you of other services, and to share your information with business
            partners, sponsors, or within our corporate organization.
            <br />
            c) We rely on legitimate interest in relation to the following
            processing activities: to personalize your experience, for research
            and development, and when we share your information with board
            members or volunteers or in relation to business transfers or
            bankruptcy.
          </>,
        ],
      },
    ],
  },
];
export const termsAndConditionsData = [
  {
    header: "Terms & Conditions",
    paragraphs: [
      <>
        The website and mobile application, offline channels including call
        centers and offices (“<b>Platform</b>”) is operated and run by Bgent
        Solutions Private Limited (“<b>Bataiyo</b>” or “<b>we</b>” or “<b>us</b>
        ” or “<b>our</b>”). Any person (“<b>User</b>” or “Users” or “<b>you</b>”
        or “<b>your</b>”) browsing, registering, accessing or using the Platform
        for accessing and utilising the services provided on the Platform (“
        <b>Services</b>”), shall be bound by these terms and conditions (“
        <b>Terms</b>”), Privacy Policy at [●] (“<b>Privacy Policy</b>”), all
        other rules, regulations and Terms referred to herein or provided by
        Bataiyo in relation to any of the Services and all applicable local,
        State, national and international laws, rules and regulations. You agree
        and acknowledge that you have completely read and understood these Terms
        and our Privacy Policy, incorporated herein by reference, as amended
        from time to time. You agree, covenant, and undertake to be bound by the
        specific rules and regulations of each of the Services as applicable.
      </>,
      <>
        If you do not agree with any part of these Terms or Privacy Policy,
        kindly refrain from using any Services provided on the Platform.
      </>,
      <>
        Any changes to these Terms will be posted on this page of the Platform
        wherever appropriate. The User must visit this page periodically to stay
        updated on the Terms applicable to their use of the Platform. Any User’s
        continued usage of the Platform after any such update, change,
        replacement or modification in the Agreement constitutes their valid
        consent to such update, change, replacement or modification.
      </>,
      <>
        These Terms constitute a legally binding document between you and
        Bataiyo. The Terms are published in accordance with the provisions
        applicable laws and the rules made thereunder. It is an electronic
        record generated by a computer system and does not require any physical,
        electronic or digital signatures.
      </>,
    ],
    sections: [
      {
        id: 1,
        title: "DEFINITIONS",
        content: [
          <>
            “<b>Agreement</b>” shall mean these Terms, the Privacy Policy and
            any other rules or policies available on the Platform including
            all other additional or modified terms and conditions and policies
            in relation to the Platform or any current or future services that
            may be offered by Bataiyo.
          </>,
          <>
            “<b>Applicable Law</b>” means all laws, statutes, common law,
            regulations, ordinances, codes, rules, guidelines, notifications,
            orders, permits, tariffs and approvals, including those relating to
            health and safety, of any governmental authority or self-regulatory
            guideline that may apply to the Users or is related to the subject
            matter of this Agreement as applicable in the territory of the User.
          </>,
          <>
            “<b>Parties</b>” Parties shall mean the Users, Provider and the
            Platform.
          </>,
          <>
            “<b>Provider</b>” shall mean the activity/service provider, who may
            be registered with the Platform as service/product provider and
            shall be solely responsible for provision of the products / services
            booked by the User.
          </>,
          <>
            “<b>Terms of Use</b>” shall mean the Agreement and may be used
            interchangeably.
          </>,
          <>
            “<b>Third-Party</b>” Third Party shall mean any person who is not a
            party to the Terms of Use, but shall not include any person for whom
            the services are availed by the User.
          </>,
          <>
            “<b>User</b>” A User shall mean any natural or legal person, who is
            competent to enter into a contract as per applicable laws, who uses
            the Platform, with or without registration, to avail the services
            offered, for himself or herself and / or for any other person.
          </>,
        ],
      },
      {
        id: 2,
        title: "ELIGIBILITY",
        content: [
          <>
            Any browsing, registering, downloading, using or accessing by any
            person of any part of the Platform or accessing any of the Services
            offered by Bataiyo will be deemed to constitute acceptance of the
            Agreement by the User thereby resulting in a legal and binding
            contract between the User and Bataiyo.
          </>,
          <>
            You agree, acknowledge and give permission to the Platforms to use
            certain technologies for monitoring of activities including logging
            of your IP address to ascertain and verify your geographical
            location for compliance with applicable laws.
          </>,
          <>
            Users shall always ensure that they are complying with the
            applicable law of their respective jurisdictions.
          </>,
          <>
            Any User who is incompetent to contract including minors,
            un-discharged insolvents, etc. is not eligible to enter into this
            Agreement with Bataiyo.
          </>,
          <>
            Only those Users who have successfully registered on the Platform in
            the manner prescribed in these Terms, and who utilize the services
            of the Platform in accordance with the Agreement, shall be eligible
            to use the Platform. Certain Services hosted on the Platform may be
            subject to additional rules and regulations applicable to each of
            the Services (“<b>Rules &amp; Regulations</b>”). Such Rules &amp;
            Regulations are deemed to be incorporated herein by reference. By
            accessing the Platform and the Services, Users accept the Rules
            &amp; Regulations. To the extent that these Terms are inconsistent
            with Rules &amp; Regulations, the Rules &amp; Regulations shall
            prevail.
          </>,
          <>
            Bataiyo reserves the right to terminate any User’s account or deny
            access to the Platform if it is brought to our notice that a
            particular User has violated the Agreement.
          </>,
        ],
      },
      {
        id: 3,
        title: "REGISTRATION",
        content: [
          <>
            In order to utilize the Platform and use the Services offered by
            Bataiyo, a User is required to register on the Platform. You are not
            authorised to participate in the Services until you have been
            granted an Account (defined below).
          </>,
          <>
            If you wish to become a registered User, you will need to create an
            account on our Platform (“<b>Account</b>”). Each User is only
            permitted to open 1 (one) Account. Bataiyo reserves the right to
            terminate or suspend any duplicate Account.
          </>,
          <>
            Users must register themselves on the Platform by providing their
            mobile number and one-time password (&quot;<b>OTP</b>&quot;).
          </>,
          <>
            You are required to provide only true, accurate, current, and
            complete information about yourself, including optional information
            such as your name, email address etc., while registering. The User
            is solely responsible for maintaining the confidentiality and
            security of all activities that occur on or through your access of
            the Platform.
          </>,
          <>
            Upon creation of the Account, you will be able to use our Platform
            and avail the Services hosted by us.
          </>,
          <>
            You agree and acknowledge that the Account can be terminated by us
            by giving notice to you, for any reason deemed fit by Bataiyo in its
            sole discretion.
          </>,
          <>
            You represent and warrant to us that, throughout the course of your
            usage of our Platform, you will not permit other persons to access
            or use your Account. If you permit other persons to access or use
            your Account, you do so at your own sole risk as to any
            consequences. You further agree and accept that you shall not access
            or use the Platform through the Account of another User.
          </>,
          <>
            Notwithstanding anything to the contrary herein, the Users
            acknowledge and agree that they shall have no ownership or other
            interest in their Account, and further acknowledge and agree that
            all rights in and to their Account are and shall forever be owned by
            and inure to the benefit of Bataiyo.
          </>,
          <>
            Your Account is for your personal use only. You shall not
            impersonate any other person. By creating an Account after providing
            a mobile phone number and verifying it using an OTP, you represent,
            warrant, and agree that you are authorized to use the Platform and
            will abide by the Agreement.
          </>,
          <>
            The Users are prohibited from operating multiple accounts. If it
            comes to the knowledge of Bataiyo that a User is operating multiple
            accounts, Bataiyo reserves the right to restrict, ban, suspend or
            terminate such User’s accounts, at its sole and absolute discretion
            without any notice.
          </>,
          <>
            Where Bataiyo conducts any customer identity checks,
            this neither implies that Bataiyo is statutorily required to conduct
            such checks nor liable for any inaccuracy in customer verification
            that cannot be directly attributable to Bataiyo’ gross negligence.
            You are responsible for completing your ‘Know Your Customer’ (KYC)
            checks with your respective banks, financial institutions, card
            associations and other payment system providers as defined under the
            Payment and Settlement Systems Act, 2007 (
            <b>Payment System Providers</b>), with whom Bataiyo has entered into
            an arrangement to facilitate payment transactions (i.e. to send or
            receive funds via the Platform) using the services of such Payment
            System Providers. Bataiyo conducts such checks as a measure to
            reduce financial crime in its multiple forms, such as online fraud,
            money laundering, or the financing of terrorism. The User agrees to
            comply with customer identity checks or other related policies of
            the Platform from time-to-time and to provide any information on
            request of law enforcement agencies. The User agrees to update the
            information on any change in Applicable Law.
          </>,
        ],
      },
      {
        id: 4,
        title: "RELATIONSHIP BETWEEN US",
        content: [
          <>
            We merely provide a technology platform and infrastructure that
            enables you to connect with other Users and Providers listed on the
            Platform and to use the messaging or communication facilities of the
            Platform that are designed to enable you to communicate with other
            Users and Providers (‘<b>Communication Channels</b>”).
          </>,
          <>
            We shall not be responsible for any misrepresentation or fraud made
            by User or Provider. We do not recommend or endorse any User or
            Provider or make any representations or warranties with respect to
            the quality of the services of a Provider. All information provided
            to you via our Platform are as per the information provided by the
            User/Provider. This information is for reference only. Bataiyo shall
            not take any liability for such discrepancies.
          </>,
          <>
            We shall not be responsible or liable for any defect or deficiency
            services Provided to You via our Platform.
          </>,
          <>
            The Service we provide is NOT for use for medical emergencies, for
            life threatening conditions or for when you need acute care. You
            should not disregard or delay to seek any advice including medical
            advice from a professional based on anything that appears or is
            provided to you by or through the Service.
          </>,
          <>
            We make no guarantees, representations or warranties, whether
            expressed or implied, with respect to professional qualifications,
            expertise, quality of work or other information herein. We do not in
            any way endorse any individual described. In no event shall we be
            liable to you or anyone else for any decision made or action taken
            by you in reliance on such information.
          </>,
          <>
            You are strongly advised to perform your own investigation prior to
            selecting any Provider’s services or Users recommendations.
          </>,
        ],
      },
      {
        id: 5,
        title: "PAYMENT FOR SERVICE",
        content: [
          <>
            Some parts of the Service are chargeable as may be specified from
            time to time. We will inform you about applicable charges for any
            paid Service and the payment terms presented to you when you use or
            order a paid Service are deemed part of these Terms of Use. Bataiyo
            does not collect any payment / charges from the User. All payment
            transactions are routed via secure server software through third
            party payment gateways and is directly paid to the receiving party.
            Bataiyo only directs you to the payment gateway and does not have
            any control over the payment transactions. We shall not be
            responsible for any Service that is not provided to you if you fail
            to pay the applicable charges for the same. Charges paid are
            non-refundable except in our sole discretion.
          </>,
          <>
            Since we use a third-party payment gateway for processing payments
            due to us, the processing of payments will be subject to the terms,
            conditions and privacy policies of the payment gateway, in addition
            to these Terms. We don’t control and are not liable for the security
            or performance of the payment gateway. You agree to pay us, through
            the payment gateway, all charges at the rates then in effect for any
            purchase in accordance with the applicable payment terms. We reserve
            the right to correct, or to instruct our payment gateway to correct,
            any errors or mistakes, even if payment has already been requested
            or received.
          </>,
          <>
            We do not store any payment details including but not limited to net
            banking, credit card or debit card details.
          </>,
        ],
      },
      {
        id: 6,
        title: "INTELLECTUAL PROPERTY RIGHTS",
        content: [
          <>
            Bataiyo uses a combination of content created by it, its partners,
            affiliates, licensors, associates and Users. The intellectual
            property rights (“<b>Intellectual Property Rights</b>”) in all
            software and material published on Platform, including but not
            limited to Services, pools, written content, photographs, graphics,
            images, illustrations, marks, logos, audio clippings, video
            clippings and animation, is owned by Bataiyo, its partners,
            licensors or associates. No User shall modify, publish, transmit,
            participate in the transfer or sale of, reproduce, create derivative
            works of, distribute, publicly perform, publicly display, or in any
            way exploit any of the materials or content on the Platform either
            in whole or in part without express written license from Bataiyo.
          </>,
        ],
      },
      {
        id: 7,
        title: "COMMUNITY POLICIES",
        content: [
          <>
            We deeply understand the psychological distress that abusive content
            can have on individuals, and we do not tolerate abusive content or
            behaviour on our platform. Users should feel safe to express
            themselves responsibly without fear of being shamed, humiliated,
            bullied, or harassed. No User shall:
            <br />
            (a) promote violence against or directly attack or threaten other
            Users, especially on the basis of race, ethnicity, national origin,
            caste, sexual orientation, gender, gender identity, religious
            affiliation, age, disability or serious disease. Users inciting harm
            towards other Users would be suspended;
            <br />
            (b) choose a username, display name or profile info which may result
            in targeted harassment or expressing hate towards a person, a group,
            or a protected category;
            <br />
            (c) use hateful images or symbols in your profile image or profile
            header;
            <br />
            (d) use abusive language or share other Users’ personal or sensitive
            information;
            <br />
            (e) threaten or incite violence;
            <br />
            (f) upload content with the intent to shame, deceive or insult a
            minor;
            <br />
            (g) upload content that wishes death, serious disease, physical, or
            other harm on an individual;
            <br />
            (h) upload content that praises violent tragedies and disparages
            their victims;
            <br />
            (i) post an individual’s non-public personal identifying information
            like a phone number, home address, or email to direct abusive
            attention or traffic toward them; or
            <br />
            (j) stalk or attempt to blackmail other Users.
            <br />
          </>,
          <>
            Any User found violating any community policy shall be barred from
            the Platform and the Account shall be suspended, at the sole
            discretion of Bataiyo.
          </>,
        ],
      },
      {
        id: 8,
        title: "RESTRICTIONS",
        content: [
          <>
            You shall not use the Platform, or any content provided thereof for
            any purpose that is illegal, unlawful or prohibited by this
            Agreement or under Applicable Law.
          </>,
          <>
            You will not use the Platform, or any content provided thereof:{" "}
            <br />
            (a) to host, display, upload, modify, publish, transmit, update or
            share any information that belongs to another person and to which
            the User does not have any right to;
            <br />
            (b) is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, paedophilic, libellous, invasive of another’s privacy,
            hateful, or racially, ethnically objectionable, disparaging;
            <br />
            (c) that is relating to or encourages money laundering or gambling,
            or is otherwise unlawful in any manner whatsoever;
            <br />
            (d) that harms minors in any way;
            <br />
            (e) that infringes any patent, trademark, copyright or other
            proprietary rights; violates any Applicable Law for the time being
            in force;
            <br />
            (f) that communicates any information which is grossly offensive or
            menacing in nature;
            <br />
            (g) which impersonates another person; contains software viruses or
            any other computer code, files or programmes designed to interrupt,
            destroy or limit the functionality of the Platform or any other
            computer resource;
            <br />
            (h) that threatens the unity, integrity, defence, security or
            sovereignty of India, friendly relations with foreign states, or
            public order, or causes incitement to the commission of any
            cognisable offence or prevents investigation of any offence or is
            insulting any other nation;
            <br />
            (i) threatens critical information infrastructure;
            <br />
            (j) that threatens public health or safety; or
            <br />
            (k) that promotes cigarettes or any other tobacco products or
            consumption of intoxicant including alcohol and Electronic Nicotine
            Delivery System (ENDS) and like products that enable nicotine
            delivery except for the purpose &amp; in the manner and to the
            extent, as may be approved under the Drugs and Cosmetics Act, 1940
            and Rules made thereunder.
            <br />
          </>,
          <>
            You will not redistribute, sublicense, rent, publish, sell, assign,
            lease, market, transfer, or otherwise make the Platform or any
            component or content thereof, available to third parties without the
            permission of Bataiyo.
          </>,
          <>
            You will not circumvent or disable any digital rights management,
            usage rules, or other security features of the Platform; remove,
            alter, or obscure any proprietary notices (including copyright
            notices) on any portion of the Platform; and not use the Platform in
            a manner that threatens the integrity, performance, or availability
            of the Platform.
          </>,
          <>
            You will not attempt to or engage in any activity that may:
            <br />
            (a) reverse engineer, decompile or otherwise extract the source code
            related to the Platform or any part thereof, unless it is expressly
            permitted by Bataiyo to you in writing or is required by the
            Applicable Law;
            <br />
            (b) use any robot, spider, retrieval application, or other device to
            retrieve or index any portion of the Platform or content thereof;
            <br />
            (c) collect information about Users for any illegal or unlawful
            purpose;
            <br />
            (d) create any Account by automated means or under false or
            fraudulent pretences for using the Platform;
            <br />
            (e) transmit any viruses, worms, defects, trojan horses, or any
            items of a destructive nature through the Platform;
            <br />
            (f) use the Platform in any manner that could damage, disable,
            overburden, or impair, or undertake any action which is harmful or
            potentially harmful to, any of the servers, networks, computer
            systems or resources connected to any of the servers connected,
            directly or indirectly to the Platform, or interfere with any other
            Users’ use and enjoyment of the Platform;
            <br />
            (g) carry out any denial of service (DoS, DDoS) or any other harmful
            attacks on the Platform;
            <br />
            (h) disrupt or place unreasonable burdens or excessive loads on, or
            interfere with or attempt to make, or attempt any unauthorized
            access to the Platform or any part of the Platform or any User of
            the Platform;
            <br />
            (i) forge headers or otherwise manipulate identifiers to disguise
            the origin of any content transmitted through the Platform; or
            <br />
            (j) obtain any materials or information through any means not
            intentionally made available, in our opinion, through the Platform.
            <br />
          </>,
          <>
            You will not impersonate another person or impersonate, guide or
            host on behalf of, or falsely state or otherwise misrepresent your
            affiliation with any person or entity, including, but not limited to
            our officials, employees, agents, partners, affiliates, dealers and
            franchisees.
          </>,
          <>
            We reserve the right to deactivate or delete your Account and all
            related information on it or take any other appropriate action, at
            our sole and absolute discretion, in the event of any fraudulent,
            illegal or suspicious activities from your Account on the Platform.
          </>,
          <>
            You are responsible for any breach of your obligations under the
            Agreement and for the consequences of any such breach.
          </>,
        ],
      },
      {
        id: 9,
        title: "REFUSAL OF SERVICES",
        content: [
          <>
            We reserve the right to refuse its services to any of the Parties
            for any reason whatsoever.
          </>,
          <>
            We reserve the right to refuse services if the User or any Provider
            fails to adhere to or violates the Agreement or any change /
            amendment / modification made to the Agreement or does any act or
            omission including but not limited to resulting in harassment or
            misconduct by the User.
          </>,
          <>
            In the event that the User has availed the services of the Platform
            to secure an appointment and subsequently the Provider refuses to
            provide his / her services to the User, the Bataiyo shall not be
            held liable.
          </>,
        ],
      },
      {
        id: 10,
        title: "THIRD PARTY LINKS & ADVERTISEMENTS",
        content: [
          <>
            The Platform may contain links to third party websites /
            applications and third-party advertisements that are not maintained
            or controlled by us. Third party links and advertisements are
            provided for your convenience only and does not imply endorsement or
            any representation or warranty by us to content, goods or services
            that may be offered by these third parties.
          </>,
          <>
            When you click on any of these third-party links, you are leaving
            the Platform and will be subject to the terms of use and privacy and
            security policies of the third-party site. You link to any such
            third-party website /applications entirely at your own risk.
          </>,
        ],
      },
      {
        id: 11,
        title: "NO WARRANTY",
        content: [
          <>
            Bataiyo shall not undertake warranty for any loss / damage / injury
            caused to the User or to any other person for whom such services
            were availed basis recommendations provided on the Platform. Bataiyo
            gives no warranty or assurance with regard to requisite licenses,
            approvals, insurance and permits which the Provider listed, if any,
            is supposed to obtain from appropriate authorities for providing the
            services it is providing to the Users.
          </>,
          <>
            Bataiyo shall not be liable for any acts or omissions of the
            Provider / User recommendations which amount to negligence /
            professional misconduct / deficiency in service / violation of any
            law in force.
          </>,
        ],
      },
      {
        id: 12,
        title: "ACCOUNT DELETION",
        content: [
          <>
            You can delete your Account at any time by following the process
            provided on the Platform (“<b>Account Deletion</b>”).
          </>,
          <>
            Once you opt for Account Deletion on the Platform, we will process
            this request as per the timeline communicated to you at the time of
            submission of the Account Deletion request. In all cases, we will
            confirm our acceptance of the Account Deletion request within 48
            hours of making such request, and your Account will be deleted
            within 7 working days of acceptance of the request.
          </>,
          <>
            Users acknowledge that Account Deletion is irreversible. Once
            deleted, the Account cannot be retrieved for any reason whatsoever.
          </>,
        ],
      },
      {
        id: 13,
        title: "SECURITY",
        content: [
          <>
            Access to your Account and to add, delete or modify any content on
            your Account is password / OTP protected.
          </>,
          <>
            We are not liable for any loss, damages or expenses of any kind
            incurred or suffered by you arising from or in connection with any
            failure, delay or interruption, interception, interference, in
            transmitting passwords/OTPs due to any failure of the mobile service
            network or any other circumstance beyond our reasonable control.
          </>,
          <>
            You agree that you will not disclose your password / OTP to anyone
            or allow any other person to access or use your Account. We are not
            responsible if your Account is accessed or opened by other persons
            using your password. You shall be held solely responsible and liable
            for all of your activities on the Platform made from your Account.
          </>,
          <>
            You understand that it is your obligation to protect the information
            you have provided on the Platform including, but not limited to
            usernames, passwords, email addresses, contact details, bank account
            information etc. You explicitly understand and agree that we will
            not incur any liability for information provided by you to anyone
            which may result in your Account on the Platform being exposed or
            misused by any other person or any other harm or loss being suffered
            by you.
          </>,
          <>
            Bataiyo shall not be liable to a User for any authorised transaction
            which has been executed through the Platform as a result of:
            <br />
            (a) fraud committed by any User using User’s access credentials
            before the User has notified Bataiyo in writing of the loss or theft
            or such access credentials, as a result of which Bataiyo has been
            unable to take any action to disable the access credentials;
            <br />
            (b) fraud committed by third parties against Users which may not be
            preventable by, or occur despite the security systems of the
            Platform; or
            <br />
            (c) a voluntary action or transaction initiated suo motu by any User
            at the behest of another User, which is not initiated by Bataiyo for
            the transfer of funds, credit or stored value to another User.
            <br />
          </>,
          <>
            Please also contact us immediately if your mobile or computer device
            through which you access the Platform has been lost, stolen or
            compromised in any way or someone has used or may use your mobile or
            computer device or credentials without your authorisation or if you
            become aware of any unauthorised use of your Account.
          </>,
          <>
            Please note that we can terminate or suspend your Account and all
            associated accounts, institute fraud prevention measures such as
            access restrictions, transaction velocity restrictions etc., without
            prior notice to prevent unauthorised use of our Platform. You must
            cooperate with us in any investigation and use any fraud prevention
            measures we prescribe, and you agree that we will not be liable to
            you or any third party for any block, suspension, cancellations, or
            termination of your use of an Account owing to such measures.
          </>,
          <>
            You are hereby informed that any and all information associated with
            any fraudulent transaction may be shared by Bataiyo (with or without
            demand) with the Government of India or any agency thereof, for the
            purpose of verification of the identity of User, or for prevention,
            detection, investigation, prosecution, and punishment
          </>,
          <>
            Bataiyo has taken all reasonable measures to secure its services as
            prescribed under Information Technology (Reasonable Security
            Practices and Procedures and Sensitive Personal Information) Rules,
            2011.
          </>,
        ],
      },
      {
        id: 14,
        title: "RIGHT TO RESTRICT, SUSPEND OR TERMINATE",
        content: [
          <>
            Bataiyo may change, suspend or discontinue any aspect of the
            Platform at any time, including the availability of any Platform’s
            feature, database, or content. We may also impose limits on certain
            features and services or restrict your access to parts or the
            Platform, without notice or liability at any time in our exclusive
            discretion, without prejudice to any legal or equitable remedies
            available to us, for any reason or purpose. However, under normal
            circumstances, we will only do so where there has been conduct that
            we believe violates these Terms or other rules and regulations or
            guidelines posted on the Platform or conduct which we believe is
            harmful to other Users, to our businesses, or other information
            providers.
          </>,
          <>
            In the event any User breaches, or Bataiyo believes that such User
            has breached the Terms, or has illegally or improperly used the
            Platform or the Services, Bataiyo may, at its sole and absolute
            discretion, and without any notice to the User, restrict, suspend or
            terminate such User’s access to all or any part of the Services,
            deactivate or delete the User’s Account and all related information
            on the account, delete any content posted by the User on the
            Platforms and further, take technical and legal steps as it may deem
            necessary to us.
          </>,
          <>
            We may also terminate this Agreement at any time, with or without
            notice and may disable your access to the Platform or bar you from
            any future use of the Platform.
          </>,
          <>
            Upon any termination of these Terms, you shall immediately
            discontinue your use and access to the Platform and destroy all
            materials obtained from it. You hereby agree and consent to the
            above and agree and acknowledge that we can, at our sole discretion,
            exercise our right in relation to any or all of the above, and that
            we shall not be liable in any manner of the same; and you agree,
            acknowledge and consent to the same.
          </>,
          <>
            You acknowledge that your representations, undertakings, and
            warranties and the clauses relating to indemnities, limitation of
            liability, grant of license, governing law and jurisdiction,
            confidentiality shall survive the efflux of time and the termination
            of these Terms.
          </>,
        ],
      },
      {
        id: 15,
        title: "MODIFICATION OF TERMS",
        content: [
          <>
            Bataiyo shall be entitled to modify any of these Terms and Privacy
            Policy in relation to any of the Services, at any time, by posting
            the same on the Platform. Use of the Platform constitutes the User’s
            acceptance of such Terms in relation to any of the Services, as may
            be amended from time to time. Bataiyo, at its sole discretion, may
            also notify the User of any change or modification in these Terms
            referred to herein or provided by Bataiyo, by way of sending an
            e-mail to the User’s registered e-mail address or posting
            notifications in the User’s accounts. The User may then exercise the
            options provided in such an e-mail or notification to indicate
            non-acceptance of the modified Terms. If such options are not
            exercised by the User within the timeframe prescribed in the e-mail
            or notification, the User will be deemed to have accepted the
            modified Terms.
          </>,
        ],
      },
      {
        id: 16,
        title: "DISCLAIMERS",
        content: [
          <>
            Bataiyo disclaims any and all warranties, expressed or implied, in
            connection with the Platform and the Services, all of which are
            provided to the User as is.
          </>,
          <>
            You agree that the access to the Platform and the Services is at
            your own risk. The Platform, the Services and any other materials
            contained on or provided through the Platform are provided “as is”
            and, to the fullest extent permitted by law, are provided without
            warranties of any kind, either express or implied. Without limiting
            the foregoing, Bataiyo does not make any warranties of fitness for a
            particular purpose, title, merchantability, completeness,
            availability, security, compatibility or non-infringement; or that
            the Services will be uninterrupted, free of viruses, malware or
            other harmful components, accurate, error free or reliable.
          </>,
          <>
            No person affiliated or claiming affiliation with the Platform has
            authority to extend such warranties.
          </>,
          <>
            Without limiting the generality of the foregoing, to the maximum
            extent permitted by Applicable Law, we shall have no liability for
            any damages or injury caused, in whole or in part, by contingencies
            or issues beyond our reasonable control, including, but not limited
            to: the acts of third parties, errors in the content or the
            Platform, network failures, internet failures, software and hardware
            failures, viruses and other system attacks, labour Page 9 of 2
            stoppages, riots, acts of government or god, natural disasters, acts
            of terrorism, communication line failure, or theft, destruction of,
            unauthorized access to, alteration of or use of records.
          </>,
          <>
            To the maximum extent permitted by Applicable Law, you hereby agree
            that you shall have no remedy in respect of any statement,
            representation, assurance or warranty (whether made innocently or
            negligently) that is not set out in these Terms. You agree that you
            shall have no claim for innocent or negligent misrepresentation or
            negligent misstatement based on any statement in these Terms.
          </>,
          <>
            The foregoing limitations shall apply regardless of whether (i)
            liability or damage is alleged for breach of contract, tortious
            behaviour, negligence, or under any other theory or cause of action,
            and/or (ii) the party against which liability or damages is sought
            was advised of the possibility thereof.
          </>,
          <>
            For the avoidance of doubt, nothing in these Terms shall limit or
            exclude liability for (i) death or personal injury caused by
            negligence; or (ii) fraud.
          </>,
          <>
            You agree and acknowledge that Bataiyo does not promise or ensure
            that you will be able to access your Account whenever you want. It
            is entirely possible that you may not be able to access your Account
            or the Platform at times or for extended periods of time due to, but
            not limited to system maintenance and updates.
          </>,
          <>
            You will be solely responsible for any damage to your mobile phone
            or loss of data that results from the installation of any software
            on your mobile phone and we expressly disclaim liability for any
            such loss or damage.
          </>,
          <>
            You agree to assume the entire risk as to the results and
            performance of any software or Services availed by you on the
            Platform. As such, the result and performance among other things
            depends on your internet connection and hardware. You also assume
            the entire cost of all servicing, repair or correction of your
            hardware when you access the Services available on the Platform.
          </>,
          <>
            We may stop provision of the Platform (or any part thereof),
            permanently or temporarily, to You or to Users generally or may
            modify or change the nature of the Platform or these Terms at our
            sole discretion, without any prior notice to you. Your use of the
            Platform following any such modification constitutes your deemed
            acceptance to be bound by the Agreement (or as it may be modified).
          </>,
          <>
            You agree that you are responsible for all data charges you incur
            through use of the Platform.
          </>,
        ],
      },
      {
        id: 17,
        title: "GRIEVANCE OFFICER",
        content: [
          <>
            In accordance with the Information Technology Act, 2000 and rules
            made thereunder, we have a Grievance Officer to address your
            concerns regarding data safety, privacy, and the Platform usage
            concerns including complaints you have against other Users on the
            Platform. We will resolve these issues raised by you within thirty
            (30) days from receiving them. You may contact the Grievance Officer
            at the following coordinates:
            <br />
            Name: Dhanik Koul
            <br />
            Address: 52-d regal , shipra sun city , indirapuram , ghaziabad.
            <br />
            Office Hours: 11:00 a.m to 5:00 PM
            <br />
            e-mail: contact@bgent.in
          </>,
        ],
      },
      {
        id: 18,
        title: "INDEMNITY",
        content: [
          <>
            The Users agree to indemnify, defend and hold harmless Bataiyo, and
            its shareholders, directors, officers, employees, agents,
            representatives, vendors and business partners (collectively, “
            <b>Parties</b>”), from and against any losses, liabilities, claims,
            damages, demands, costs and expenses (including legal fees and
            disbursements in connection therewith and interest chargeable
            thereon) asserted against or incurred by us that arise out of,
            result from:
            <br />
            (a) any breach or non-performance of any representation, warranty,
            covenant or agreement made or obligation to be performed according
            to these Terms;
            <br />
            (b) User’s use of the Platform;
            <br />
            (c) User’s violation of the Terms;
            <br />
            (d) User’s violation of any rights of any other User or person;
            <br />
            (e) User’s alleged improper conduct according to these Terms;
            <br />
            (f) User’s conduct in connection with the Platform or the Services;
            <br />
            (g) User’s breach of any Applicable Law or governmental or judicial
            order which applies to your use of the Platform from a specific
            location;
            <br />
            (h) the use by us of information provided by you through our
            Platform; or
            <br />
            (i) any unauthorized, improper, illegal or wrongful use of your
            Account by any person, including a third party, whether or not
            authorized or permitted by you.
            <br />
          </>,
          <>
            The User agrees to fully cooperate in indemnifying Bataiyo at the
            User’s expense. The User also agrees not to settle with any of the
            Parties without the consent of Bataiyo.
          </>,
          <>
            In no event Bataiyo shall be liable to compensate the User or any
            third-party for any special, incidental, indirect, consequential or
            punitive damages whatsoever, including those resulting from loss of
            use, data or profits, whether or not foreseeable, and whether or not
            Bataiyo had been advised of the possibility of such damages, or
            based on any theory of liability, including breach of contract or
            warranty, negligence or other tortuous action, or any other claim
            arising out of or in connection with the User’s use of or access to
            the Platform or Services.
          </>,
          <>
            This indemnification obligation will survive the expiry or
            termination of these Terms and your use of the Platform.
          </>,
          <>
            There could be instances where Bataiyo is not able to receive prompt
            updates about the change in Applicable Law of your place of stay. It
            is your obligation to ensure that any activities you engage on the
            Platform are legal as per the Applicable Law. You agree to indemnify
            and hold harmless, Bataiyo from any claim, demand, damage, or loss
            whatsoever coming out due to your non-compliance with the laws of
            your jurisdiction.
          </>,
        ],
      },
      {
        id: 19,
        title: "LIMITATION OF LIABILITY",
        content: [
          <>
            You clearly understand and agree that Bataiyo shall under no
            circumstances (including, without limitation, in contract,
            negligence or other tort), be liable for any direct, indirect,
            ancillary, special, incidental, consequential or exemplary or
            punitive damages. This includes, but is not limited to injury,
            claim, loss of data, loss of income, loss of profit or loss of
            opportunity, loss of or damage to property, damages for monetary
            loss, goodwill, use, data or other intangible loss (even if Bataiyo
            has been advised of the possibility of such damages), resulting from
            or arising out of the use of or the inability to use the Platform,
            even if we have been advised or become aware of the possibility of
            such damages or loss or that such loss was foreseeable.
          </>,
          <>
            Our total liability to you for any damages (regardless of the
            foundation for the action) shall not exceed the amounts paid by you
            for availing the Services in last one (1) month from your Account.
          </>,
          <>
            To the fullest extent permitted by law, Bataiyo shall not be liable
            to you or any other party for any loss or damage, regardless of the
            form of action or basis of any claim. You acknowledge and agree that
            your sole and exclusive remedy for any dispute with us is to
            terminate your use of the Platform.
          </>,
        ],
      },
      {
        id: 20,
        title: "FORCE MAJEURE",
        content: [
          <>
            Bataiyo shall not be liable for any damages whatsoever arising out
            of Force Majeure Event (defined below) or other similar
            circumstances, directly or indirectly to the Users as a result of
            using the Services offered by us on the Platform. A Force Majeure
            Event means and includes real or potential labour disputes,
            governmental actions, war or threat of war, sabotage, civil unrest,
            demonstrations, fire, storm, flooding, explosion, Page 11 of 2
            earthquake, provisions or limitations of materials or resources,
            inability to obtain the relevant authorization, accident, and defect
            in electricity or telecommunication network that affects our ability
            to offer the Services on the Platform. Force Majeure Event or any
            other event beyond our control hindering, delaying or complicating
            the maintenance of the Platform entitles Bataiyo to suspend or limit
            the Platform until further notice without being liable in any manner
            whatsoever.
          </>,
        ],
      },
      {
        id: 21,
        title: "INTERPRETATION",
        content: [
          <>
            Any reference to the singular includes a reference to the plural and
            vice versa, and any reference to one gender includes a reference to
            other gender(s), unless explicitly provided for.
          </>,
          <>
            Headings and captions are used for convenience only and not for
            interpretation of the Agreement.
          </>,
          <>
            Any reference to a person shall include their heirs, executors and
            permitted assignees.
          </>,
          <>
            If any judicial or quasi-judicial body in India declares any of the
            provisions of these Terms to be unlawful, invalid, void or
            unenforceable for any reason, the validity and enforceability of the
            remaining provisions will not be affected. Any such inappropriate
            term or condition will be replaced with another term or condition
            that is valid and enforceable and is in most nearly with effect to
            the original invalid term. In such event, the parties shall be
            obligated to construe and interpret the void, illegal or
            unenforceable provision with a valid, legal and enforceable
            provision in such manner that corresponds as closely as possible to
            Bataiyo’ intent and objective in the void, illegal or unenforceable
            provisions.
          </>,
        ],
      },
      {
        id: 22,
        title: "GOVERNING LAW AND DISPUTE RESOLUTION",
        content: [
          <>
            The validity, interpretation and implementation of this Agreement
            shall be governed by, and construed in accordance with, laws of
            India without regard to its conflict of law principles.
          </>,
          <>
            In case you have any grievances with respect to the Platform or the
            Services, you can send an e-mail to Grievance Officer. We reserve
            the right to replace the Grievance Officer at any time without any
            notice or intimation to you. You can directly escalate any disputes
            to our management team by contacting the Grievance Officer.
          </>,
          <>
            Subject to the clauses below, the courts of competent jurisdiction
            at Pune shall have exclusive jurisdiction to determine any and all
            disputes arising out of, or in connection with the Platform provided
            by Bataiyo (including the Services, construction, validity,
            interpretation and enforceability of these Terms, or the rights and
            obligations of the Users), as well as the exclusive jurisdiction to
            grant interim or preliminary relief in case of any dispute referred
            to arbitration as given below.
          </>,
          <>
            In the event of any legal dispute (which may be a legal issue or
            question) which may arise, the party raising the dispute shall
            provide a written notification (<b>Notification</b>) to the other
            party. On receipt of Notification, the parties shall first try to
            resolve the dispute through discussions. In the event that the
            parties are unable to resolve the dispute within fifteen days of
            receipt of Notification, the dispute shall be settled by
            arbitration.
          </>,
          <>
            The seat and venue of arbitration shall be Pune, India. All
            arbitration proceedings shall be conducted in English and in
            accordance with the provisions of the Arbitration and Conciliation
            Act, 1996, as amended from time to time. The parties shall mutually
            appoint a sole arbitrator.
          </>,
          <>
            The arbitration award will be final and binding on the parties, and
            each party will bear its own costs of arbitration and equally share
            the fees of the arbitrator unless the arbitral tribunal decides
            otherwise.
          </>,
          <>
            The arbitrator shall be entitled to pass interim orders and awards,
            including the orders for specific performance and such orders would
            be enforceable in competent courts of Pune. The arbitrator shall
            give a reasoned award.
          </>,
          <>
            Nothing contained in these Terms shall prevent Bataiyo from seeking
            and obtaining interim or permanent equitable or injunctive relief,
            or any other relief available to safeguard its interest prior to,
            during or following the filing of arbitration proceedings or pending
            the execution of a decision or award in connection with any
            arbitration proceedings from any courts of competent jurisdiction at
            Pune to grant the same. The pursuit of equitable or injunctive
            relief shall not constitute a waiver on the part of Bataiyo to
            pursue any remedy for monetary damages through the arbitration
            described herein.
          </>,
        ],
      },
      {
        id: 23,
        title: "PRIVACY POLICY",
        content: [
          <>
            All information collected from the Users, is subject to Bataiyo’
            Privacy Policy (“Privacy Policy”) which is available at [-]
          </>,
        ],
      },
    ],
  },
];
export const disclaimerData = [
  {
    header: "Disclaimer",
    paragraphs: [
      <>
        The information provided by Bataiyo (“we”, “us”, “our”) on our customer
        interface channels including its website, mobile site, mobile app &
        offline channels including call centers and offices (“Platform”) is for
        general informational purposes only. The term “you” refers to anyone who
        uses, visits and/or views the website. All information on the Platform
        is provided in good faith, however, we make no representation or
        warranty of any kind, express or implied, including without limitation
        regarding the accuracy, adequacy, validity, reliability, availability,
        merchantability, fitness for a particular purpose, non-infringement,
        security, or completeness of any information on the Site or our mobile
        application. Accessing of such information does not establish any kind
        of professional-client relationship by your use of this website.
      </>,
      <>
        Any content/information provided regarding products/services have not
        been evaluated or approved by any statutory body. Such information
        provided under this feature is brought to you on an, "as is" and "as
        available" basis, without any warranty of any kind, express or implied.
        Under no circumstances shall we have any liability for any loss or
        damage of any kind incurred because of the use of the Platform or
        reliance of any information provided on the Platform and such reliance
        is solely at your risk.
      </>,
      <>
        The Platform information is provided for general information only and is
        not suitable for professional advice.
      </>,
      <>
        Any testimonials by users our products/services reflect real life
        experiences and opinions of such users. These experiences are personal
        to each individual and we do not claim that all users will have similar
        experiences. We are not affiliated with any users who post testimonials
        and users are not paid for or otherwise compensated for their
        instructions.
      </>,
      <>
        We may partner various affiliate marketing programs or deal with other
        businesses whose products or services may be promoted or advertised on
        the website in exchange for any kind of financial rewards if you click
        and/or purchase those products or services through our affiliate links.
        We may receive a commission if you make a purchase through our affiliate
        link at no extra cost to you.
      </>,
      <>
        We may recommend other products, services, or advisors, but such
        references do not warrant or represent that the information provided is
        accurate. These recommendations are based on our/user’s personal
        experience, but it is your responsibility to conduct due diligence to
        ensure that you receive accurate information regarding such products,
        services, and advisors. These affiliate or third-party relationships in
        no way compromise the integrity of the content, information, services,
        and materials being presented to you here, and you are under no
        obligation to click on these affiliate links to purchase those products
        or services being offered. These affiliate programs are selected based
        on personal experiences and preferences.
      </>,
      <>
        The Platform may contain links to other websites or content owned or
        created by third parties or links to websites and features or other
        advertising. We do not have control over any external websites and these
        websites are exclusively responsible for their own content and
        information presented. Therefore, Bataiyo and/or its officers,
        employees, successors, shareholders, joint venture partners or others
        working with us shall not be held liable for any content presented on
        these external websites and for any damages resulting therefrom.
      </>,
      <>
        This disclosure policy applies to all affiliate links shared by us on
        our website, social media, emails, programs, products and services and
        any other means of communication with you.
      </>,
    ],
  },
];
export const cookiesPolicyData = [
  {
    header: "Cookies Policy",
    paragraphs: [
      <>
        Bgent Solutions Private Limited and its subsidiaries / affiliates (“
        <b>Bataiyo</b>” or “<b>we</b>”) are committed to protecting the privacy
        of your personally identifiable information. We advise you to read this
        Cookie Policy (“<b>Policy</b>”) carefully to understand the use of
        cookies on any of Bataiyo’s customer interface channels including its
        website, mobile site, mobile app &amp; offline channels including call
        centres and offices (collectively referred herein as &quot;
        <b>Platform</b>&quot;).
      </>,
      <>
        In this notice we have provided detailed information about how and when
        we use cookies. If your browser is configured to accept cookies, we may
        collect non-personally identifiable information passively using
        “cookies” and “page tags”.
      </>,
      <>
        In this notice we have provided detailed information about how and when
        we use cookies. If your browser is configured to accept cookies, we may
        collect non-personally identifiable information passively using
        “cookies” and “page tags”.
      </>,
    ],
    sections: [
      {
        id: 1,
        title: "What's a cookie and page tags?",
        paragraphs: [
          <>
            &quot;Cookies&quot; are small text files that can be placed on your
            computer or mobile device in order to identify your web browser and
            the activities of your computer on the Bataiyo Service and other
            websites. Cookies are then sent back to the originating website on
            each subsequent visit, or to another website that recognizes that
            cookie. It allows us to recognize your device and store some
            information about your preferences or past actions. Cookies can make
            your next visit easier and the site more useful to you. Cookies
            cannot access any other information on your computer. Bataiyo
            advertises on third-party websites. As part of our effort to track
            the success of our advertising campaigns, we may at times use a
            visitor identification technology such as “web beacons/page tag”, or
            “action tags”, which count visitors who have come to the website
            after being exposed to an Bataiyo banner ad on a third-party site.
            We do not use this technology to access your personal information
            and it is only used to compile aggregated statistics about visitors
            who come to the website to gauge the effectiveness of our ads. They
            are a web technology used to help track website or email usage
            information, such as how many times a specific page or email has
            been viewed. Page tags are invisible to you, and any portion of the
            our Services, including advertisements, or email sent on our behalf,
            may contain page tags.
          </>,
        ],
      },
      {
        id: 2,
        title: "Does Bataiyo use cookies, why and how ?",
        paragraphs: [
          <>
            Yes. We use cookies to ensure that any person who uses our sales
            channels gets the best possible experience. We use cookies to
            personalize your experience on the Bataiyo websites, it changes the
            way the site behaves or looks, such as your preferred language or
            the region you are in (such as dynamically generating content on
            webpages specifically designed for you), for security purposes that
            allows us to block many types of attacks, to assist you in using the
            Bataiyo Service (such as saving time by not having to reenter your
            name each time you use the Bataiyo Service), to understand how
            people use our products/services so that we can improve them, to
            allow us to statistically monitor how you are using the Bataiyo
            Service so that we can improve our offerings, and to target certain
            advertisements to your browser which may be of interest to you or to
            determine the popularity of certain content, for sending
            newsletters, seeking your opinion and feedback and providing you
            services and promotional materials. By using cookies and page tags
            together, we can improve the Bataiyo Service and measure the
            effectiveness of our advertising and marketing campaigns. Please be
            aware this cookie policy does not govern the use of third-party
            websites or services or providers of third-party services.
          </>,
        ],
      },
      {
        id: 3,
        title: "Do I have to accept them?",
        paragraphs: [
          <>
            You do not have to accept cookies to use the Bataiyo Service. If you
            reject cookies, certain features or resources of the Bataiyo
            websites may not work properly or at all and you may have a degraded
            experience. Although most browsers are initially /set to accept
            cookies, you can change your browser settings to notify you when you
            receive a cookie or to reject cookies generally. If we detect that
            you have enabled the do not track setting in your browser, we will
            automatically disable advertising/ targeting cookies. If you have
            disabled one or more cookies, we may still use information collected
            from cookies prior to your disabled preference being set; however,
            we will stop using the disabled cookie to collect any further
            information. To learn more about how to control privacy settings and
            cookie management, click the link for your browser [●]. To learn
            more about cookies; how to control, disable or delete them, please
            visit [●] or write to [●]. We respect your legal rights in relation
            to your data. For details on the rights available to you, please
            refer our [●]. In case you have any questions, comments or concerns
            about this Policy or wish to exercise any of the rights available to
            you, please contact [●].
          </>,
        ],
      },
    ],
  },
];

export const blogsListData = [
  {
    id: 1,
    title:
      "Bridging the Digital Divide: Bataiyo’s Pre-Registration Initiative Launching Nationwide on August 15th",
    tags: ["Innovation"],
    link: "https://medium.com/@dhanik_87474/bridging-the-digital-divide-bataiyos-pre-registration-initiative-launching-nationwide-on-august-b4111424f20a",
    authorName: "Dhanik Koul",
    // authorImage: BlogAuthorIcon,
    coverImage: DigitalDivideBlogCoverImg,
    publishDate: "August 5, 2024",
    pin: "no",
    description: [
      <>
        <p className="blogParaStyle">
          In an increasingly digital world, the ability to navigate and utilise
          digital tools has become a critical skill set. Recognizing the
          significant gap that exists, particularly in underserved populations,
          Bataiyo is set to launch a groundbreaking pre-registration initiative
          on this Independence Day August 15th across India. This initiative is
          a crucial step toward identifying and empowering individuals who are
          digitally restrained, providing them with the much-needed push to
          embark on their growth journey.
        </p>
        <h3 className="blogHeadingStyles">Understanding the Digital Divide</h3>
        <p className="blogParaStyle">
          The digital divide refers to the gap between individuals who have easy
          access to digital and information technology and those who do not.
          This divide can stem from a lack of infrastructure, affordability
          issues, or a lack of digital literacy. In India, despite significant
          advancements in technology and connectivity, a substantial portion of
          the population still finds itself on the wrong side of this divide.
          This results in missed opportunities in education, employment, and
          access to essential services.
        </p>
        <h3 className="blogHeadingStyles">The Vision of Bataiyo</h3>
        <p className="blogParaStyle">
          Bataiyo’s mission is to bridge this digital gap and ensure that
          everyone, regardless of their background, has the opportunity to
          thrive in the digital age. By launching the pre-registration
          initiative, Bataiyo aims to identify individuals who need assistance
          the most and provide them with tailored support to boost their digital
          literacy and skills.
        </p>
        <h3 className="blogHeadingStyles">
          Aligning with Atmanirbhar Bharat and Digital India
        </h3>
        <p className="blogParaStyle">
          The pre-registration initiative aligns seamlessly with the broader
          goals of Atmanirbhar Bharat (Self-Reliant India) and Digital India.
          Atmanirbhar Bharat is an initiative aimed at making India self-reliant
          by bolstering local industries, innovation, and infrastructure.
          Digital India seeks to transform the nation into a digitally empowered
          society and knowledge economy. Bataiyo’s efforts contribute to these
          national missions by ensuring that even the most digitally restrained
          individuals can participate in and benefit from the country’s digital
          transformation.
        </p>
        <p className="blogParaStyle">
          By providing digital literacy and skill development, Bataiyo empowers
          individuals to take charge of their own futures, fostering
          self-reliance and enabling them to contribute meaningfully to the
          economy. This initiative not only bridges the digital divide but also
          supports the vision of a self-reliant and digitally inclusive India.
        </p>
        <h3 className="blogHeadingStyles">Impact and Future Goals</h3>
        <p className="blogParaStyle">
          The pre-registration initiative is just the first step in Bataiyo’s
          larger vision to create a digitally inclusive society. By empowering
          individuals with the skills and confidence to navigate the digital
          world, Bataiyo aims to create a ripple effect that will benefit
          families, communities, and the nation as a whole.{" "}
        </p>
        <h3 className="blogHeadingStyles">Conclusion</h3>
        <p className="blogParaStyle">
          Bataiyo’s pre-registration initiative, launching on August 15th, marks
          a significant milestone in the journey toward digital inclusion in
          India. By identifying and supporting the digitally restrained
          population, Bataiyo is not only addressing an immediate need but also
          laying the foundation for a more equitable and prosperous future. As
          the initiative rolls out nationwide, it promises to transform lives,
          one digital skill at a time.
        </p>
      </>,
    ],
  },
  {
    id: 2,
    title:
      "Supporting Local Businesses Through Recommendations: The Power of Community",
    tags: ["Social Impact"],
    link: "https://sahil1998.livejournal.com/265.html?newpost=1",
    authorName: "Dhruv Kabra",
    // authorImage: BlogAuthorIcon,
    coverImage: SupportingBusinessBlogCoverImg,
    publishDate: "July 12, 2024",
    pin: "no",
    description: [
      <>
        <p className="blogParaStyle">
          Local businesses are the backbone of our communities, offering unique
          products and personalised services. Supporting them through
          recommendations can significantly boost their visibility, trust, and
          customer loyalty.
        </p>
        <h3 className="blogHeadingStyles">
          The Importance of Local Businesses
        </h3>
        <p className="blogParaStyle">
          Local businesses create jobs, stimulate economies, and reinvest in
          their communities. They face challenges like competition from large
          corporations and limited marketing budgets, making community support
          vital.
        </p>
        <h3 className="blogHeadingStyles">
          The Power of Personal Recommendations
        </h3>
        <p className="blogParaStyle">
          Recommendations from friends and family are trusted more than
          advertisements. They can increase visibility, build trust, encourage
          loyalty, and foster a sense of community.
        </p>
        <h3 className="blogHeadingStyles">
          How Bataiyo Facilitates Local Business Support
        </h3>
        <p className="blogParaStyle">
          Bataiyo harnesses personal recommendations to support local
          businesses. It connects users with service providers recommended by
          their trusted network, ensuring reliability and trust. Key features
          include:
        </p>
        <h3 className="blogHeadingStyles">Network-Based Recommendations:</h3>
        <p className="blogParaStyle">
          Trusted recommendations from friends and family.
        </p>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            <b>Easy Access:</b> Discover local businesses with just a tap.
          </li>
          <li className="blogListStyles">
            <b>No Ads or Commissions:</b> Focus on genuine recommendations.
          </li>
          <li className="blogListStyles">
            <b>Legacy Feature:</b> Providers can refer others, ensuring
            continuity.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">Embracong Atma Nirbhar Bharat</h3>
        <p className="blogParaStyle">
          Supporting local businesses aligns with Atma Nirbhar Bharat. Choosing
          locally strengthens the nation’s economic resilience.
        </p>
        <h3 className="blogHeadingStyles">Conclusion</h3>
        <p className="blogParaStyle">
          Supporting local businesses through recommendations strengthens
          communities and economies. Platforms like Bataiyo make it easier to
          connect with and support local businesses, fostering trust and mutual
          support. Let’s embrace Atma Nirbhar Bharat by championing our local
          businesses and ensuring they thrive.
        </p>
      </>,
    ],
  },
  {
    id: 3,
    title:
      "How Trustworthy Are Modern Day Reviews on Google, MakeMyTrip, and Other Platforms? Why Friends’ Recommendations Still Reign Supreme",
    tags: ["Trust and Credibility"],
    link: "https://medium.com/@dhanik_87474/-db17dd256183",
    authorName: "Dhruv Kabra",
    // authorImage: BlogAuthorIcon,
    coverImage: ModernDayBlogCoverImg,
    publishDate: "July 4, 2024",
    pin: "no",
    description: [
      <>
        <p className="blogParaStyle">
          In today’s digital world, online reviews have become our go-to for
          deciding where to eat, stay, or visit. Platforms like Google,
          MakeMyTrip, and TripAdvisor are packed with millions of reviews that
          help us make choices. But are these
        </p>
        <p className="blogParaStyle">
          reviews really as trustworthy as they seem? Despite the convenience of
          online reviews, recommendations from friends and family still stand
          out as the most reliable.
        </p>
        <h3 className="blogHeadingStyles">The Problems with Online Reviews.</h3>
        <h3 className="blogHeadingStyles">1. Are They Real?</h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            <b>Fake Reviews:</b> One of the biggest issues is fake reviews.
            Businesses might post fake positive reviews to boost their ratings
            or negative ones to hurt their competitors. Even with platforms
            trying to stop this, fake reviews still get through.
          </li>
          <li className="blogListStyles">
            <b>Bias and Freebies:</b> Some reviews are biased because the
            reviewer got a discount or freebie. Even honest reviews can be
            swayed by personal biases or expectations that might not match
            yours.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">2. Too Much to Handle</h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            <b>Overload:</b> With hundreds or thousands of reviews, it can be
            overwhelming to figure out which ones to trust.
          </li>
          <li className="blogListStyles">
            <b>Mixed Opinions:</b> Everyone has different tastes. What one
            person loves, another might hate, making it confusing to decide.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">3. Anonymity and Unaccountable</h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            <b>Anonymous Reviews:</b> People can post reviews without revealing
            who they are, which sometimes leads to exaggerated or dishonest
            feedback.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">
          Why Friends’ Recommendations Are the Best
        </h3>
        <h3 className="blogHeadingStyles">1. Trust and Reliability</h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            <b>Known Sources:</b> You know and trust your friends and family.
            You understand their likes and dislikes, making their advice more
            relatable.
          </li>
          <li className="blogListStyles">
            <b>Tailored Advice:</b> Friends and family can give you advice that
            fits your specific needs and tastes, something generic online
            reviews can’t do.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">2. Accountability</h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            <b>Direct Feedback:</b> When a friend recommends something, they
            stand by it. They won’t mislead you because they value your trust.
          </li>
          <li className="blogListStyles">
            <b>Context Matters:</b> Friends know your situation — whether you’re
            on a budget or looking for luxury — and can tailor their
            recommendations accordingly.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">3. Genuine Experiences</h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            <b>Unbiased Advice:</b> Unlike some online reviews, friends have no
            reason to lie or exaggerate. Their recommendations are based on real
            experiences.
          </li>
          <li className="blogListStyles">
            <b>Detailed Stories:</b> Friends can share personal stories and
            details that give you a clearer picture than a short online review.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">Conclusion</h3>
        <p className="blogParaStyle">
          While online reviews on platforms like Google and MakeMyTrip can be
          helpful, they often suffer from issues such as fake reviews, bias,
          information overload, and anonymity, which can undermine their
          reliability. In contrast, recommendations from friends and family are
          more personalised, reliable, and genuinely motivated. Although
          combining online reviews and personal recommendations can provide a
          well-rounded view, relying on trusted friends and family for advice
          usually results in better and more trustworthy decisions.
        </p>
      </>,
    ],
  },
  {
    id: 4,
    title:
      "🌟 Discovering Chandigarh: A Modern Day of Exploration and Culinary Delights 🌟",
    tags: ["Travel"],
    link: "https://medium.com/@dhanik_87474/-a7a770a42ed2",
    authorName: "Dhruv Kabra",
    // authorImage: BlogAuthorIcon,
    coverImage: DiscoveringBlogCoverImg,
    publishDate: "June 23, 2024",
    pin: "no",
    description: [
      <>
        <p className="blogParaStyle">
          Prashant recently had the pleasure of visiting Chandigarh while his
          sister, Supriya, attended her Judiciary exams from 10 am to 1 pm. With
          three hours to spare, he decided to explore this vibrant city and
          indulge in its renowned culinary offerings.
        </p>
        <p className="blogParaStyle">
          The day was perfect — the weather in Chandigarh was pleasantly cool,
          enhancing the charm of his adventure. Armed with recommendations from
          his friend Shashank Singhal, Prashant set out to discover Chandigarh’s
          hidden treasures.
        </p>
        <h3 className="blogHeadingStyles">
          Sector 17 Plaza: A Hub of Activity:
        </h3>
        <p className="blogParaStyle">
          His first stop was Sector 17 Plaza, a bustling commercial center where
          modern architecture meets lively markets. The vibrant atmosphere was
          invigorating, offering a glimpse into the city’s dynamic lifestyle.
          The plaza is known for its shopping arcades, fountains, and open
          spaces, making it a perfect place to start his exploration.
        </p>
        <h3 className="blogHeadingStyles">Sukhna Lake: A Serene Oasis:</h3>
        <p className="blogParaStyle">
          Next, he visited Sukhna Lake, a serene oasis at the foothills of the
          Himalayas. The tranquil waters and scenic surroundings provided a
          perfect setting for a peaceful stroll, away from the hustle and
          bustle. The lake is a favorite spot for locals and tourists alike,
          offering activities such as boating and a beautiful promenade for
          walking or cycling.
        </p>
        <h3 className="blogHeadingStyles">
          Culinary Delights: Chole Bhature and Chaat:
        </h3>
        <p className="blogParaStyle">
          Chandigarh is famed for its street food, and Prashant wasn’t going to
          miss out. Following Shashank’s tips, he savored the iconic Chole
          Bhature at Sindh Dhaba and indulged in mouthwatering chaat at Garg
          Chaat Corner. Each bite was a testament to the city’s rich culinary
          heritage. The Chole Bhature was a flavorful delight, with perfectly
          spiced chickpeas and fluffy bhature, while the chaat was an explosion
          of tangy, spicy, and sweet flavors.
        </p>
        <h3 className="blogHeadingStyles">
          Architectural Marvels: Capitol Complex and Rock Garden:
        </h3>
        <p className="blogParaStyle">
          As he explored further, the unique blend of modernity and greenery in
          Chandigarh stood out. The Capitol Complex and Rock Garden, with their
          architectural splendor and well-planned layout, were awe-inspiring.
          The Capitol Complex, designed by Le Corbusier, is a UNESCO World
          Heritage site, showcasing stunning modernist architecture. The Rock
          Garden, created by Nek Chand, is a whimsical landscape of sculptures
          made from industrial and urban waste, offering a fascinating and
          creative escape.
        </p>
        <h3 className="blogHeadingStyles">A Memorable Visit:</h3>
        <p className="blogParaStyle">
          Thanks to Shashank’s invaluable insights, Prashant’s brief visit to
          Chandigarh was incredibly memorable. The city’s warmth and hospitality
          left a lasting impression on him. He looks forward to returning soon
          to uncover more of its charms.
        </p>
        <p className="blogParaStyle">
          Prashant can log all the service providers on the Bataiyo application
          as his experience, so that next time anyone in his network visits
          Chandigarh, they can log in to Bataiyo and utilise his
          recommendations.
        </p>
      </>,
    ],
  },
  {
    id: 5,
    title: "Moving to a New City: Navigating Challenges and Finding Solutions",
    tags: ["City Living"],
    link: "https://medium.com/@dhanik_87474/moving-to-a-new-city-navigating-challenges-and-finding-solutions-b7ecfe077226",
    authorName: "Dhruv Kabra",
    // authorImage: BlogAuthorIcon,
    coverImage: MovingToCityBlogCoverImg,
    publishDate: "June 20, 2024",
    pin: "no",
    description: [
      <>
        <p className="blogParaStyle">
          Moving to a new city can be exciting and challenging. When I moved
          from Agra, Uttar Pradesh, to Chennai for a new job five or six years
          ago, I faced several unexpected obstacles. Here are the challenges I
          encountered and the solutions I discovered, which might help anyone
          else making a similar move.
        </p>
        <h3 className="blogHeadingStyles">
          Key Challenges When Moving to a New City
        </h3>
        <h3 className="blogHeadingStyles">
          1. Finding Trustworthy Household Help
        </h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            In smaller towns, reliable maids and cooks are often easily found
            through local recommendations.
          </li>
          <li className="blogListStyles">
            In larger cities, language barriers can make communication
            difficult.
          </li>
          <li className="blogListStyles">
            Initial hires through online platforms can be unreliable, with many
            not showing up or meeting expectations.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">
          2. Searching for Reliable Service Vendors
        </h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            Locating dependable plumbers, electricians, and carpenters can be
            challenging.
          </li>
          <li className="blogListStyles">
            Multiple searches and calls often lead to poor service quality or
            unavailability.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">
          3. Adapting to a New Culture and Lifestyle
        </h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            Adjusting to local customs and daily routines in a new city can be
            complex.
          </li>
          <li className="blogListStyles">
            Missing familiar regional cuisine adds to the discomfort.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">
          4. Exploring the City and Finding Local Favourites
        </h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            The vastness and diversity of a large city can make it difficult to
            know where to start exploring.
          </li>
          <li className="blogListStyles">
            Traffic and crowded public places can be initially overwhelming.
          </li>
          <li className="blogListStyles">
            Identifying local hangouts, eateries, and cultural spots without
            guidance can be challenging.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">The Turning Point</h3>
        <p className="blogParaStyle">
          Traditional advice like “Agar pata hai to tu mujhe bataiyo!” (If you
          know, let me know) can lead to valuable connections. Reaching out to
          friends who have previously lived in the city can be a game-changer.
          For instance, a friend named Venki, who had lived in Chennai, offered
          invaluable assistance, saying, “Tu tension mat le mai batata hu”
          (Don’t worry, I’ll tell you).
        </p>
        <h3 className="blogHeadingStyles">
          Discovering Chennai Through Friends’ Recommendations
        </h3>
        <ul className="blogUlListStyles">
          <li className="blogListStyles">
            A list of must-visit places, including cafes, restaurants, and
            cultural spots, can make exploring a new city enjoyable.
          </li>
          <li className="blogListStyles">
            Exploring areas like Besant Nagar for its vibrant beach scene and
            eateries, and T. Nagar for its lively street shopping, can enhance
            the experience.
          </li>
          <li className="blogListStyles">
            Visits to landmarks such as Marina Beach, Kapaleeshwarar Temple, and
            Guindy National Park offer appreciation for Chennai’s rich history
            and natural beauty.
          </li>
          <li className="blogListStyles">
            Weekend getaways to places like Mahabalipuram and Pondicherry
            provide refreshing breaks from the city hustle.
          </li>
        </ul>
        <h3 className="blogHeadingStyles">Introducing Bataiyo!</h3>
        <p className="blogParaStyle">
          Now, here comes Bataiyo! It helps users explore all the local or
          hyper-local services needed in a new city—from household help to any
          local vendor. If your connections have recommended a service provider
          in the city, Bataiyo lets you find and connect with them. The
          application helps users recommend a service provider, and these
          providers can then be connected with other users in their circle.
        </p>
      </>,
    ],
  },
];

export const successSocialIcons = [
  {
    id: "1",
    name: "instagram",
    icon: InstagramImg,
    path: "https://www.instagram.com/bataiyo.official/",
  },
  {
    id: "2",
    name: "linkedin",
    icon: LinkedInImg,
    path: "https://www.linkedin.com/company/bataiyo/",
  },
  {
    id: "3",
    name: "youtube",
    icon: YouTubeImg,
    path: "https://www.youtube.com/channel/UCh2YhhO5bdamxyC1WSBfcXQ",
  },
  {
    id: "4",
    name: "x",
    icon: XImg,
    path: "https://x.com/bataiyoofficial?t=s9FWulgAI-X1FujT5xYscQ&s=09",
  },
  {
    id: "5",
    name: "facebook",
    icon: FacebookImg,
    path: "https://www.facebook.com/profile.php?id=61560529440864&mibextid=ZbWKwL",
  },
];
export const blogsSEOData = [
  {
    id: 1,
    title:
      "Bridging the Digital Divide: Bataiyo’s Pre-Registration Initiative Launching Nationwide on August 15th",
    tags: ["Innovation"],
    description:
      "Explore how Bataiyo is working to bridge the digital divide with its nationwide pre-registration initiative launching on August 15th.",
    keywords:
      "Digital Divide, Bataiyo, Pre-Registration, Nationwide Launch, Innovation",
  },
  {
    id: 2,
    title:
      "Supporting Local Businesses Through Recommendations: The Power of Community",
    tags: ["Social Impact"],
    description:
      "Learn how community-driven recommendations are supporting local businesses and fostering a stronger community.",
    keywords: "Local Businesses, Community, Recommendations, Social Impact",
  },
  {
    id: 3,
    title:
      "How Trustworthy Are Modern Day Reviews on Google, MakeMyTrip, and Other Platforms? Why Friends’ Recommendations Still Reign Supreme",
    tags: ["Trust and Credibility"],
    description:
      "Dive into the reliability of online reviews on platforms like Google and MakeMyTrip, and why personal recommendations still hold value.",
    keywords: "Online Reviews, Trust, Credibility, Google, MakeMyTrip",
  },
  {
    id: 4,
    title:
      "Discovering Chandigarh: A Modern Day of Exploration and Culinary Delights",
    tags: ["Travel"],
    description:
      "Join us on a journey through Chandigarh, exploring its modern attractions and savoring its culinary delights.",
    keywords: "Chandigarh, Travel, Exploration, Culinary Delights",
  },
  {
    id: 5,
    title: "Moving to a New City: Navigating Challenges and Finding Solutions",
    tags: ["City Living"],
    description:
      "Moving to a new city? Discover practical solutions to common challenges and make your transition smoother.",
    keywords: "Moving, New City, Challenges, Solutions, City Living",
  },
];
export const blogSEOKeywords = [
  "Service Industry Trends",
  "Tips for Hiring Service Providers",
  "Home Services Advice",
  "How to Choose Service Professionals",
  "Benefits of Zero-Commission Platforms",
  "Service Booking Tips",
  "Updates in Service Marketplace",
  "Professional Service Reviews",
  "Customer Experience Tips",
  "Guide to Service Providers",
  "Latest Service Industry News",
  "How to Book Services Online",
  "Service Provider Success Stories",
  "Managing Service Providers",
  "Insights into Service Marketplace",
  "Best Practices for Service Booking",
  "Trends in Professional Services",
  "How to Find the Right Service Provider",
  "Service Marketplace Innovations",
  "Tips for Efficient Service Booking",
  "Understanding Service Provider Platforms",
  "How to Improve Service Booking Experience",
  "Customer Reviews on Service Providers",
  "Future of Service Industry",
  "Expert Advice on Service Booking",
  "Navigating Service Provider Platforms",
  "Service Provider Industry Insights",
  "How to Maximize Service Booking",
  "Benefits of Using Service Platforms",
  "Common Mistakes in Service Booking",
  "Innovations in Service Platforms",
  "Customer Service Best Practices",
  "How to Choose the Best Service Provider",
  "Effective Service Management Tips",
  "Service Platform User Experiences",
  "Guide to Better Service Booking",
  "Service Provider Market Analysis",
  "Service Booking Challenges and Solutions",
  "Latest Trends in Home Services",
  "Best Service Provider Platforms",
];
export const servicesSEOKeywords = [
  "Home Cleaning Service",
  "Plumbing Services",
  "Electrical Repair Service",
  "Pest Control Experts",
  "Carpentry Service",
  "Air Conditioning Repair",
  "Gardening Service",
  "Painting and Decorating",
  "Home Renovation Service",
  "Fitness Trainer",
  "Beauty Service",
  "Tutoring Service",
  "Medical Service",
  "Event Planning Service",
  "IT Support Service",
  "Appliance Repair Service",
  "Moving and Packing Service",
  "Laundry Service",
  "Dry Cleaning Service",
  "Childcare Service",
  "Vehicle Repair Service",
  "Handyman Service",
  "Home Maintenance Service",
  "Professional Cleaning Service",
  "Home Improvement Service",
  "Renovation Experts",
  "Security Service",
  "Catering Service",
  "Local Service Providers",
  "Specialized Service Providers",
  "Expert Service Providers",
  "Affordable Professional Service",
  "Local Home Service",
  "Service Provider Categories",
  "Professional Home Repair Service",
  "On-Demand Service Providers",
  "Reliable Local Service",
  "Book Service Professionals Online",
  "Top Service Providers",
  "Comprehensive Service Options",
  "Daily Needs",
  "Domestic Services",
  "Retail & Shopping",
  "Travel",
  "Professional Services",
  "Maintenance & Repairs",
  "Food & Beverage",
  "Beauty & Fashion",
  "Settling In",
  "Health & Wellness",
  "Social Cause",
  "Marriage",
  "Other Services",
  "On Rentals",
  "Accountant",
  "Acupuncturist",
  "Adventure Activities",
  "Animal Rescue",
  "Appliance Repair",
  "Architect",
  "Architecture Academy",
  "Art Academy",
  "Art Teacher",
  "Artisan",
  "Artist",
  "Astrologer",
  "Astronomy Academy",
  "Atta Chakki Maker",
  "Auto Rickshaw Driver",
  "Automotive Services And Accessories",
  "Babysitter",
  "Backpacker Hostels",
  "Bakery Classes",
  "Bakery/Cake Shop",
  "Balloon Decorator",
  "Barber",
  "Bathroom Cleaner",
  "Beautician",
  "Bike Rentals",
  "Bike Repair",
  "Bike Taxi Driver",
  "Birthday Planner",
  "Blood Donation Camp",
  "Blood Test Lab",
  "Business/Entrepreneurship Academy",
  "Cab/Taxi",
  "Cafe",
  "Camping",
  "Car Rentals",
  "Car Repair",
  "Car Washer",
  "Car/Bike Dealer",
  "Carpenter",
  "Catering Service",
  "Chartered Accountant",
  "Chiropractor",
  "Cleaning Services",
  "Coding/Programming Academy",
  "Computer Rentals",
  "Content Creator",
  "Cooking Academy",
  "Cooking Classes",
  "Culinary Arts Academy",
  "Culinary Souvenirs",
  "Cycle Rentals",
  "Daily Wage Worker",
  "Dairy Shop",
  "Dance Academy",
  "Dance Teacher",
  "Dermatologist",
  "Dietitian",
  "Digital Marketing Consultant",
  "Disposable Distributor",
  "Donation Camp",
  "Driver/Chauffeur",
  "Drycleaner",
  "Elder Care",
  "Electrician",
  "Engineering Academy",
  "Engineering Teacher",
  "Environmental Program",
  "Environmental Studies Academy",
  "Etiquette Academy",
  "Event Manager",
  "Event Planner",
  "Fashion Design Academy",
  "Fashion Designer",
  "Film/Acting Academy",
  "Firecracker Factory",
  "Food Raw Material Distributor",
  "Fruit Stall",
  "Game Center",
  "Gardener",
  "Gas Refill Shop",
  "General Store",
  "Gents Salon",
  "Glass And Aluminium Shop",
  "Graphic Design Academy",
  "Graphic Designer",
  "Grocery And Confectionery",
  "Gym Trainer",
  "Hairdresser",
  "Handyman",
  "Hardware And Repair Shop",
  "Health And Wellness Camp",
  "Herbalist",
  "High School Teacher",
  "Home Appliance Shop",
  "Home Caretaker",
  "Home Organizer",
  "Home Stay",
  "Homemade Food",
  "Ice Cream Parlour",
  "Insurance Broker",
  "Interior Decorator",
  "Interior Design Academy",
  "IT Hardware",
  "Key Maker",
  "Ladies Parlour",
  "Language Academy",
  "Language Tutor",
  "Laser Hair Removal Specialist",
  "Laundry",
  "Lawyer",
  "Leadership Academy",
  "Legal Consultant",
  "Lighting Specialist",
  "Maid/Servant",
  "Make Up Artist",
  "Marriage Planner",
  "Martial Arts Academy",
  "Massage Therapist",
  "Mathematics Academy",
  "Mattress And Carpet Shop",
  "Meat Shop",
  "Media Publications",
  "Medical Pharmacy",
  "Medical Teacher",
  "Merchandise Vendor",
  "Milk Man",
  "Mistri/Mason",
  "Movers And Packers",
  "Music Academy",
  "Music Band",
  "Music Production Academy",
  "Music Teacher",
  "Mutual Fund Broker",
  "Nail Technician",
  "Notary Shop",
  "Occupational Therapist",
  "Optical Eye Shop",
  "Paan Shop",
  "Painter",
  "Painting Academy",
  "Pandit/Pujari",
  "Party Hall",
  "Paying Guest",
  "Personal Bodyguard",
  "Pet Adoption Care",
  "Pet Boarding",
  "Pet Care",
  "Pet Trainer",
  "Photographer/Videographer",
  "Photography Academy",
  "Physiotherapist",
  "Playschool",
  "Pre Nursery Teacher",
  "Primary Teacher",
  "Private Doctor",
  "Psychiatrist",
  "Public Speaking/Debate Academy",
  "Puja Samagri",
  "Real Estate Broker",
  "Restaurant",
  "Robotics Academy",
  "Roofer And Shed Maker",
  "Science Academy",
  "Scraper",
  "Security Guard",
  "Security System Installer",
  "SEO Specialist",
  "Shoe Cobbler And Repair",
  "Skincare Specialist",
  "Social Awareness Campaign",
  "Solar Panel Installer",
  "Speech Therapist",
  "Sports Academy",
  "Sports Arena",
  "Sports Trainer",
  "Stand Up Artist",
  "Stationary",
  "Tailor",
  "Tattoo Artist",
  "Tattoo Removal Specialist",
  "Tax Consultant",
  "Tea Stall",
  "Tenant",
  "Theater/Drama Academy",
  "Tours And Travels",
  "Traditional Clothing",
  "Traditional Craftsmen",
  "Travel Agent",
  "Unisex Salon",
  "Vegetable Seller",
  "Vehicle Mechanic",
  "Voice Coach",
  "Watch Shop",
  "Watchman",
  "Water Vendor",
  "Waxing Specialist",
  "Writing/Creative Writing Academy",
  "Yoga Trainer",
  "Yoga/Meditation Academy",
];
export const modifiersSEOKeywords = ["Best"];
