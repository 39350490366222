import { Grid, Paper } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { splitServiceNames } from "../../constant/utils";
import { styles } from "./styleSheet";
import { DefaultServicesIcon } from "../../constant/ImagesS3";

const ServiceCard = ({ service, isSmallScreen }) => (
  <Grid item xs={12} sm={6} md={4} lg={3} key={service?.serviceId}>
    <Paper elevation={3} sx={styles.servicesCardPaper(isSmallScreen)}>
      <div style={styles.serviceNameBox}>
        <img
          src={service?.serviceIcon || DefaultServicesIcon}
          alt={service?.serviceName}
          style={styles.servicesIcon(isSmallScreen)}
          onError={(e) => {
            e.target.src = DefaultServicesIcon;
          }}
        />
        <div style={styles.serviceNameText(isSmallScreen)}>
          {splitServiceNames(service?.serviceName)}
        </div>
      </div>
    </Paper>
  </Grid>
);

ServiceCard.propTypes = {
  service: PropTypes.shape({
    serviceId: PropTypes.string.isRequired,
    serviceIcon: PropTypes.string,
    serviceName: PropTypes.string,
  }).isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export default ServiceCard;
