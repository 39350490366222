export const servicesStaticData = [
  {
    categoryId: "66976bbe45f995d961598e71",
    categoryName: "daily needs",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/dailyneeds.svg",
    subcategories: [
      {
        subcategoryId: "66976bbf45f995d961598e74",
        subcategoryName: "beauty & fashion",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/beautyfashion.svg",
        services: [
          {
            serviceId: "66976bbf45f995d961598e77",
            serviceName: "barber",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/barber.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/barberserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc045f995d961598e7e",
            serviceName: "gents salon",
            serviceSortOrder: 2,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gentssalon.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gentssalonserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc145f995d961598e85",
            serviceName: "hairdresser",
            serviceSortOrder: 3,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hairdresser.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hairdresserserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc245f995d961598e8c",
            serviceName: "ladies parlour",
            serviceSortOrder: 4,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesparlour.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesparlourserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc345f995d961598e93",
            serviceName: "make up artist",
            serviceSortOrder: 5,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc445f995d961598e9a",
            serviceName: "mehndi designer",
            serviceSortOrder: 6,
            isActive: true,
            description: "Festival or Marriage Celebration.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mehndidesigner.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mehndidesignerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bc545f995d961598ea1",
            serviceName: "nail technician",
            serviceSortOrder: 7,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/nailtechnician.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/nailtechnicianserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc645f995d961598ea8",
            serviceName: "skincare specialist",
            serviceSortOrder: 8,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/skincarespecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/skincarespecialistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc745f995d961598eaf",
            serviceName: "unisex salon",
            serviceSortOrder: 9,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/unisexsalon.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/unisexsalonserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc845f995d961598eb6",
            serviceName: "waxing specialist",
            serviceSortOrder: 10,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/waxingspecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/waxingspecialistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bc945f995d961598ebc",
        subcategoryName: "care",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/care.svg",
        services: [
          {
            serviceId: "66976bca45f995d961598ebf",
            serviceName: "babysitter",
            serviceSortOrder: 11,
            isActive: true,
            description: "Caring for your little ones with love.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/babysitter.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/babysitterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bcb45f995d961598ec6",
            serviceName: "elder care",
            serviceSortOrder: 12,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/eldercare.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/eldercareserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bcc45f995d961598ecc",
        subcategoryName: "domestic services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/domesticservices.svg",
        services: [
          {
            serviceId: "66976bcc45f995d961598ecf",
            serviceName: "bathroom cleaner",
            serviceSortOrder: 13,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bathroomcleaner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bathroomcleanerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcd45f995d961598ed6",
            serviceName: "car washer",
            serviceSortOrder: 14,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/carwasher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carwasherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bce45f995d961598edd",
            serviceName: "cleaning services",
            serviceSortOrder: 15,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cleaningservices.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cleaningservicesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcf45f995d961598ee4",
            serviceName: "cook",
            serviceSortOrder: 16,
            isActive: true,
            description: "Home made food. Just a click away!",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cook.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cookserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd045f995d961598eeb",
            serviceName: "drycleaner",
            serviceSortOrder: 17,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/drycleaner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/drycleanerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd145f995d961598ef2",
            serviceName: "gardener",
            serviceSortOrder: 18,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/gardener.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gardenerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3b05c2bcf732eb2ecfe8",
            serviceName: "ironing",
            serviceSortOrder: 23,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/ironing.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ironingserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd345f995d961598f00",
            serviceName: "laundry",
            serviceSortOrder: 20,
            isActive: true,
            description: "Fresh clothes, fresher feelings.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/laundry.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/laundryserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd245f995d961598ef9",
            serviceName: "maid/servant",
            serviceSortOrder: 19,
            isActive: true,
            description: "A clean home, a happy heart.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/housemaid.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/housemaidserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd545f995d961598f07",
            serviceName: "milk man",
            serviceSortOrder: 21,
            isActive: true,
            description: "Farm-fresh milk for a healthier you.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/milkman2.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/milkmanserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd645f995d961598f0e",
            serviceName: "tailor",
            serviceSortOrder: 22,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/tailor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tailorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd745f995d961598f15",
            serviceName: "water vendor",
            serviceSortOrder: 23,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/watervendor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/watervendorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bd745f995d961598f1b",
        subcategoryName: "food & beverage",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/foodbeverage.svg",
        services: [
          {
            serviceId: "66976bd845f995d961598f1e",
            serviceName: "cafe",
            serviceSortOrder: 24,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cafe.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cafeserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd945f995d961598f25",
            serviceName: "homemade food",
            serviceSortOrder: 25,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadefood.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadefoodserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bda45f995d961598f2c",
            serviceName: "restaurant",
            serviceSortOrder: 26,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/restaurant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/restaurantserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bdb45f995d961598f33",
            serviceName: "street food",
            serviceSortOrder: 27,
            isActive: true,
            description: "Bite into the soul of the city.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/streetfood.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/streetfoodserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bdc45f995d961598f39",
        subcategoryName: "health & wellness",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/healthwellness.svg",
        services: [
          {
            serviceId: "66976bdc45f995d961598f3c",
            serviceName: "medical pharmacy",
            serviceSortOrder: 28,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalpharmacy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalpharmacyserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bdd45f995d961598f42",
        subcategoryName: "maintenance & repairs",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/maintenancerepairs.svg",
        services: [
          {
            serviceId: "66976bde45f995d961598f45",
            serviceName: "ac on rentals",
            serviceSortOrder: 29,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/aconrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/aconrentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bdf45f995d961598f4c",
            serviceName: "appliance repair",
            serviceSortOrder: 30,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/appliancerepairhvac.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/appliancerepairhvacserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be045f995d961598f53",
            serviceName: "automotive services and accessories",
            serviceSortOrder: 31,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/automotiveservicesaccessories.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/automotiveservicesaccessoriesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd9b4415f9f92376e9fdce",
            serviceName: "bike repair",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerepairicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd9be215f9f92376e9fdcf",
            serviceName: "car repair",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carrepairicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be145f995d961598f5a",
            serviceName: "carpenter",
            serviceSortOrder: 32,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/carpenter.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carpenterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be245f995d961598f61",
            serviceName: "electrician",
            serviceSortOrder: 33,
            isActive: true,
            description: "Brightening your home with expert care.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/electrician.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/electricianserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976be345f995d961598f68",
            serviceName: "gas refill shop",
            serviceSortOrder: 34,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be445f995d961598f6f",
            serviceName: "glass and aluminium shop",
            serviceSortOrder: 35,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be545f995d961598f76",
            serviceName: "handyman",
            serviceSortOrder: 36,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/handyman.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/handymanserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be645f995d961598f7d",
            serviceName: "hardware and repair shop",
            serviceSortOrder: 37,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be745f995d961598f84",
            serviceName: "home appliance shop",
            serviceSortOrder: 38,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homeapplianceshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homeapplianceshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be845f995d961598f8b",
            serviceName: "key maker",
            serviceSortOrder: 39,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/keymaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/keymakerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be945f995d961598f92",
            serviceName: "mistri/mason",
            serviceSortOrder: 40,
            isActive: true,
            description: "Building your dreams, brick by brick.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/masonmistri.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/masonmistriserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bea45f995d961598f99",
            serviceName: "mobile shop",
            serviceSortOrder: 41,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mobilerepairshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mobilerepairshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bec45f995d961598fa0",
            serviceName: "painter",
            serviceSortOrder: 42,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/painter.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/painterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bed45f995d961598fa7",
            serviceName: "pest control",
            serviceSortOrder: 43,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pestcontrol.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/pestcontrolserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bee45f995d961598fae",
            serviceName: "plumber",
            serviceSortOrder: 44,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/plumber.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/plumberserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bef45f995d961598fb5",
            serviceName: "roofer and shed maker",
            serviceSortOrder: 45,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/roofershedmaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/roofershedmakerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf045f995d961598fbc",
            serviceName: "shoe cobbler and repair",
            serviceSortOrder: 46,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/shoecobblerrepair.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/shoecobblerrepairserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf145f995d961598fc3",
            serviceName: "tyre puncture shop",
            serviceSortOrder: 47,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tyrepunctureshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tyrepunctureshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf245f995d961598fca",
            serviceName: "vehicle mechanic",
            serviceSortOrder: 48,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vehiclemechanic.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/vehiclemechanicserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf345f995d961598fd1",
            serviceName: "vehicle towing",
            serviceSortOrder: 49,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vehicletowing.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/vehicletowingserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf445f995d961598fd8",
            serviceName: "watch shop",
            serviceSortOrder: 50,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/watchshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/watchshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf545f995d961598fdf",
            serviceName: "welder",
            serviceSortOrder: 51,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/welder.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/welderserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bf645f995d961598fe5",
        subcategoryName: "retail & shopping",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/retailshopping.svg",
        services: [
          {
            serviceId: "66976bf645f995d961598fe8",
            serviceName: "atta chakki maker",
            serviceSortOrder: 52,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/attachakkimaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/attachakkimakerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2c14be0fa4ee39921dfe",
            serviceName: "bakery/cake shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bakeryclasses.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bakerycakeshopicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf745f995d961598fef",
            serviceName: "dairy shop",
            serviceSortOrder: 53,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dairyshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/dairyshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2b67be0fa4ee39921dfb",
            serviceName: "electronic shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/electronicshopicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3ab5c2bcf732eb2ecfe7",
            serviceName: "fruit stall",
            serviceSortOrder: 59,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fruitstall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/fruitstallserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf845f995d961598ff6",
            serviceName: "furniture shop",
            serviceSortOrder: 54,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/furnitureshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/furnitureshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf945f995d961598ffd",
            serviceName: "game center",
            serviceSortOrder: 55,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gamecenter.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gamecenterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2c82be0fa4ee39921dff",
            serviceName: "general store",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/generalstoreicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfa45f995d961599004",
            serviceName: "grocery and confectionery",
            serviceSortOrder: 56,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/groceryconfectionery.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/groceryconfectioneryserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfc45f995d96159900b",
            serviceName: "ice cream parlour",
            serviceSortOrder: 57,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/icecreamparlour.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/icecreamparlourserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfd45f995d961599012",
            serviceName: "jewellery shop",
            serviceSortOrder: 58,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/jewelleryshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/jewelleryshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfe45f995d961599019",
            serviceName: "juice shop",
            serviceSortOrder: 59,
            isActive: true,
            description: "Nature’s flavors, served in a glass.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/juiceshop.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/juiceshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bff45f995d961599020",
            serviceName: "mattress and carpet shop",
            serviceSortOrder: 60,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mattressandcarpet shop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mattressandcarpetshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0045f995d961599027",
            serviceName: "meat shop",
            serviceSortOrder: 61,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/meatshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/meatshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c2c45f995d961599142",
            serviceName: "paan shop",
            serviceSortOrder: 62,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/paanshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/paanshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0245f995d961599035",
            serviceName: "puja samagri",
            serviceSortOrder: 63,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pujasamagri.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/pujasamagriserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0345f995d96159903c",
            serviceName: "sports shop",
            serviceSortOrder: 64,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0445f995d961599043",
            serviceName: "stationary",
            serviceSortOrder: 65,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/stationary.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/stationaryserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0545f995d96159904a",
            serviceName: "tea stall",
            serviceSortOrder: 66,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/teastall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/teastallserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0645f995d961599051",
            serviceName: "toy shop",
            serviceSortOrder: 67,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/toyshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/toyshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0745f995d961599058",
            serviceName: "vegetable seller",
            serviceSortOrder: 68,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vegetableseller.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/vegetablesellerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c0845f995d96159905e",
        subcategoryName: "transportation",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/transportation.svg",
        services: [
          {
            serviceId: "66976c0845f995d961599061",
            serviceName: "auto rickshaw driver",
            serviceSortOrder: 69,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/autorickshawdriver.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/autorickshawdriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0945f995d961599068",
            serviceName: "bike rentals",
            serviceSortOrder: 70,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0a45f995d96159906f",
            serviceName: "bike taxi driver",
            serviceSortOrder: 71,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/biketaxidriver.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/biketaxidriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0f45f995d96159908b",
            serviceName: "cab/taxi",
            serviceSortOrder: 71,
            isActive: true,
            description: "Safe travels, every time.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cabtaxi.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/taxidriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c0c45f995d961599076",
            serviceName: "car rentals",
            serviceSortOrder: 72,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0d45f995d96159907d",
            serviceName: "cycle rentals",
            serviceSortOrder: 73,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cyclerentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cyclerentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0e45f995d961599084",
            serviceName: "driver/chauffeur",
            serviceSortOrder: 74,
            isActive: true,
            description: "Experience the road with confidence.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/driverchauffeur2.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/driverchauffeurserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c1045f995d961599092",
            serviceName: "tours and travels",
            serviceSortOrder: 76,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tourstravels.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tourstravelsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c1145f995d961599099",
            serviceName: "travel agent",
            serviceSortOrder: 77,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/travelagent.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/travelagentserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c1145f995d96159909e",
    categoryName: "domestic services",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/domesticservices.svg",
    subcategories: [
      {
        subcategoryId: "66976bcc45f995d961598ecc",
        subcategoryName: "domestic services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/domesticservices.svg",
        services: [
          {
            serviceId: "66976bcc45f995d961598ecf",
            serviceName: "bathroom cleaner",
            serviceSortOrder: 78,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bathroomcleaner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bathroomcleanerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcd45f995d961598ed6",
            serviceName: "car washer",
            serviceSortOrder: 79,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/carwasher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carwasherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bce45f995d961598edd",
            serviceName: "cleaning services",
            serviceSortOrder: 80,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cleaningservices.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cleaningservicesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcf45f995d961598ee4",
            serviceName: "cook",
            serviceSortOrder: 81,
            isActive: true,
            description: "Home made food. Just a click away!",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cook.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cookserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd045f995d961598eeb",
            serviceName: "drycleaner",
            serviceSortOrder: 82,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/drycleaner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/drycleanerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd145f995d961598ef2",
            serviceName: "gardener",
            serviceSortOrder: 83,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/gardener.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gardenerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3b05c2bcf732eb2ecfe8",
            serviceName: "ironing",
            serviceSortOrder: 88,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/ironing.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ironingserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd345f995d961598f00",
            serviceName: "laundry",
            serviceSortOrder: 85,
            isActive: true,
            description: "Fresh clothes, fresher feelings.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/laundry.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/laundryserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd245f995d961598ef9",
            serviceName: "maid/servant",
            serviceSortOrder: 84,
            isActive: true,
            description: "A clean home, a happy heart.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/housemaid.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/housemaidserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd545f995d961598f07",
            serviceName: "milk man",
            serviceSortOrder: 86,
            isActive: true,
            description: "Farm-fresh milk for a healthier you.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/milkman2.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/milkmanserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd645f995d961598f0e",
            serviceName: "tailor",
            serviceSortOrder: 87,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/tailor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tailorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd745f995d961598f15",
            serviceName: "water vendor",
            serviceSortOrder: 88,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/watervendor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/watervendorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c1b45f995d9615990d7",
    categoryName: "retail & shopping",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/retailshopping.svg",
    subcategories: [
      {
        subcategoryId: "66976bf645f995d961598fe5",
        subcategoryName: "retail & shopping",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/retailshopping.svg",
        services: [
          {
            serviceId: "66d351d88c69575453cb65fd",
            serviceName: "agricultural pesticide dealer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/agriculturepesticidesdealericon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d351898c69575453cb65fc",
            serviceName: "agricultural tool dealer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/agriculturetooldealericon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf645f995d961598fe8",
            serviceName: "atta chakki maker",
            serviceSortOrder: 89,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/attachakkimaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/attachakkimakerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be045f995d961598f53",
            serviceName: "automotive services and accessories",
            serviceSortOrder: 90,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/automotiveservicesaccessories.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/automotiveservicesaccessoriesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2c14be0fa4ee39921dfe",
            serviceName: "bakery/cake shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bakeryclasses.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bakerycakeshopicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c1d45f995d9615990e5",
            serviceName: "computer supplies and repairs",
            serviceSortOrder: 91,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/computersupplies.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/computersuppliesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf745f995d961598fef",
            serviceName: "dairy shop",
            serviceSortOrder: 92,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dairyshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/dairyshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2b67be0fa4ee39921dfb",
            serviceName: "electronic shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/electronicshopicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3ab5c2bcf732eb2ecfe7",
            serviceName: "fruit stall",
            serviceSortOrder: 102,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fruitstall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/fruitstallserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf845f995d961598ff6",
            serviceName: "furniture shop",
            serviceSortOrder: 93,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/furnitureshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/furnitureshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf945f995d961598ffd",
            serviceName: "game center",
            serviceSortOrder: 94,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gamecenter.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gamecenterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be345f995d961598f68",
            serviceName: "gas refill shop",
            serviceSortOrder: 95,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2c82be0fa4ee39921dff",
            serviceName: "general store",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/generalstoreicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d548ce279fd41f77d4aa6e",
            serviceName: "gift shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/giftshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/giftshopicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be445f995d961598f6f",
            serviceName: "glass and aluminium shop",
            serviceSortOrder: 97,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfa45f995d961599004",
            serviceName: "grocery and confectionery",
            serviceSortOrder: 98,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/groceryconfectionery.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/groceryconfectioneryserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be645f995d961598f7d",
            serviceName: "hardware and repair shop",
            serviceSortOrder: 99,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d54767279fd41f77d4aa6c",
            serviceName: "home made pickle seller",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadepickleseller.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadepicklesellericon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfc45f995d96159900b",
            serviceName: "ice cream parlour",
            serviceSortOrder: 100,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/icecreamparlour.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/icecreamparlourserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5f45f995d96159927d",
            serviceName: "it hardware",
            serviceSortOrder: 100,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/itsupport.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ithardwareicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfd45f995d961599012",
            serviceName: "jewellery shop",
            serviceSortOrder: 101,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/jewelleryshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/jewelleryshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfe45f995d961599019",
            serviceName: "juice shop",
            serviceSortOrder: 102,
            isActive: true,
            description: "Nature’s flavors, served in a glass.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/juiceshop.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/juiceshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bff45f995d961599020",
            serviceName: "mattress and carpet shop",
            serviceSortOrder: 104,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mattressandcarpet shop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mattressandcarpetshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0045f995d961599027",
            serviceName: "meat shop",
            serviceSortOrder: 105,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/meatshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/meatshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bdc45f995d961598f3c",
            serviceName: "medical pharmacy",
            serviceSortOrder: 106,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalpharmacy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalpharmacyserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c2b45f995d96159913b",
            serviceName: "optical eye shop",
            serviceSortOrder: 107,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/opticaleyeshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/opticaleyeshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c2c45f995d961599142",
            serviceName: "paan shop",
            serviceSortOrder: 108,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/paanshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/paanshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2a85be0fa4ee39921df9",
            serviceName: "perfume shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/perfumeshopicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0245f995d961599035",
            serviceName: "puja samagri",
            serviceSortOrder: 109,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pujasamagri.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/pujasamagriserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0345f995d96159903c",
            serviceName: "sports shop",
            serviceSortOrder: 110,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0445f995d961599043",
            serviceName: "stationary",
            serviceSortOrder: 111,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/stationary.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/stationaryserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d5c41ca03a79e993d1e1c8",
            serviceName: "sweets box manufacturer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sweetsboxmanufacturer.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/sweetsboxmanufacturericon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0545f995d96159904a",
            serviceName: "tea stall",
            serviceSortOrder: 112,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/teastall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/teastallserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0645f995d961599051",
            serviceName: "toy shop",
            serviceSortOrder: 113,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/toyshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/toyshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0745f995d961599058",
            serviceName: "vegetable seller",
            serviceSortOrder: 115,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vegetableseller.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/vegetablesellerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf445f995d961598fd8",
            serviceName: "watch shop",
            serviceSortOrder: 116,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/watchshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/watchshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c3445f995d96159916f",
    categoryName: "travel",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/travel.svg",
    subcategories: [
      {
        subcategoryId: "66976c4b45f995d961599204",
        subcategoryName: "activities",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/activities.svg",
        services: [
          {
            serviceId: "66976c4b45f995d961599207",
            serviceName: "adventure activities",
            serviceSortOrder: 141,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/adventureactivities.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/adventureactivitiesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4d45f995d96159920e",
            serviceName: "tourist guide",
            serviceSortOrder: 142,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/touristguide.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/touristguideserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bd745f995d961598f1b",
        subcategoryName: "food & beverage",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/foodbeverage.svg",
        services: [
          {
            serviceId: "66976bd845f995d961598f1e",
            serviceName: "cafe",
            serviceSortOrder: 137,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cafe.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cafeserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd945f995d961598f25",
            serviceName: "homemade food",
            serviceSortOrder: 138,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadefood.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadefoodserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bda45f995d961598f2c",
            serviceName: "restaurant",
            serviceSortOrder: 139,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/restaurant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/restaurantserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bdb45f995d961598f33",
            serviceName: "street food",
            serviceSortOrder: 140,
            isActive: true,
            description: "Bite into the soul of the city.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/streetfood.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/streetfoodserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c4d45f995d961599214",
        subcategoryName: "international",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/international.svg",
        services: [
          {
            serviceId: "66976c4e45f995d961599217",
            serviceName: "international",
            serviceSortOrder: 143,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/international.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/internationalserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: true,
          },
        ],
      },
      {
        subcategoryId: "66976c4245f995d9615991cb",
        subcategoryName: "local shopping",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/localshopping.svg",
        services: [
          {
            serviceId: "66976c4345f995d9615991ce",
            serviceName: "collectibles",
            serviceSortOrder: 132,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/collectibles.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/collectiblesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4445f995d9615991d5",
            serviceName: "culinary souvenirs",
            serviceSortOrder: 133,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/culinarysouvenirs.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/culinarysouvenirsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4545f995d9615991dc",
            serviceName: "sweet shop",
            serviceSortOrder: 134,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/sweetshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/sweetshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4645f995d9615991e3",
            serviceName: "traditional clothing",
            serviceSortOrder: 135,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/traditionalclothing.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/traditionalclothingserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4745f995d9615991ea",
            serviceName: "traditional craftsmen",
            serviceSortOrder: 136,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/traditionalcraftsmen.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/traditionalcraftsmenserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c3c45f995d96159919f",
        subcategoryName: "stay",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/stay.svg",
        services: [
          {
            serviceId: "66976c3c45f995d9615991a2",
            serviceName: "backpacker hostels",
            serviceSortOrder: 126,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/backpackerhostels.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/backpackerhostelsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c3d45f995d9615991a9",
            serviceName: "camping",
            serviceSortOrder: 127,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/camping.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/campingserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c3e45f995d9615991b0",
            serviceName: "home stay",
            serviceSortOrder: 128,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/homestay.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homestayserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c3f45f995d9615991b7",
            serviceName: "hostel",
            serviceSortOrder: 129,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/hostel.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hostelserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4045f995d9615991be",
            serviceName: "hotel/resort",
            serviceSortOrder: 130,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hotelresort.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hotelresortserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4145f995d9615991c5",
            serviceName: "lodge",
            serviceSortOrder: 131,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/lodge.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/lodgeserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c0845f995d96159905e",
        subcategoryName: "transportation",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/transportation.svg",
        services: [
          {
            serviceId: "66976c0845f995d961599061",
            serviceName: "auto rickshaw driver",
            serviceSortOrder: 117,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/autorickshawdriver.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/autorickshawdriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0945f995d961599068",
            serviceName: "bike rentals",
            serviceSortOrder: 118,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0a45f995d96159906f",
            serviceName: "bike taxi driver",
            serviceSortOrder: 119,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/biketaxidriver.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/biketaxidriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0f45f995d96159908b",
            serviceName: "cab/taxi",
            serviceSortOrder: 119,
            isActive: true,
            description: "Safe travels, every time.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cabtaxi.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/taxidriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c0c45f995d961599076",
            serviceName: "car rentals",
            serviceSortOrder: 120,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0d45f995d96159907d",
            serviceName: "cycle rentals",
            serviceSortOrder: 121,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cyclerentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cyclerentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0e45f995d961599084",
            serviceName: "driver/chauffeur",
            serviceSortOrder: 122,
            isActive: true,
            description: "Experience the road with confidence.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/driverchauffeur2.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/driverchauffeurserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c1045f995d961599092",
            serviceName: "tours and travels",
            serviceSortOrder: 124,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tourstravels.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tourstravelsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c1145f995d961599099",
            serviceName: "travel agent",
            serviceSortOrder: 125,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/travelagent.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/travelagentserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c4e45f995d96159921c",
    categoryName: "professional services",
    categoryImage:
      "https://d2uvzz1jka6usw.cloudfront.net/professionalservices.svg",
    subcategories: [
      {
        subcategoryId: "66976c6e45f995d9615992e0",
        subcategoryName: "creative & entertainment",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/creativeentertainment.svg",
        services: [
          {
            serviceId: "66976c6e45f995d9615992e3",
            serviceName: "artisan",
            serviceSortOrder: 172,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/artisan.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/artisanserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6f45f995d9615992ea",
            serviceName: "artist",
            serviceSortOrder: 173,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/artist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/artistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7045f995d9615992f1",
            serviceName: "balloon decorator",
            serviceSortOrder: 174,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/balloondecorator.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/balloondecoratorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7145f995d9615992f8",
            serviceName: "birthday planner",
            serviceSortOrder: 175,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/birthdayplanner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/birthdayplannerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bda32e15f9f92376e9fdd1",
            serviceName: "content creator",
            serviceSortOrder: 174,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/digitalmarketingconsultant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/contentcreatoricon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7245f995d9615992ff",
            serviceName: "DJ",
            serviceSortOrder: 176,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dj.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/djserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7445f995d961599306",
            serviceName: "event manager",
            serviceSortOrder: 177,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/eventmanager.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/eventmanagerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7545f995d96159930d",
            serviceName: "event planner",
            serviceSortOrder: 178,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/eventplanner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/eventplannerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bda35515f9f92376e9fdd2",
            serviceName: "influencer",
            serviceSortOrder: 177,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/digitalmarketingconsultant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/influencericon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7645f995d961599314",
            serviceName: "lighting specialist",
            serviceSortOrder: 179,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/lightingspecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/lightingspecialistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7745f995d96159931b",
            serviceName: "music band",
            serviceSortOrder: 180,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/musicband.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/musicbandserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7845f995d961599322",
            serviceName: "party hall",
            serviceSortOrder: 181,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/partyhall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/partyhallserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7945f995d961599329",
            serviceName: "photographer/videographer",
            serviceSortOrder: 182,
            isActive: true,
            description: "Where every click tells a story.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/photographervideographer.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/photographervideographerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c7a45f995d961599330",
            serviceName: "recording music studio",
            serviceSortOrder: 183,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/recordingmusicstudio.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/recordingmusicstudioserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7b45f995d961599337",
            serviceName: "stand up artist",
            serviceSortOrder: 184,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/standupartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/standupartistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7c45f995d96159933e",
            serviceName: "tattoo artist",
            serviceSortOrder: 185,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tattooartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tattooartistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7d45f995d961599345",
            serviceName: "tattoo removal specialist",
            serviceSortOrder: 186,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tattooremovalspecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tattooremovalspecialistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c6d45f995d9615992d7",
        subcategoryName: "job",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/job.svg",
        services: [
          {
            serviceId: "66976c6d45f995d9615992da",
            serviceName: "find job",
            serviceSortOrder: 171,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/findjob.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/findjobserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: true,
          },
        ],
      },
      {
        subcategoryId: "66976c4f45f995d96159921f",
        subcategoryName: "professional services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/professionalservices.svg",
        services: [
          {
            serviceId: "66976c4f45f995d961599222",
            serviceName: "accountant",
            serviceSortOrder: 144,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/accountant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/accountantserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5045f995d961599229",
            serviceName: "architect",
            serviceSortOrder: 145,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/architect.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/architectserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4efc098c25fc9199e88d",
            serviceName: "architecture academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/architecture academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/architectureacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee6098c25fc9199e7fd",
            serviceName: "art academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/art academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/artacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5145f995d961599230",
            serviceName: "astrologer",
            serviceSortOrder: 146,
            isActive: true,
            description: "Where the stars align with your story.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/astrologer.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/astrologerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f02098c25fc9199e8b5",
            serviceName: "astronomy academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/astronomy academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/astronomyacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd9b4415f9f92376e9fdce",
            serviceName: "bike repair",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerepairicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef5098c25fc9199e85d",
            serviceName: "business/entrepreneurship academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/business/entrepreneurship academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/businessentrepreneurshipacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d352ad8c69575453cb65fe",
            serviceName: "car driving school",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cardrivingschoolicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd9be215f9f92376e9fdcf",
            serviceName: "car repair",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carrepairicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc297bbe0fa4ee39921df8",
            serviceName: "car/bike dealer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carbikedealericon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5345f995d961599237",
            serviceName: "catering service",
            serviceSortOrder: 147,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cateringservice.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cateringserviceserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5445f995d96159923e",
            serviceName: "chartered accountant",
            serviceSortOrder: 148,
            isActive: true,
            description: "Your trusted partner in financial success.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/charteredaccountant.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/charteredaccountantserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef3098c25fc9199e855",
            serviceName: "coding/programming academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/coding/programming academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/codingprogrammingacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee9098c25fc9199e80d",
            serviceName: "cooking academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cooking academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cookingacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef1098c25fc9199e845",
            serviceName: "culinary arts academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/culinary arts academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/aculinaryartsacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2b0fbe0fa4ee39921dfa",
            serviceName: "daily wage worker",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homecaretaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/dailywageworkericon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d3552e8c69575453cb65ff",
            serviceName: "detective agency",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/detectiveagency.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/detectiveagencyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5545f995d961599245",
            serviceName: "digital marketing consultant",
            serviceSortOrder: 149,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/digitalmarketingconsultant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/digitalmarketingconsultantserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f01098c25fc9199e8ad",
            serviceName: "engineering academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/engineering academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/engineeringacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f07098c25fc9199e8d5",
            serviceName: "environmental studies academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/environmental studies academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/environmentalstudiesacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5745f995d96159924c",
            serviceName: "equity stock broker",
            serviceSortOrder: 150,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/equitystockbroker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/equitystockbrokerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f04098c25fc9199e8c5",
            serviceName: "etiquette academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/etiquette academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/etiquetteacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef0098c25fc9199e83d",
            serviceName: "fashion design academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fashion design academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/fashiondesignacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eed098c25fc9199e82d",
            serviceName: "film/acting academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/film/acting academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/filmactingacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bedd4b170ead650c3c28d2",
            serviceName: "fire fighting vendor",
            serviceSortOrder: 150,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/firefightingvendoricon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "6698a9c864a85a0fa65b4631",
            serviceName: "firecracker factory",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/firecrackerfactory.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/firecrackerfactoryserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d357588c69575453cb6603",
            serviceName: "forex",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/forex.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/forexicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef8098c25fc9199e875",
            serviceName: "graphic design academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/graphic design academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/graphicdesignacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5945f995d961599253",
            serviceName: "graphic designer",
            serviceSortOrder: 151,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/graphicdesigner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/graphicdesignerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2bd9be0fa4ee39921dfd",
            serviceName: "home builder",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homecaretaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homebuildericon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5a45f995d96159925a",
            serviceName: "home decor",
            serviceSortOrder: 152,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/home decor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homedecorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5b45f995d961599261",
            serviceName: "home organizer",
            serviceSortOrder: 153,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homeorganizer.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homeorganizerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bda93b15f9f92376e9fdd3",
            serviceName: "house keeping",
            serviceSortOrder: 153,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homecaretaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/housekeepingicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5c45f995d961599268",
            serviceName: "insurance broker",
            serviceSortOrder: 154,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/insurancebroker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/insurancebrokerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5d45f995d96159926f",
            serviceName: "interior decorator",
            serviceSortOrder: 155,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/interiordecorator.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/interiordecoratorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4efd098c25fc9199e895",
            serviceName: "interior design academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/interior design academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/interiordesignacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5e45f995d961599276",
            serviceName: "interior designer",
            serviceSortOrder: 156,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/interiordesigner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/interiordesignerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3b05c2bcf732eb2ecfe8",
            serviceName: "ironing",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/ironing.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ironingserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5f45f995d96159927d",
            serviceName: "it hardware",
            serviceSortOrder: 157,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/itsupport.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ithardwareicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eea098c25fc9199e815",
            serviceName: "language academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/language academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/languageacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6045f995d961599284",
            serviceName: "lawyer",
            serviceSortOrder: 158,
            isActive: true,
            description: "Guiding you through the legal maze.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/lawyer.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/lawyerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f05098c25fc9199e8cd",
            serviceName: "leadership academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/leadership academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/leadershipacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6145f995d96159928b",
            serviceName: "legal consultant",
            serviceSortOrder: 159,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/legalconsultant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/legalconsultantserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d357bd8c69575453cb6604",
            serviceName: "loan/finance",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/loanfinance.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/loanfinanceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee7098c25fc9199e805",
            serviceName: "martial arts academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/martial arts academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/martialartsacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef7098c25fc9199e86d",
            serviceName: "mathematics academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mathematics academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mathematicsacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd02b9993052292902c094",
            serviceName: "media publications",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/digitalmarketingconsultant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mediapublicationsicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee5098c25fc9199e7f5",
            serviceName: "music academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/music academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/musicacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eff098c25fc9199e8a5",
            serviceName: "music production academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/music production academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/musicproductionacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6245f995d961599292",
            serviceName: "mutual fund broker",
            serviceSortOrder: 160,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mutualfundbroker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mutualfundbrokerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6345f995d961599299",
            serviceName: "notary shop",
            serviceSortOrder: 161,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/notaryshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/notaryshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4efe098c25fc9199e89d",
            serviceName: "painting academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/painting academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/paintingacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6445f995d9615992a0",
            serviceName: "personal bodyguard",
            serviceSortOrder: 162,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/personalbodyguard.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/personalbodyguardserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eef098c25fc9199e835",
            serviceName: "photography academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/photography academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/photographyacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef9098c25fc9199e87d",
            serviceName: "public speaking/debate academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/public speaking/debate academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/publicspeakingdebateacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f03098c25fc9199e8bd",
            serviceName: "robotics academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/robotics academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/roboticsacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef6098c25fc9199e865",
            serviceName: "science academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/science academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/scienceacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3b19c2bcf732eb2ecfe9",
            serviceName: "scraper",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/scraper.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/scraperserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6545f995d9615992a7",
            serviceName: "security guard",
            serviceSortOrder: 163,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/securityguard.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/securityguardserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6645f995d9615992ae",
            serviceName: "security system installer",
            serviceSortOrder: 164,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/securitysysteminstaller.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/securitysysteminstallerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6845f995d9615992b5",
            serviceName: "seo specialist",
            serviceSortOrder: 165,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/seospecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/seospecialistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6945f995d9615992bc",
            serviceName: "solar panel installer",
            serviceSortOrder: 166,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/solarpanelinstaller.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/solarpanelinstallerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6a45f995d9615992c3",
            serviceName: "speech therapist",
            serviceSortOrder: 167,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/speechtherapist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/speechtherapistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eeb098c25fc9199e81d",
            serviceName: "sports academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sports academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6b45f995d9615992ca",
            serviceName: "stock investor broker",
            serviceSortOrder: 168,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/stockinvestorbroker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/stockinvestorbrokerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bee60f170ead650c3c28d3",
            serviceName: "tax consultant",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/accountant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/accountantserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d356f28c69575453cb6602",
            serviceName: "tent shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/tentshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tentshopicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eec098c25fc9199e825",
            serviceName: "theater/drama academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/theater/drama Academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/theaterdramaacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d356af8c69575453cb6601",
            serviceName: "visa assistance",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/visaassistance.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/visaassistanceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c6c45f995d9615992d1",
            serviceName: "website developer",
            serviceSortOrder: 169,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/websitedeveloper.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/websitedeveloperserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef2098c25fc9199e84d",
            serviceName: "writing/creative writing academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/writing/creative writing academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/writingcreativewritingacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4efb098c25fc9199e885",
            serviceName: "yoga/meditation academy",
            serviceSortOrder: 170,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/yoga/meditation academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/yogatrainerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c7e45f995d96159934a",
    categoryName: "maintenance & repairs",
    categoryImage:
      "https://d2uvzz1jka6usw.cloudfront.net/maintenancerepairs.svg",
    subcategories: [
      {
        subcategoryId: "66976bdd45f995d961598f42",
        subcategoryName: "maintenance & repairs",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/maintenancerepairs.svg",
        services: [
          {
            serviceId: "66976bde45f995d961598f45",
            serviceName: "ac on rentals",
            serviceSortOrder: 187,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/aconrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/aconrentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bdf45f995d961598f4c",
            serviceName: "appliance repair",
            serviceSortOrder: 188,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/appliancerepairhvac.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/appliancerepairhvacserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be045f995d961598f53",
            serviceName: "automotive services and accessories",
            serviceSortOrder: 205,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/automotiveservicesaccessories.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/automotiveservicesaccessoriesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be145f995d961598f5a",
            serviceName: "carpenter",
            serviceSortOrder: 189,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/carpenter.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carpenterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be245f995d961598f61",
            serviceName: "electrician",
            serviceSortOrder: 190,
            isActive: true,
            description: "Brightening your home with expert care.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/electrician.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/electricianserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66cc2b67be0fa4ee39921dfb",
            serviceName: "electronic shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/electronicshopicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be345f995d961598f68",
            serviceName: "gas refill shop",
            serviceSortOrder: 206,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be445f995d961598f6f",
            serviceName: "glass and aluminium shop",
            serviceSortOrder: 207,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be545f995d961598f76",
            serviceName: "handyman",
            serviceSortOrder: 191,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/handyman.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/handymanserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be645f995d961598f7d",
            serviceName: "hardware and repair shop",
            serviceSortOrder: 208,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be745f995d961598f84",
            serviceName: "home appliance shop",
            serviceSortOrder: 192,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homeapplianceshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homeapplianceshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be845f995d961598f8b",
            serviceName: "key maker",
            serviceSortOrder: 193,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/keymaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/keymakerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be945f995d961598f92",
            serviceName: "mistri/mason",
            serviceSortOrder: 194,
            isActive: true,
            description: "Building your dreams, brick by brick.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/masonmistri.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/masonmistriserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bea45f995d961598f99",
            serviceName: "mobile shop",
            serviceSortOrder: 195,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mobilerepairshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mobilerepairshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bec45f995d961598fa0",
            serviceName: "painter",
            serviceSortOrder: 196,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/painter.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/painterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bed45f995d961598fa7",
            serviceName: "pest control",
            serviceSortOrder: 197,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pestcontrol.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/pestcontrolserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bee45f995d961598fae",
            serviceName: "plumber",
            serviceSortOrder: 198,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/plumber.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/plumberserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bef45f995d961598fb5",
            serviceName: "roofer and shed maker",
            serviceSortOrder: 199,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/roofershedmaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/roofershedmakerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf045f995d961598fbc",
            serviceName: "shoe cobbler and repair",
            serviceSortOrder: 200,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/shoecobblerrepair.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/shoecobblerrepairserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf145f995d961598fc3",
            serviceName: "tyre puncture shop",
            serviceSortOrder: 201,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tyrepunctureshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tyrepunctureshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf245f995d961598fca",
            serviceName: "vehicle mechanic",
            serviceSortOrder: 202,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vehiclemechanic.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/vehiclemechanicserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf345f995d961598fd1",
            serviceName: "vehicle towing",
            serviceSortOrder: 203,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vehicletowing.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/vehicletowingserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf445f995d961598fd8",
            serviceName: "watch shop",
            serviceSortOrder: 209,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/watchshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/watchshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf545f995d961598fdf",
            serviceName: "welder",
            serviceSortOrder: 204,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/welder.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/welderserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c9145f995d9615993bf",
    categoryName: "food & beverage",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/foodbeverage.svg",
    subcategories: [
      {
        subcategoryId: "66976bd745f995d961598f1b",
        subcategoryName: "food & beverage",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/foodbeverage.svg",
        services: [
          {
            serviceId: "66976bd845f995d961598f1e",
            serviceName: "cafe",
            serviceSortOrder: 210,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cafe.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cafeserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcf45f995d961598ee4",
            serviceName: "cook",
            serviceSortOrder: 211,
            isActive: true,
            description: "Home made food. Just a click away!",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cook.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cookserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd945f995d961598f25",
            serviceName: "homemade food",
            serviceSortOrder: 212,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadefood.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadefoodserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bda45f995d961598f2c",
            serviceName: "restaurant",
            serviceSortOrder: 213,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/restaurant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/restaurantserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bdb45f995d961598f33",
            serviceName: "street food",
            serviceSortOrder: 214,
            isActive: true,
            description: "Bite into the soul of the city.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/streetfood.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/streetfoodserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976c9645f995d9615993da",
    categoryName: "beauty & fashion",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/beautyfashion.svg",
    subcategories: [
      {
        subcategoryId: "66976c4f45f995d96159921f",
        subcategoryName: "professional services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/professionalservices.svg",
        services: [
          {
            serviceId: "66976bbf45f995d961598e77",
            serviceName: "barber",
            serviceSortOrder: 215,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/barber.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/barberserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bdab4715f9f92376e9fdd5",
            serviceName: "beautician",
            serviceSortOrder: 216,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/beauticianicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c9745f995d9615993e3",
            serviceName: "fashion designer",
            serviceSortOrder: 216,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fashiondesigner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/fashiondesignerserviceicon2.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc045f995d961598e7e",
            serviceName: "gents salon",
            serviceSortOrder: 224,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gentssalon.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gentssalonserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc145f995d961598e85",
            serviceName: "hairdresser",
            serviceSortOrder: 217,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hairdresser.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hairdresserserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc245f995d961598e8c",
            serviceName: "ladies parlour",
            serviceSortOrder: 225,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesparlour.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesparlourserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c9945f995d9615993ef",
            serviceName: "laser hair removal specialist",
            serviceSortOrder: 218,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/laserhairremovalspecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/laserhairremovalspecialistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc345f995d961598e93",
            serviceName: "make up artist",
            serviceSortOrder: 219,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc445f995d961598e9a",
            serviceName: "mehndi designer",
            serviceSortOrder: 220,
            isActive: true,
            description: "Festival or Marriage Celebration.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mehndidesigner.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mehndidesignerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bc545f995d961598ea1",
            serviceName: "nail technician",
            serviceSortOrder: 221,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/nailtechnician.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/nailtechnicianserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc645f995d961598ea8",
            serviceName: "skincare specialist",
            serviceSortOrder: 222,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/skincarespecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/skincarespecialistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc745f995d961598eaf",
            serviceName: "unisex salon",
            serviceSortOrder: 226,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/unisexsalon.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/unisexsalonserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc845f995d961598eb6",
            serviceName: "waxing specialist",
            serviceSortOrder: 223,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/waxingspecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/waxingspecialistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976ca045f995d96159941c",
    categoryName: "settling in",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/settlingin.svg",
    subcategories: [
      {
        subcategoryId: "66976bc945f995d961598ebc",
        subcategoryName: "care",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/care.svg",
        services: [
          {
            serviceId: "66976bca45f995d961598ebf",
            serviceName: "babysitter",
            serviceSortOrder: 322,
            isActive: true,
            description: "Caring for your little ones with love.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/babysitter.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/babysitterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bcb45f995d961598ec6",
            serviceName: "elder care",
            serviceSortOrder: 323,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/eldercare.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/eldercareserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cf945f995d96159963f",
            serviceName: "home caretaker",
            serviceSortOrder: 324,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homecaretaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homecaretakerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bcc45f995d961598ecc",
        subcategoryName: "domestic services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/domesticservices.svg",
        services: [
          {
            serviceId: "66976bcc45f995d961598ecf",
            serviceName: "bathroom cleaner",
            serviceSortOrder: 311,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bathroomcleaner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bathroomcleanerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcd45f995d961598ed6",
            serviceName: "car washer",
            serviceSortOrder: 312,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/carwasher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carwasherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bce45f995d961598edd",
            serviceName: "cleaning services",
            serviceSortOrder: 313,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cleaningservices.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cleaningservicesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcf45f995d961598ee4",
            serviceName: "cook",
            serviceSortOrder: 314,
            isActive: true,
            description: "Home made food. Just a click away!",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cook.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cookserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd045f995d961598eeb",
            serviceName: "drycleaner",
            serviceSortOrder: 315,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/drycleaner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/drycleanerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd145f995d961598ef2",
            serviceName: "gardener",
            serviceSortOrder: 316,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/gardener.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gardenerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd345f995d961598f00",
            serviceName: "laundry",
            serviceSortOrder: 318,
            isActive: true,
            description: "Fresh clothes, fresher feelings.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/laundry.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/laundryserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd245f995d961598ef9",
            serviceName: "maid/servant",
            serviceSortOrder: 317,
            isActive: true,
            description: "A clean home, a happy heart.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/housemaid.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/housemaidserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd545f995d961598f07",
            serviceName: "milk man",
            serviceSortOrder: 319,
            isActive: true,
            description: "Farm-fresh milk for a healthier you.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/milkman2.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/milkmanserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd645f995d961598f0e",
            serviceName: "tailor",
            serviceSortOrder: 320,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/tailor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tailorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bd745f995d961598f15",
            serviceName: "water vendor",
            serviceSortOrder: 321,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/watervendor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/watervendorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976cdc45f995d96159958b",
        subcategoryName: "education & training",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/educationtraining.svg",
        services: [
          {
            serviceId: "66bc4efc098c25fc9199e88d",
            serviceName: "architecture academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/architecture academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/architectureacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee6098c25fc9199e7fd",
            serviceName: "art academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/art academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/artacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cdd45f995d96159958e",
            serviceName: "art teacher",
            serviceSortOrder: 295,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/artteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/artteacherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f02098c25fc9199e8b5",
            serviceName: "astronomy academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/astronomy academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/astronomyacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cde45f995d961599595",
            serviceName: "bakery classes",
            serviceSortOrder: 296,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bakeryclasses.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bakeryclassesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef5098c25fc9199e85d",
            serviceName: "business/entrepreneurship academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/business/entrepreneurship academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/businessentrepreneurshipacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef3098c25fc9199e855",
            serviceName: "coding/programming academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/coding/programming academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/codingprogrammingacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee9098c25fc9199e80d",
            serviceName: "cooking academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cooking academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cookingacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cdf45f995d96159959c",
            serviceName: "cooking classes",
            serviceSortOrder: 297,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cookingclassteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cookingclassteacherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef1098c25fc9199e845",
            serviceName: "culinary arts academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/culinary arts academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/aculinaryartsacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce045f995d9615995a3",
            serviceName: "dance academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/danceacademy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/danceacademyserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce145f995d9615995aa",
            serviceName: "dance teacher",
            serviceSortOrder: 299,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/danceteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/danceteacherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f01098c25fc9199e8ad",
            serviceName: "engineering academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/engineering academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/engineeringacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce245f995d9615995b1",
            serviceName: "engineering teacher",
            serviceSortOrder: 300,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/engineeringteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/engineeringteacherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f07098c25fc9199e8d5",
            serviceName: "environmental studies academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/environmental studies academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/environmentalstudiesacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f04098c25fc9199e8c5",
            serviceName: "etiquette academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/etiquette academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/etiquetteacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef0098c25fc9199e83d",
            serviceName: "fashion design academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fashion design academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/fashiondesignacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eed098c25fc9199e82d",
            serviceName: "film/acting academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/film/acting academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/filmactingacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef8098c25fc9199e875",
            serviceName: "graphic design academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/graphic design academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/graphicdesignacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce345f995d9615995b8",
            serviceName: "high school teacher",
            serviceSortOrder: 301,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/highschoolteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/highschoolteacherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce445f995d9615995bf",
            serviceName: "home tutor",
            serviceSortOrder: 302,
            isActive: true,
            description: "Where learning meets personalized attention.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hometutor2.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hometutorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66bc4efd098c25fc9199e895",
            serviceName: "interior design academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/interior design academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/interiordesignacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eea098c25fc9199e815",
            serviceName: "language academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/language academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/languageacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce545f995d9615995c6",
            serviceName: "language tutor",
            serviceSortOrder: 303,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/languagetutor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/languagetutorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f05098c25fc9199e8cd",
            serviceName: "leadership academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/leadership academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/leadershipacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee7098c25fc9199e805",
            serviceName: "martial arts academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/martial arts academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/martialartsacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef7098c25fc9199e86d",
            serviceName: "mathematics academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mathematics academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mathematicsacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce645f995d9615995cd",
            serviceName: "medical teacher",
            serviceSortOrder: 304,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalteacherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ee5098c25fc9199e7f5",
            serviceName: "music academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/music academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/musicacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eff098c25fc9199e8a5",
            serviceName: "music production academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/music production academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/musicproductionacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce745f995d9615995d4",
            serviceName: "music teacher",
            serviceSortOrder: 305,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/musicteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/musicteacherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4efe098c25fc9199e89d",
            serviceName: "painting academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/painting academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/paintingacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eef098c25fc9199e835",
            serviceName: "photography academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/photography academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/photographyacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce845f995d9615995db",
            serviceName: "playschool",
            serviceSortOrder: 306,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/playschool.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/playschoolserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ce945f995d9615995e2",
            serviceName: "pre nursery teacher",
            serviceSortOrder: 307,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/prenurseryteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/prenurseryteacherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cea45f995d9615995e9",
            serviceName: "primary teacher",
            serviceSortOrder: 308,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/primaryteacher.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/primaryteacherserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef9098c25fc9199e87d",
            serviceName: "public speaking/debate academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/public speaking/debate academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/publicspeakingdebateacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4f03098c25fc9199e8bd",
            serviceName: "robotics academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/robotics academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/roboticsacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef6098c25fc9199e865",
            serviceName: "science academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/science academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/scienceacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eeb098c25fc9199e81d",
            serviceName: "sports academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sports academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cec45f995d9615995f0",
            serviceName: "sports trainer",
            serviceSortOrder: 309,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sportstrainer.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/sportstrainerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4eec098c25fc9199e825",
            serviceName: "theater/drama academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/theater/drama Academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/theaterdramaacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ced45f995d9615995f7",
            serviceName: "voice coach",
            serviceSortOrder: 310,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/voicecoach.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/voicecoachserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4ef2098c25fc9199e84d",
            serviceName: "writing/creative writing academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/writing/creative writing academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/writingcreativewritingacademyicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bc4efb098c25fc9199e885",
            serviceName: "yoga/meditation academy",
            serviceSortOrder: 298,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/yoga/meditation academy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/yogatrainerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bd745f995d961598f1b",
        subcategoryName: "food & beverage",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/foodbeverage.svg",
        services: [
          {
            serviceId: "66976bd845f995d961598f1e",
            serviceName: "cafe",
            serviceSortOrder: 290,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cafe.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cafeserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bcf45f995d961598ee4",
            serviceName: "cook",
            serviceSortOrder: 291,
            isActive: true,
            description: "Home made food. Just a click away!",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cook.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cookserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bd945f995d961598f25",
            serviceName: "homemade food",
            serviceSortOrder: 292,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadefood.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homemadefoodserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bda45f995d961598f2c",
            serviceName: "restaurant",
            serviceSortOrder: 293,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/restaurant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/restaurantserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bdb45f995d961598f33",
            serviceName: "street food",
            serviceSortOrder: 294,
            isActive: true,
            description: "Bite into the soul of the city.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/streetfood.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/streetfoodserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bdd45f995d961598f42",
        subcategoryName: "maintenance & repairs",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/maintenancerepairs.svg",
        services: [
          {
            serviceId: "66976bde45f995d961598f45",
            serviceName: "ac on rentals",
            serviceSortOrder: 267,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/aconrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/aconrentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bdf45f995d961598f4c",
            serviceName: "appliance repair",
            serviceSortOrder: 268,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/appliancerepairhvac.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/appliancerepairhvacserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be045f995d961598f53",
            serviceName: "automotive services and accessories",
            serviceSortOrder: 269,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/automotiveservicesaccessories.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/automotiveservicesaccessoriesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be145f995d961598f5a",
            serviceName: "carpenter",
            serviceSortOrder: 270,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/carpenter.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carpenterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be245f995d961598f61",
            serviceName: "electrician",
            serviceSortOrder: 271,
            isActive: true,
            description: "Brightening your home with expert care.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/electrician.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/electricianserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976be345f995d961598f68",
            serviceName: "gas refill shop",
            serviceSortOrder: 272,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gasrefillshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be445f995d961598f6f",
            serviceName: "glass and aluminium shop",
            serviceSortOrder: 273,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/glassaluminiumshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be545f995d961598f76",
            serviceName: "handyman",
            serviceSortOrder: 274,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/handyman.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/handymanserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be645f995d961598f7d",
            serviceName: "hardware and repair shop",
            serviceSortOrder: 275,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be745f995d961598f84",
            serviceName: "home appliance shop",
            serviceSortOrder: 276,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homeapplianceshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homeapplianceshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be845f995d961598f8b",
            serviceName: "key maker",
            serviceSortOrder: 277,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/keymaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/keymakerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976be945f995d961598f92",
            serviceName: "mistri/mason",
            serviceSortOrder: 278,
            isActive: true,
            description: "Building your dreams, brick by brick.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/masonmistri.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/masonmistriserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bea45f995d961598f99",
            serviceName: "mobile shop",
            serviceSortOrder: 279,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mobilerepairshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mobilerepairshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bec45f995d961598fa0",
            serviceName: "painter",
            serviceSortOrder: 280,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/painter.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/painterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bed45f995d961598fa7",
            serviceName: "pest control",
            serviceSortOrder: 281,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pestcontrol.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/pestcontrolserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bee45f995d961598fae",
            serviceName: "plumber",
            serviceSortOrder: 282,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/plumber.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/plumberserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bef45f995d961598fb5",
            serviceName: "roofer and shed maker",
            serviceSortOrder: 283,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/roofershedmaker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/roofershedmakerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf045f995d961598fbc",
            serviceName: "shoe cobbler and repair",
            serviceSortOrder: 284,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/shoecobblerrepair.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/shoecobblerrepairserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf145f995d961598fc3",
            serviceName: "tyre puncture shop",
            serviceSortOrder: 285,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tyrepunctureshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tyrepunctureshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf245f995d961598fca",
            serviceName: "vehicle mechanic",
            serviceSortOrder: 286,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vehiclemechanic.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/vehiclemechanicserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf345f995d961598fd1",
            serviceName: "vehicle towing",
            serviceSortOrder: 287,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vehicletowing.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/vehicletowingserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf445f995d961598fd8",
            serviceName: "watch shop",
            serviceSortOrder: 288,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/watchshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/watchshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf545f995d961598fdf",
            serviceName: "welder",
            serviceSortOrder: 289,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/welder.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/welderserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976cbf45f995d9615994da",
        subcategoryName: "pet care",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/petcare.svg",
        services: [
          {
            serviceId: "66976cc045f995d9615994dd",
            serviceName: "pet adoption care",
            serviceSortOrder: 262,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/petadoptioncare.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/petadoptioncareserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cc145f995d9615994e4",
            serviceName: "pet boarding",
            serviceSortOrder: 263,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/petboarding.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/petboardingserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cc245f995d9615994eb",
            serviceName: "pet care",
            serviceSortOrder: 264,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/petcare.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/petcareserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cc345f995d9615994f2",
            serviceName: "pet shelter",
            serviceSortOrder: 265,
            isActive: true,
            description: "Giving love and hope to homeless pets.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/petshelter.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/petshelterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976cc445f995d9615994f9",
            serviceName: "pet trainer",
            serviceSortOrder: 266,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pettrainer.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/pettrainerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976cbd45f995d9615994ca",
        subcategoryName: "real estate",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/realestate.svg",
        services: [
          {
            serviceId: "66976cbe45f995d9615994cd",
            serviceName: "home on rent",
            serviceSortOrder: 260,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/homeonrent.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homeonrentserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cbf45f995d9615994d4",
            serviceName: "real estate broker",
            serviceSortOrder: 261,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/realestatebroker.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/realestatebrokerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bf645f995d961598fe5",
        subcategoryName: "retail & shopping",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/retailshopping.svg",
        services: [
          {
            serviceId: "66976bf745f995d961598fef",
            serviceName: "dairy shop",
            serviceSortOrder: 245,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dairyshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/dairyshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bb3ab5c2bcf732eb2ecfe7",
            serviceName: "fruit stall",
            serviceSortOrder: 251,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fruitstall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/fruitstallserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf845f995d961598ff6",
            serviceName: "furniture shop",
            serviceSortOrder: 246,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/furnitureshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/furnitureshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bf945f995d961598ffd",
            serviceName: "game center",
            serviceSortOrder: 247,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gamecenter.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gamecenterserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfa45f995d961599004",
            serviceName: "grocery and confectionery",
            serviceSortOrder: 248,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/groceryconfectionery.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/groceryconfectioneryserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfc45f995d96159900b",
            serviceName: "ice cream parlour",
            serviceSortOrder: 249,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/icecreamparlour.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/icecreamparlourserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfd45f995d961599012",
            serviceName: "jewellery shop",
            serviceSortOrder: 250,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/jewelleryshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/jewelleryshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bfe45f995d961599019",
            serviceName: "juice shop",
            serviceSortOrder: 251,
            isActive: true,
            description: "Nature’s flavors, served in a glass.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/juiceshop.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/juiceshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976bff45f995d961599020",
            serviceName: "mattress and carpet shop",
            serviceSortOrder: 252,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mattressandcarpet shop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mattressandcarpetshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0045f995d961599027",
            serviceName: "meat shop",
            serviceSortOrder: 253,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/meatshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/meatshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c2c45f995d961599142",
            serviceName: "paan shop",
            serviceSortOrder: 254,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/paanshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/paanshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0345f995d96159903c",
            serviceName: "sports shop",
            serviceSortOrder: 255,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0445f995d961599043",
            serviceName: "stationary",
            serviceSortOrder: 256,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/stationary.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/stationaryserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0545f995d96159904a",
            serviceName: "tea stall",
            serviceSortOrder: 257,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/teastall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/teastallserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0645f995d961599051",
            serviceName: "toy shop",
            serviceSortOrder: 258,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/toyshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/toyshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0745f995d961599058",
            serviceName: "vegetable seller",
            serviceSortOrder: 259,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/vegetableseller.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/vegetablesellerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c3c45f995d96159919f",
        subcategoryName: "stay",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/stay.svg",
        services: [
          {
            serviceId: "66976c3c45f995d9615991a2",
            serviceName: "backpacker hostels",
            serviceSortOrder: 237,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/backpackerhostels.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/backpackerhostelsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c3d45f995d9615991a9",
            serviceName: "camping",
            serviceSortOrder: 238,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/camping.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/campingserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c3e45f995d9615991b0",
            serviceName: "home stay",
            serviceSortOrder: 239,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/homestay.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/homestayserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c3f45f995d9615991b7",
            serviceName: "hostel",
            serviceSortOrder: 240,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/hostel.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hostelserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4045f995d9615991be",
            serviceName: "hotel/resort",
            serviceSortOrder: 241,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hotelresort.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hotelresortserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c4145f995d9615991c5",
            serviceName: "lodge",
            serviceSortOrder: 242,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/lodge.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/lodgeserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cae45f995d961599472",
            serviceName: "paying guest",
            serviceSortOrder: 243,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/pg.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/pgserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cb045f995d961599479",
            serviceName: "tenant",
            serviceSortOrder: 244,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/tenant.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tenantserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c0845f995d96159905e",
        subcategoryName: "transportation",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/transportation.svg",
        services: [
          {
            serviceId: "66976c0845f995d961599061",
            serviceName: "auto rickshaw driver",
            serviceSortOrder: 227,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/autorickshawdriver.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/autorickshawdriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0945f995d961599068",
            serviceName: "bike rentals",
            serviceSortOrder: 228,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd9b4415f9f92376e9fdce",
            serviceName: "bike repair",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bikerepairicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0a45f995d96159906f",
            serviceName: "bike taxi driver",
            serviceSortOrder: 229,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/biketaxidriver.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/biketaxidriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0f45f995d96159908b",
            serviceName: "cab/taxi",
            serviceSortOrder: 229,
            isActive: true,
            description: "Safe travels, every time.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/cabtaxi.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/taxidriverserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c0c45f995d961599076",
            serviceName: "car rentals",
            serviceSortOrder: 230,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66bd9be215f9f92376e9fdcf",
            serviceName: "car repair",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hardwareandrepairsshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carrepairicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc297bbe0fa4ee39921df8",
            serviceName: "car/bike dealer",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/carrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/carbikedealericon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0d45f995d96159907d",
            serviceName: "cycle rentals",
            serviceSortOrder: 231,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cyclerentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cyclerentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c0e45f995d961599084",
            serviceName: "driver/chauffeur",
            serviceSortOrder: 232,
            isActive: true,
            description: "Experience the road with confidence.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/driverchauffeur2.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/driverchauffeurserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976ca645f995d96159943e",
            serviceName: "movers and packers",
            serviceSortOrder: 233,
            isActive: true,
            description: "Reliable hands for your most precious belongings.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/moverspackers.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/moverspackersserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976c1045f995d961599092",
            serviceName: "tours and travels",
            serviceSortOrder: 235,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/tourstravels.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tourstravelsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c1145f995d961599099",
            serviceName: "travel agent",
            serviceSortOrder: 236,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/travelagent.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/travelagentserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976cf945f995d961599644",
    categoryName: "health & wellness",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/healthwellness.svg",
    subcategories: [
      {
        subcategoryId: "66976bdc45f995d961598f39",
        subcategoryName: "health & wellness",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/healthwellness.svg",
        services: [
          {
            serviceId: "66d356428c69575453cb6600",
            serviceName: "ambulance",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/ambulance.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ambulanceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cfa45f995d961599648",
            serviceName: "blood test lab",
            serviceSortOrder: 325,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/bloodtestlab.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/bloodtestlabserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c4f45f995d96159921f",
        subcategoryName: "professional services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/professionalservices.svg",
        services: [
          {
            serviceId: "66976cfb45f995d96159964f",
            serviceName: "acupuncturist",
            serviceSortOrder: 326,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/acupuncturist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/acupuncturistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cfc45f995d961599656",
            serviceName: "chiropractor",
            serviceSortOrder: 327,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/chiropractor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/chiropractorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cfd45f995d96159965d",
            serviceName: "dentist",
            serviceSortOrder: 328,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dentist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/dentistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cfe45f995d961599664",
            serviceName: "dermatologist",
            serviceSortOrder: 329,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/dermatologist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/dermatologistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976cff45f995d96159966b",
            serviceName: "dietitian",
            serviceSortOrder: 330,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dietitian.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/dietitianserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0045f995d961599672",
            serviceName: "doctor",
            serviceSortOrder: 331,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/doctor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/doctorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66cc2ba3be0fa4ee39921dfc",
            serviceName: "gym",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gymtrainer.svg",
            serviceIcon: "https://d2uvzz1jka6usw.cloudfront.net/gymicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0145f995d961599679",
            serviceName: "gym trainer",
            serviceSortOrder: 332,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/gymtrainer.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/gymtrainerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0245f995d961599680",
            serviceName: "herbalist",
            serviceSortOrder: 333,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/herbalist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/herbalistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0345f995d961599687",
            serviceName: "massage therapist",
            serviceSortOrder: 334,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/massagetherapist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/massagetherapistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0545f995d96159968e",
            serviceName: "nutritionist",
            serviceSortOrder: 335,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/nutritionist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/nutritionistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0645f995d961599695",
            serviceName: "occupational therapist",
            serviceSortOrder: 336,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/occupationaltherapist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/occupationaltherapistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0745f995d96159969c",
            serviceName: "online doctor",
            serviceSortOrder: 337,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/onlinedoctor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/onlinedoctorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0845f995d9615996a3",
            serviceName: "physiotherapist",
            serviceSortOrder: 338,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/physiotherapist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/physiotherapistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0945f995d9615996aa",
            serviceName: "private doctor",
            serviceSortOrder: 339,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/privatedoctor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/privatedoctorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0a45f995d9615996b1",
            serviceName: "psychiatrist",
            serviceSortOrder: 340,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/psychiatrist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/psychiatristserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0b45f995d9615996b8",
            serviceName: "yoga trainer",
            serviceSortOrder: 341,
            isActive: true,
            description: "Transform your life through yoga.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/yogatrainer.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/yogatrainerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bf645f995d961598fe5",
        subcategoryName: "retail & shopping",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/retailshopping.svg",
        services: [
          {
            serviceId: "66976bdc45f995d961598f3c",
            serviceName: "medical pharmacy",
            serviceSortOrder: 342,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalpharmacy.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/medicalpharmacyserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c2b45f995d96159913b",
            serviceName: "optical eye shop",
            serviceSortOrder: 343,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/opticaleyeshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/opticaleyeshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976d0d45f995d9615996c7",
    categoryName: "social cause",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/socialcause.svg",
    subcategories: [
      {
        subcategoryId: "66976d0f45f995d9615996d3",
        subcategoryName: "blood donation",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/blooddonation.svg",
        services: [
          {
            serviceId: "66976d0f45f995d9615996d6",
            serviceName: "blood donation camp",
            serviceSortOrder: 345,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/blooddonationcamp.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/blooddonationcampserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d1145f995d9615996df",
            serviceName: "charity",
            serviceSortOrder: 346,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/charity.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/charityserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: true,
          },
          {
            serviceId: "66976d1245f995d9615996e8",
            serviceName: "donation camp",
            serviceSortOrder: 347,
            isActive: true,
            description: "Give a little, change a lot.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/donationcamp.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/donationcampserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66976d1345f995d9615996f1",
            serviceName: "environmental program",
            serviceSortOrder: 348,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/environmentalprogram.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/environmentalprogramserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d1445f995d9615996f8",
            serviceName: "health and wellness camp",
            serviceSortOrder: 349,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/healthwellnesscamp.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/healthwellnesscampserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d1545f995d961599701",
            serviceName: "ngo",
            serviceSortOrder: 350,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/ngo.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ngoserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d0e45f995d9615996cd",
            serviceName: "social awareness campaign",
            serviceSortOrder: 344,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/socialawarenesscampaign.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/socialawarenesscampaignserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976d1645f995d961599706",
    categoryName: "marriage",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/marriage.svg",
    subcategories: [
      {
        subcategoryId: "66976bbf45f995d961598e74",
        subcategoryName: "beauty & fashion",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/beautyfashion.svg",
        services: [
          {
            serviceId: "66976c9745f995d9615993e3",
            serviceName: "fashion designer",
            serviceSortOrder: 351,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fashiondesigner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/fashiondesignerserviceicon2.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc145f995d961598e85",
            serviceName: "hairdresser",
            serviceSortOrder: 352,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/hairdresser.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/hairdresserserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc245f995d961598e8c",
            serviceName: "ladies parlour",
            serviceSortOrder: 353,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesparlour.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/ladiesparlourserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc345f995d961598e93",
            serviceName: "make up artist",
            serviceSortOrder: 354,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/makeupartistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976bc445f995d961598e9a",
            serviceName: "mehndi designer",
            serviceSortOrder: 355,
            isActive: true,
            description: "Festival or Marriage Celebration.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/mehndidesigner.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/mehndidesignerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c6e45f995d9615992e0",
        subcategoryName: "creative & entertainment",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/creativeentertainment.svg",
        services: [
          {
            serviceId: "66976c6f45f995d9615992ea",
            serviceName: "artist",
            serviceSortOrder: 356,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/artist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/artistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7045f995d9615992f1",
            serviceName: "balloon decorator",
            serviceSortOrder: 357,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/balloondecorator.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/balloondecoratorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7245f995d9615992ff",
            serviceName: "DJ",
            serviceSortOrder: 358,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/dj.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/djserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7445f995d961599306",
            serviceName: "event manager",
            serviceSortOrder: 359,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/eventmanager.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/eventmanagerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7645f995d961599314",
            serviceName: "lighting specialist",
            serviceSortOrder: 360,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/lightingspecialist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/lightingspecialistserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7745f995d96159931b",
            serviceName: "music band",
            serviceSortOrder: 361,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/musicband.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/musicbandserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7845f995d961599322",
            serviceName: "party hall",
            serviceSortOrder: 362,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/partyhall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/partyhallserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c7945f995d961599329",
            serviceName: "photographer/videographer",
            serviceSortOrder: 363,
            isActive: true,
            description: "Where every click tells a story.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/photographervideographer.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/photographervideographerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976d2245f995d96159974c",
        subcategoryName: "find partner",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/findpartner.svg",
        services: [
          {
            serviceId: "66976d2345f995d961599756",
            serviceName: "find bride",
            serviceSortOrder: 365,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/findbride.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/findbrideserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: true,
          },
          {
            serviceId: "66976d2245f995d96159974f",
            serviceName: "find groom",
            serviceSortOrder: 364,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/findgroom.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/findgroomserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: true,
          },
        ],
      },
      {
        subcategoryId: "66976d2445f995d96159975c",
        subcategoryName: "other services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/otherservices.svg",
        services: [
          {
            serviceId: "66976d2445f995d96159975f",
            serviceName: "flower shop",
            serviceSortOrder: 366,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/florist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/floristserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c4f45f995d96159921f",
        subcategoryName: "professional services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/professionalservices.svg",
        services: [
          {
            serviceId: "66976c5145f995d961599230",
            serviceName: "astrologer",
            serviceSortOrder: 367,
            isActive: true,
            description: "Where the stars align with your story.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/astrologer.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/astrologerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66d3586c8c69575453cb6605",
            serviceName: "banquet hall",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/banquethall.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/banquethallicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976c5345f995d961599237",
            serviceName: "catering service",
            serviceSortOrder: 368,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/cateringservice.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/cateringserviceserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2745f995d961599770",
            serviceName: "marriage planner",
            serviceSortOrder: 369,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/marriageplanner.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/marriageplannerserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2845f995d961599777",
            serviceName: "pandit/pujari",
            serviceSortOrder: 370,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/panditpujari.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/panditpujariserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66d356f28c69575453cb6602",
            serviceName: "tent shop",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/tentshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/tentshopicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bf645f995d961598fe5",
        subcategoryName: "retail & shopping",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/retailshopping.svg",
        services: [
          {
            serviceId: "66976c0245f995d961599035",
            serviceName: "puja samagri",
            serviceSortOrder: 371,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pujasamagri.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/pujasamagriserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    categoryId: "66976d2a45f995d961599781",
    categoryName: "other services",
    categoryImage: "https://d2uvzz1jka6usw.cloudfront.net/otherservices.svg",
    subcategories: [
      {
        subcategoryId: "66976bc945f995d961598ebc",
        subcategoryName: "care",
        subcategoryImage: "https://d2uvzz1jka6usw.cloudfront.net/care.svg",
        services: [
          {
            serviceId: "66976d2a45f995d961599785",
            serviceName: "animal rescue",
            serviceSortOrder: 372,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/animalrescue.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/animalrescueserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976c6e45f995d9615992e0",
        subcategoryName: "creative & entertainment",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/creativeentertainment.svg",
        services: [
          {
            serviceId: "66976d2b45f995d96159978c",
            serviceName: "merchandise vendor",
            serviceSortOrder: 373,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/merchandisevendor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/merchandisevendorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976d2445f995d96159975c",
        subcategoryName: "other services",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/otherservices.svg",
        services: [
          {
            serviceId: "66976d2c45f995d961599793",
            serviceName: "computer rentals",
            serviceSortOrder: 374,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/computerrentals.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/computerrentalsserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2d45f995d96159979a",
            serviceName: "concierge services",
            serviceSortOrder: 375,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/conciergeservices.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/conciergeservicesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2e45f995d9615997a1",
            serviceName: "courier services",
            serviceSortOrder: 376,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/courierservices.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/courierservicesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3245f995d9615997bb",
            serviceName: "delivery",
            serviceSortOrder: 380,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/fooddeliveryperson.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/fooddeliverypersonserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2f45f995d9615997a8",
            serviceName: "dispobale distributor",
            serviceSortOrder: 377,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/dispobaledistributor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/dispobaledistributorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3045f995d9615997af",
            serviceName: "flex printing press",
            serviceSortOrder: 378,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/flexprintingpress.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/flexprintingpressserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d2445f995d96159975f",
            serviceName: "flower shop",
            serviceSortOrder: 379,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/florist.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/floristserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3345f995d9615997c2",
            serviceName: "food raw material distributor",
            serviceSortOrder: 381,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/foodrawmaterialdistributor.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/foodrawmaterialdistributorserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976ca645f995d96159943e",
            serviceName: "movers and packers",
            serviceSortOrder: 382,
            isActive: true,
            description: "Reliable hands for your most precious belongings.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/moverspackers.png",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/moverspackersserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: true,
            comingSoon: false,
          },
          {
            serviceId: "66cc28fdbe0fa4ee39921df7",
            serviceName: "pet store",
            serviceSortOrder: 1,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage: "https://d2uvzz1jka6usw.cloudfront.net/petcare.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/petstoreicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3545f995d9615997ce",
            serviceName: "pollution puc shop",
            serviceSortOrder: 383,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/pollutionpucshop.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/pollutionpucshopserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3645f995d9615997d5",
            serviceName: "printing press",
            serviceSortOrder: 384,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/printingpress.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/printingpressserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3745f995d9615997dc",
            serviceName: "sports arena",
            serviceSortOrder: 385,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsarena.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/sportsarenaserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
          {
            serviceId: "66976d3845f995d9615997e3",
            serviceName: "wifi network provider",
            serviceSortOrder: 386,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/wifinetworkprovider.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/wifinetworkproviderserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
      {
        subcategoryId: "66976bf645f995d961598fe5",
        subcategoryName: "retail & shopping",
        subcategoryImage:
          "https://d2uvzz1jka6usw.cloudfront.net/retailshopping.svg",
        services: [
          {
            serviceId: "66976c1d45f995d9615990e5",
            serviceName: "computer supplies and repairs",
            serviceSortOrder: 387,
            isActive: true,
            description:
              "Discover the Best Service Experience Right in Your Locality.",
            serviceImage:
              "https://d2uvzz1jka6usw.cloudfront.net/computersupplies.svg",
            serviceIcon:
              "https://d2uvzz1jka6usw.cloudfront.net/computersuppliesserviceicon.svg",
            bgColor: "0xFFCCE4FF",
            popular: false,
            comingSoon: false,
          },
        ],
      },
    ],
  },
];

export const blogsSEOData = [
  {
    id: 1,
    title:
      "Bridging the Digital Divide: Bataiyo’s Pre-Registration Initiative Launching Nationwide on August 15th",
    tags: ["Innovation"],
    description:
      "Explore how Bataiyo is working to bridge the digital divide with its nationwide pre-registration initiative launching on August 15th.",
    keywords:
      "Digital Divide, Bataiyo, Pre-Registration, Nationwide Launch, Innovation",
  },
  {
    id: 2,
    title:
      "Supporting Local Businesses Through Recommendations: The Power of Community",
    tags: ["Social Impact"],
    description:
      "Learn how community-driven recommendations are supporting local businesses and fostering a stronger community.",
    keywords: "Local Businesses, Community, Recommendations, Social Impact",
  },
  {
    id: 3,
    title:
      "How Trustworthy Are Modern Day Reviews on Google, MakeMyTrip, and Other Platforms? Why Friends’ Recommendations Still Reign Supreme",
    tags: ["Trust and Credibility"],
    description:
      "Dive into the reliability of online reviews on platforms like Google and MakeMyTrip, and why personal recommendations still hold value.",
    keywords: "Online Reviews, Trust, Credibility, Google, MakeMyTrip",
  },
  {
    id: 4,
    title:
      "Discovering Chandigarh: A Modern Day of Exploration and Culinary Delights",
    tags: ["Travel"],
    description:
      "Join us on a journey through Chandigarh, exploring its modern attractions and savoring its culinary delights.",
    keywords: "Chandigarh, Travel, Exploration, Culinary Delights",
  },
  {
    id: 5,
    title: "Moving to a New City: Navigating Challenges and Finding Solutions",
    tags: ["City Living"],
    description:
      "Moving to a new city? Discover practical solutions to common challenges and make your transition smoother.",
    keywords: "Moving, New City, Challenges, Solutions, City Living",
  },
];
