import {
  Box,
  Card,
  CardContent,
  CardMedia,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { splitServiceNames } from "../../constant/utils";
import { useApiData } from "../../context/ApiDataContext";
import ScrollDots from "./ScrollDots";
import { styles } from "./styleSheet";

function PopularServices() {
  const { serviceList } = useApiData();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1024px)");
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef(null);

  // Filter services based on popularServiceSortOrder
  const filteredServices = serviceList
    ?.filter((service) => service.popular)
    .sort((a, b) => a.popularServiceSortOrder - b.popularServiceSortOrder);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const cardWidth = container.children[0].getBoundingClientRect().width;
        const containerWidth = container.getBoundingClientRect().width;
        const scrollLeft = container.scrollLeft;
        const scrollableWidth = container.scrollWidth - containerWidth;

        const cardIndex = Math.floor(scrollLeft / cardWidth);
        const totalDots = 3;
        let dotIndex = cardIndex % totalDots;
        if (scrollLeft >= scrollableWidth) {
          dotIndex = totalDots - 1;
          // Highlight the last dot when at the end
        }
        setActiveIndex(dotIndex);
      }
    };

    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [filteredServices]);

  return (
    <div style={styles.servicesMainContainer(isMediumScreen)}>
      <h1 style={styles.servicesHeading(isSmallScreen)}>Trusted Services</h1>
      {/* Popular Services Section */}
      <div style={styles.popularServicesContainer}>
        <div style={styles.popularServicesHeadingBox(isSmallScreen)}>
          <h3 style={styles.popularServicesText(isSmallScreen)}>
            Popular Services
          </h3>
        </div>
        {/* Container for horizontal scroll */}
        <Box>
          <Box ref={containerRef} sx={styles.horizonatalContainer}>
            {filteredServices?.map((service) => (
              <Box
                key={service?._id}
                sx={styles.serviceCardItem(isSmallScreen)}
              >
                <Card sx={styles.popularServicesCard(isSmallScreen)}>
                  <CardMedia
                    component="img"
                    alt={service?.serviceName}
                    sx={styles.popularServicesImg(isSmallScreen)}
                    image={service?.serviceImage}
                  />
                  <CardContent sx={styles.cardContentBox(isSmallScreen)}>
                    <Box sx={styles.serviceName(isSmallScreen)}>
                      {splitServiceNames(service?.serviceName)}
                    </Box>
                    <Box sx={styles.serviceDesc(isSmallScreen)}>
                      {service?.description}
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
          <ScrollDots isSmallScreen={isSmallScreen} activeIndex={activeIndex} />
        </Box>
      </div>
    </div>
  );
}

export default PopularServices;
