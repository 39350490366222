import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const ScrollDots = ({ activeIndex, isSmallScreen }) => {
  const dotStyle = {
    width: isSmallScreen ? "8px" : "10px",
    height: isSmallScreen ? "8px" : "10px",
    borderRadius: "50%",
    backgroundColor: "#ddd",
    margin: "0 5px",
    display: "inline-block",
  };

  const activeDotStyle = {
    ...dotStyle,
    backgroundColor: "#007bff",
  };

  return (
    <Box sx={{ textAlign: "center", marginTop: "10px" }}>
      {[0, 1, 2].map((index) => (
        <Box
          key={index}
          sx={index === activeIndex ? activeDotStyle : dotStyle}
        />
      ))}
    </Box>
  );
};
ScrollDots.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  isSmallScreen: PropTypes.bool,
};
export default ScrollDots;
