import { ROUTES } from "../constant/routes";
const cloudfrontBaseUrl = ROUTES.CLOUDFRONT_URL;
export const ServiceSeekersImg = `${cloudfrontBaseUrl}forserviceseekers.png`;
export const ServiceProvidersImg = `${cloudfrontBaseUrl}forserviceproviders.png`;
export const DetailsAtYourFingertips = `${cloudfrontBaseUrl}tapicon.svg`;
export const ErrorImg = `${cloudfrontBaseUrl}closecircle.png`;
export const BataiyoLoader = `${cloudfrontBaseUrl}bataiyoloader.gif`;
export const DiamondIcon = `${cloudfrontBaseUrl}diamondicon.png`;
export const ThumbIcon = `${cloudfrontBaseUrl}thumbicon.png`;
export const HeadingMiddleImg = `${cloudfrontBaseUrl}headingmiddleicon.png`;
export const HeadingLeftImg = `${cloudfrontBaseUrl}headingtopicon.png`;
export const YouTubeImg = `${cloudfrontBaseUrl}youtubesuccessicon.png`;
export const XImg = `${cloudfrontBaseUrl}twittersuccessicon.png`;
export const trustIcon = `${cloudfrontBaseUrl}trusticon.svg`;
export const ShareIcon = `${cloudfrontBaseUrl}shareicon.svg`;
export const ReducesSearchTimeImg = `${cloudfrontBaseUrl}reducesearchtimeimg.svg`;
export const PromotesLegacyImg = `${cloudfrontBaseUrl}promoteslegacyimg.svg`;
export const OurPlatformImg = `${cloudfrontBaseUrl}ourplatformimg.svg`;
export const OrangeBulbIcon = `${cloudfrontBaseUrl}orangelightbulbicon.svg`;
export const OnlyTrustedImg = `${cloudfrontBaseUrl}onlytrustedimg.svg`;
export const ScanIcon = `${cloudfrontBaseUrl}scanicon.svg`;
export const NoCommissionImg = `${cloudfrontBaseUrl}nocommissionimg.svg`;
export const NetworkingIcon = `${cloudfrontBaseUrl}networkicon.svg`;
export const NetworkingBlackIcon = `${cloudfrontBaseUrl}networkblackicon.svg`;
export const MedalIcon = `${cloudfrontBaseUrl}medalstaricon.svg`;
export const LinkedInImg = `${cloudfrontBaseUrl}linkedinsuccessicon.png`;
export const LikeIcon = `${cloudfrontBaseUrl}likeicon.svg`;
export const LightBulbIcon = `${cloudfrontBaseUrl}lightbulbiconwhite.svg`;
export const BlackBulbIcon = `${cloudfrontBaseUrl}lightbulbiconblack.svg`;
export const InstagramImg = `${cloudfrontBaseUrl}instagramsuccessicon.png`;
export const InfiniteIcon = `${cloudfrontBaseUrl}infiniteiconwhite.svg`;
export const InfiniteBlackIcon = `${cloudfrontBaseUrl}infiniteiconblack.svg`;
export const InfiniteGreenIcon = `${cloudfrontBaseUrl}infinitegreenicon.svg`;
export const HandsIcon = `${cloudfrontBaseUrl}handsicon.svg`;
export const GoodWillIcon = `${cloudfrontBaseUrl}goodwilliconwhite.svg`;
export const GoodWillBlackIcon = `${cloudfrontBaseUrl}goodwilliconblack.svg`;
export const FacebookImg = `${cloudfrontBaseUrl}facebooksuccessicon.png`;
export const ConnectionIcon = `${cloudfrontBaseUrl}connectionicon.svg`;
export const BookIcon = `${cloudfrontBaseUrl}bookicon.svg`;
export const SupportingBusinessBlogCoverImg = `${cloudfrontBaseUrl}blogsupportinglocalbusinesses.png`;
export const MovingToCityBlogCoverImg = `${cloudfrontBaseUrl}blogmovingtocity.png`;
export const ModernDayBlogCoverImg = `${cloudfrontBaseUrl}blogmodernday.png`;
export const DiscoveringBlogCoverImg = `${cloudfrontBaseUrl}blogdiscoveringchandigarh.png`;
export const DigitalDivideBlogCoverImg = `${cloudfrontBaseUrl}blogdigitaldivideimg.png`;
export const BataiyoLogoIcon = `${cloudfrontBaseUrl}bataiyoroadmapicon.svg`;
export const AllBlogsImg = `${cloudfrontBaseUrl}blogbataiyotopimg.png`;
export const AllBlogsMobImg = `${cloudfrontBaseUrl}blogbataiyotopmobimg.png`;
export const MadeInIndiaSticker = `${cloudfrontBaseUrl}blogmadeinindiasticker.png`;
export const BataiyoLogo = `${cloudfrontBaseUrl}bataiyologo.png`;
export const AdvantageHeadingicon = `${cloudfrontBaseUrl}advantageheading.png`;
export const OrangeLine = `${cloudfrontBaseUrl}orangehorzline.png`;
export const NoComissionImg = `${cloudfrontBaseUrl}zerocommisionpopimg.png`;
export const OrangeArrow = `${cloudfrontBaseUrl}roadmaporangearrow.svg`;
export const GreenArrow = `${cloudfrontBaseUrl}roadmapgreenarrow.svg`;
export const RoadmapMobImg = `${cloudfrontBaseUrl}roadmapmobimg2.svg`;
export const FriendsIcon = `${cloudfrontBaseUrl}friendsicon.svg`;
export const BataiyoIcon = `${cloudfrontBaseUrl}bataiyoblueicon.png`;
export const BataiyoWhiteLogo = `${cloudfrontBaseUrl}navwhitelogo.svg`;
export const BataiyoFooterLogo = `${cloudfrontBaseUrl}bataiyofooterlogo.svg`;
export const ProviderImg = `${cloudfrontBaseUrl}providerformimg.png`;
export const UserImg = `${cloudfrontBaseUrl}userformimg.png`;
export const IndiaFlag = `${cloudfrontBaseUrl}indiaflagimg.png`;
export const OrangeDiamond = `${cloudfrontBaseUrl}orangediamond.svg`;
export const BottomGreenLine = `${cloudfrontBaseUrl}ibobottomline.svg`;
export const BlackBigStar = `${cloudfrontBaseUrl}bigblackstar.svg`;
export const SmallStar = `${cloudfrontBaseUrl}smallblackstar.svg`;
export const BlueArrowIcon = `${cloudfrontBaseUrl}bluearrow.png`;
export const GreenArrowIcon = `${cloudfrontBaseUrl}greenarrow.png`;
export const OrangeArrowIcon = `${cloudfrontBaseUrl}orangearrow.png`;
export const IndiaFlagLogo = `${cloudfrontBaseUrl}flagofIndia.svg`;
export const DefaultServicesIcon = `${cloudfrontBaseUrl}homesettingicon.svg`;
export const BataiyoFeaturesImg = `${cloudfrontBaseUrl}whatsbataiyoimg.svg`;
