import { useFormik } from "formik";
import { useCallback, useState } from "react";
import { signupFormValidationSchema } from "../constant/formValidationSchema";
import { ERROR_MESSAGES } from "../constant/messages";
import { useDialog } from "../context/DialogContext";

import { postSignUpFormData } from "../services/form.service";

const useSignupForm = () => {
  const { handleDialogClose } = useDialog();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(false);

  const filterValues = useCallback((obj) => {
    const { isEighteenOrOlder, ...rest } = obj;
    return rest;
  }, []);

  const handleSuccessFalse = useCallback(() => {
    setSuccessMessage(false);
  }, []);

  const handleErrorFalse = useCallback(() => {
    setError(false);
  }, []);

  const formik = useFormik({
    initialValues: {
      userType: "",
      businessType: "Individual",
      mobileNumber: "",
      serviceId: "",
      name: "",
      businessName: "",
      experience: "",
      serviceOrCurrentLocationObject: {},
      homeLocationObject: {},
      isEighteenOrOlder: false,
      isAutoApproved: true,
      otp: Array(4).fill(""),
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: signupFormValidationSchema,
    validateOnChange: true,

    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formattedMobileNumber = values?.mobileNumber?.startsWith("+91")
          ? values?.mobileNumber
          : "+91" + values?.mobileNumber;
        const filteredValues = filterValues(values);

        if (filteredValues.userType === "User") {
          const keysToDelete = [
            "businessType",
            "serviceId",
            "businessName",
            "experience",
            "isAutoApproved",
          ];
          keysToDelete.forEach((key) => delete filteredValues[key]);
        }

        // Conditionally remove businessName if businessType is "Individual"
        if (filteredValues.businessType === "Individual") {
          delete filteredValues?.businessName;
        }

        const payload = {
          ...filteredValues,
          mobileNumber: formattedMobileNumber,
          otp: values?.otp.join(""),
        };
        const res = await postSignUpFormData(payload);
        if (res?.data?.message === "Registration Successful") {
          setSuccessMessage(true);
          handleDialogClose();
        }
      } catch (error) {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
        console.error(ERROR_MESSAGES?.postDataError, error);
      } finally {
        setLoading(false);
      }
    },
  });

  return {
    formik,
    loading,
    errorMessage,
    successMessage,
    handleSuccessFalse,
    handleErrorFalse,
    error,
  };
};

export default useSignupForm;
