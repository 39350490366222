import React from "react";
import LatestBlogsCards from "../../components/Blog/LatestBlogsCards";
import Seo from "../../components/SEO/Seo";
import { blogSEOKeywords } from "../../constant/staticData";
import Layout from "../../Layout";
const Blog = () => {
  return (
    <Layout>
      <Seo
        title="Bataiyo | Our Blog"
        description="Explore our latest blog posts covering a wide range of topics including innovation, social impact, travel, and more."
        keywords={blogSEOKeywords.join(", ")}
        url="blog"
      />
      <LatestBlogsCards />
    </Layout>
  );
};

export default Blog;
