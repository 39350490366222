import { useMediaQuery } from "@mui/material";
import React from "react";
import {
  GreenArrow,
  OrangeArrow,
  OrangeLine,
  RoadmapMobImg,
} from "../../constant/ImagesS3";
import { roadmapCardsDetails } from "../../constant/staticData";
import "./Roadmap.css";
function Roadmap() {
  const isSmallScreen = useMediaQuery("(max-width: 767px)");

  return (
    <section className="roadmap-section" id="roadmap">
      <div className="roadmap-content">
        <img
          src={OrangeArrow}
          alt="orange-arrow"
          className="orange-arrow"
          loading="lazy"
        />
        <img
          src={GreenArrow}
          alt="green-arrow"
          className="green-arrow"
          loading="lazy"
        />
        <h1 className="roadmap-heading">Roadmap</h1>
        <img
          src={OrangeLine}
          alt="orange-line"
          className="orange-horx-line"
          loading="lazy"
        />
      </div>{" "}
      {isSmallScreen ? (
        <div className="roadmap-image-container">
          <img
            src={RoadmapMobImg}
            alt="roadmap"
            className="roadmap-mob-image"
            loading="lazy"
          />
        </div>
      ) : (
        <div className="roadmap-box-container">
          <div className="horz-blue-line"></div>{" "}
          {roadmapCardsDetails?.map((item) => (
            <div
              className="roadmap-card-container"
              style={{
                backgroundColor: `${item.backgroundColor}`,
              }}
              key={item?.id}
            >
              <div className="roadmap-card-title">{item?.title}</div>
              <div className="roadmap-card-qtext">{`(Q${item?.q}-${item?.year})`}</div>
              <div className="roadmap-image-container">
                <img
                  src={item?.logo}
                  alt={item?.alt}
                  className="advantage-card-image"
                  loading="lazy"
                />
              </div>
              <div className="roadmap-card-desc">{item?.deatils}</div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

export default Roadmap;
