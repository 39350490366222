import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NoComissionImg } from "../../constant/ImagesS3.js";
import { successSocialIcons } from "../../constant/staticData.js";
import { useDialog } from "../../context/DialogContext.js";
import { styles } from "./styleSheet.js";
function CustomDialog({ isDialogOpen, handleClose }) {
  const { handleDialogClose } = useDialog();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  const handleModalClose = () => {
    handleClose();
    handleDialogClose();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCloseIcon(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (!isDialogOpen) return null;
  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleModalClose}
      maxWidth="sm"
      fullWidth
      sx={styles.CustomDialogBox(isSmallScreen)}
    >
      <div style={styles.CustomDialogContent}>
        <div style={styles.crossButtonBox}>
          {showCloseIcon && (
            <CloseIcon onClick={handleModalClose} sx={styles.crossIcon} />
          )}
        </div>
        <img
          src={NoComissionImg}
          alt="success"
          style={styles.CustomDialogImage}
          loading="lazy"
        />
        <div style={styles.CustomDialogText}>
          Successful!
          <br />
          <div style={styles.CustomDialogDescText}>
            Congratulations! You have successfully signed up. Have a great
            experience ahead and grow your network.
          </div>
        </div>{" "}
      </div>{" "}
      <div style={styles.CustomDialogDescBox}>
        <div style={styles.CutomDialogFewThingsText}>
          A few things to remember:
        </div>
        <div style={styles.CutomDialogFewThingsDescText}>
          <div style={styles.DialogContentBox}>
            <div style={styles.DialogTextCircle("#FF820F")}></div>
            <div style={styles.DialogTextHead}>
              App Launch:{" "}
              <span style={styles.DialogTextSpan}>
                The app is available on the Android and iOS stores 
                but is currently accessible only for whitelisted users.
              </span>
            </div>
          </div>
          <div style={styles.DialogContentBox}>
            <div style={styles.DialogTextCircle("#0072DD")}></div>
            <div style={styles.DialogTextHead}>
              Early Access:{" "}
              <span style={styles.DialogTextSpan}>
                To get early access, please contact us on Instagram from below links.
              </span>
            </div>
          </div>{" "}
          <div style={styles.DialogContentBox}>
            <div style={styles.DialogTextCircle("#06A445")}></div>
            <div style={styles.DialogTextHead}>
              Going Live:{" "}
              <span style={styles.DialogTextSpan}>
                Follow us on social media to find out when we are going live.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={styles.CutomDialogFollowUsText}>
          Follow us for exclusive updates
        </div>
        <div style={styles.CutomDialogSocialIconsBox}>
          {successSocialIcons?.map((item) => (
            <Link key={item.id} to={item.path} target="_blank">
              {" "}
              <img
                src={item.icon}
                alt={item.name}
                style={styles.CutomDialogSocialIconsImg}
              />
            </Link>
          ))}
        </div>
      </div>
      <DialogActions sx={styles.CloseButtonBox}>
        {showCloseIcon && (
          <Button
            onClick={handleModalClose}
            disabled={!showCloseIcon}
            sx={styles.CloseButtonStyle}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CustomDialog;
